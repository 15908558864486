/**
 * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop
 */
import axios from 'axios'
import { interceptorRequest, interceptorResponse } from './auth'
import moment from 'moment'
import qs from 'querystring'

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL })
api.interceptors.request.use(interceptorRequest)
api.interceptors.response.use(null, interceptorResponse)

export async function getCamList() {
  return await api.get('camera')
}

export async function getCameraListPageCameras(keyword = '', organization_ids = [], region_ids = [], county_ids = []) {
  let params = {}
  keyword.length && (params.q = keyword)
  organization_ids.length && (params.organization_ids = organization_ids)
  region_ids.length && (params.region_ids = region_ids)
  county_ids.length && (params.county_ids = county_ids)
  const query = qs.stringify(params)
  const { data } = await api.get(`camera/all?${query}`)
  return data.cameras
}

/**
 * @param {string} startDate
 * @param {string} endDate
 * @param {number} isAlert 0: sikp, 1: confirm, 2: skip(hardcase)
 */
export async function getLogList(startDate = '', endDate = '', tabIndex = 1) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  return await api.get(`log/all?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&is_alert=${tabIndex}`)
}

/**
 * @param {string} startDate
 * @param {string} endDate
 * @param {number} isAlert 0: sikp, 1: confirm, 2: skip(hardcase)
 */
export async function getLogListv2(startDate = '', endDate = '', tabIndex = 1, workerIds = [], inChild = false, inRepOrg = true) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  // return await api.get(
  //   `v2/log/all?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&is_alert=${tabIndex}&worker_ids=${JSON.stringify(
  //     workerIds
  //   )}`
  // )

  const query = qs.stringify({
    start_date: startDate,
    end_date: endDate,
    in_child: inChild,
    in_rep_org: inRepOrg,
    is_alert: tabIndex,
    worker_ids: workerIds,
    in_child: inChild,
    in_rep_org: inRepOrg,
  })
  return await api.get(`v2/log/all?${query}`)
}

/**
 * @param {string} startDate
 * @param {string} endDate
 * @param {number} isAlert 0: sikp, 1: confirm, 2: skip(hardcase)
 * @param {number} cameraId
 */
export async function getLogListv2ByCameraId(
  startDate = '',
  endDate = '',
  tabIndex = 1,
  workerIds = [],
  cameraId = 0,
  inChild = true,
  inRepOrg = true
) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  const query = qs.stringify({
    start_date: startDate,
    end_date: endDate,
    is_alert: tabIndex,
    worker_ids: workerIds,
    camera_id: cameraId,
    in_child: inChild,
    in_rep_org: inRepOrg,
  })
  // return await api.get(
  //   `v2/log/all?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(
  //     endDate
  //   )}&is_alert=${tabIndex}&camera_id=${cameraId}&worker_ids=${JSON.stringify(workerIds)}`
  // )
  return await api.get(`v2/log/all?${query}`)
}

export async function getLogPeriod(inChild = true, inNoti = true, inRepOrg = true, inRespStat = true, tabIndex = 1, startDate = '', endDate = '') {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  const query = qs.stringify({
    end_date: endDate,
    in_child: inChild,
    in_noti: inNoti,
    in_rep_org: inRepOrg,
    in_resp_stat: inRespStat,
    is_alert: tabIndex,
    start_date: startDate,
  })
  return await api.get(`log/period?${query}`)
}

/**
 * @param {number} isAlert 0: sikp, 1: confirm, 2: skip(hardcase)
 */
export async function getRecentLogList(tabIndex = 1) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  let offset = moment().format().slice(19) // ex. +09:00
  offset = offset.slice(0, 1) + parseInt(offset.slice(1, 3)) // ex. +9 or -9
  offset = parseInt(offset) // ex. 9 or -9
  return await api.get(`log/recent?offset=${encodeURIComponent(offset)}&is_alert=${tabIndex}`)
}

export async function getRecentLogListv2(tabIndex = 1, inNoti, inRepOrg, inRespStat, inChild) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  let offset = moment().format().slice(19) // ex. +09:00
  offset = offset.slice(0, 1) + parseInt(offset.slice(1, 3)) // ex. +9 or -9
  offset = parseInt(offset) // ex. 9 or -9
  return await api.get(
    `v2/log/recent?is_alert=${tabIndex}&in_noti=${encodeURIComponent(inNoti)}&in_rep_org=${encodeURIComponent(
      inRepOrg
    )}&in_resp_stat=${encodeURIComponent(inRespStat)}&in_child=${encodeURIComponent(inChild)}`
  )
}

/**
 * @param {boolean} includeChildLogs
 * @param {boolean} includeNotificationInformation
 * @param {boolean} includeDuplicatesByOrganization
 * @param {boolean} includeResponseStatusInformation
 * @param {number} isAlert 0: sikp, 1: confirm, 2: skip(hardcase)
 */
export async function getRecentLogListWithRepetitive(
  includeChildLogs = true,
  includeNotificationInformation = true,
  includeDuplicatesByOrganization = true,
  includeResponseStatusInformation = true,
  tabIndex = 1
) {
  if (tabIndex === 2) {
    tabIndex = 0
  } else if (tabIndex === 3) {
    tabIndex = 2
  }
  // let offset = moment().format().slice(19) // ex. +09:00
  // offset = offset.slice(0, 1) + parseInt(offset.slice(1, 3)) // ex. +9 or -9
  // offset = parseInt(offset) // ex. 9 or -9
  // return await api.get(`log/recent?offset=${encodeURIComponent(offset)}&is_alert=${tabIndex}&includes_repetitive=${true}`)

  const query = qs.stringify({
    in_child: includeChildLogs,
    in_noti: includeNotificationInformation,
    in_rep_org: includeDuplicatesByOrganization,
    in_resp_stat: includeResponseStatusInformation,
    is_alert: tabIndex,
  })
  return await api.get(`v2/log/recent?${query}`)
}

export async function getLog(logId) {
  return await api.get(`log/info/${logId}`)
}

/**
 * @param {{log_id: number, duplicated_log_id: number, is_duplicate: boolean}} payload
 */
export async function updateDetectionType(payload) {
  return await api.put('log/repetitive-detection', payload)
}

/**
 * @param {{log_id: number, event_type_id: number}} payload
 */
export async function updateEventType(payload) {
  return await api.put('log/event', payload)
}

/**
 *
 * @returns {event_types: [{id:number, name:string}], result: {code:string, message: string}}
 */
export async function getEventType() {
  return await api.get('event')
}

/**
 * @param {{start_date: string, end_date: string, event_type_id: [number]}} payload
 * @returns {xlsx_path: string, result: {code: string, message: string}}
 */
export async function downloadLogHistory(payload) {
  return await api.post('log/download', payload)
}

/**
 * @param {{camera_id: number, start_time: string, end_time: string}} payload time format "2021-09-15T00:00:00+09:00"
 * @returns {result: {code: string, message: string}}
 */
export async function updatePauseAlert(payload) {
  return await api.put('alert/pause', payload)
}

/**
 * @param {{camera_id: number}} payload
 * @returns {result: {code: string, message: string}}
 */
export async function clearPauseAlert(payload) {
  return await api.delete(`alert/pause/${payload.camera_id}`)
}

/**
 * @param {File} file
 */
export async function uploadFileForSuppport(file) {
  const form = new FormData()
  form.append('file', file)
  return api.post('/contact/upload', form)
}

/**
 * @param {{log_id: number, response_status: number}} payload
 * @returns {result: {code: string, message: string}}
 */
export async function setResponseStatus(payload) {
  return await api.put('log/response-status', payload)
}

/**
 * @param {{log_id: number, event_type_id: number}} payload
 * @returns {result: {code: string, message: string}}
 */
export async function setEventType(payload) {
  return await api.put('log/event', payload)
}

/**
 * @param {number} id
 */
export async function getChildLogs(id, inRepOrg = true) {
  return await api.get(`log/${id}/children?in_rep_org=${encodeURIComponent(inRepOrg)}`)
}

export async function getFirstDetectionsWithin24Hours(id) {
  return await api.get(`log/recent/${id}`)
}
