<template>
  <v-dialog v-model="value" width="400px" @keydown.esc="hide" persistent no-click-animation>
    <v-card :class="`pause-alert ${themeName}`">
      <v-card-text class="pa-0">
        <div :class="`pause-alert-dialog-title ${themeName}`">Pause Alerts Until...</div>
        <div :class="`pause-alert-dialog-content ${themeName}`">
          <div class="date-time-container">
            <div class="label">Date</div>
            <div :class="`calendar-container ${themeName}`">{{ currentDate }}</div>
            <v-menu
              v-model="openDatepicker"
              offset-x
              nudge-left="-5px"
              :close-on-content-click="false"
              :close-on-click="false"
              content-class="calendar-layout"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="calendar-image">
                  <img src="@/assets/Calendar_Filled_24px_Icon.svg" width="24px" />
                </div>
              </template>
              <Calendar
                v-if="openDatepicker"
                :min-date="calendarMinDate"
                :max-date="calendarMaxDate"
                :is-range="false"
                @updateCustomDate="onUpdateDate"
                style="border-radius: 15px"
                @closeCalendar="closeCalendar"
                :daySelectMode="true"
              ></Calendar>
            </v-menu>
          </div>
          <div class="date-time-container">
            <div class="label">Time</div>
            <v-menu v-model="timeMenu" offset-y nudge-bottom="5px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" :class="`calendar-container ${themeName}`" @click="onClickTimeMenu">
                  <span>{{ currentTime }}</span>
                  <img
                    v-if="!$vuetify.theme.dark && !timeMenu"
                    class="arrow-img"
                    src="@/assets/Icon ionic-ios-arrow-down-black-small.svg"
                    width="7"
                  />
                  <img
                    v-else-if="$vuetify.theme.dark && !timeMenu"
                    class="arrow-img"
                    src="@/assets/Icon ionic-ios-arrow-down-gray-small.svg"
                    width="7"
                  />
                  <img
                    v-else-if="!$vuetify.theme.dark && timeMenu"
                    class="arrow-img"
                    src="@/assets/Icon ionic-ios-arrow-up-black-small.svg"
                    width="7"
                  />
                  <img v-else class="arrow-img" src="@/assets/Icon ionic-ios-arrow-up-gray-small.svg" width="7" />
                </div>
              </template>
              <div class="time-container">
                <div class="wrapper">
                  <div
                    v-for="(time, index) in timeList"
                    :key="index"
                    @click="onClickTime(index, $event)"
                    :class="{ time: true, disabled: invalidTime(index), dark: $vuetify.theme.dark }"
                  >
                    {{ time }}
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </div>
        <div class="d-flex justify-end">
          <button @click="hide" :class="`pause-alert-dialog-button cancel ${themeName}`">Cancel</button>
          <button @click="ok" :class="`pause-alert-dialog-button ok ${themeName}`">Pause Alerts</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Calendar from '@components/common/CalendarAdmin'
import Calendar from '@item/calendar/DefaultCalendar'
import moment from 'moment'
import Util from '@/util'
import { alert } from '@/api'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { Calendar },
  props: {
    value: Boolean,
    item: Object,
    /**
     * emit events
     * ok
     */
  },
  data() {
    const timeList = (() => {
      return new Array(24).fill(0).map((v, i) => {
        if (i < 12) {
          return `${i.toString().padStart(2, '0')}:00`
        } else {
          return `${i}:00`
        }
      })
    })()
    return {
      timeMenu: false,
      openDatepicker: false,
      today: '',
      currentDate: '',
      calendarMinDate: '',
      calendarMaxDate: '',
      currentTimeIndex: 0,
      timeList,
    }
  },
  computed: {
    ...mapState(['recentLogListv2']),
    currentTime() {
      if (this.currentTimeIndex < 12) {
        return `${this.currentTimeIndex.toString().padStart(2, '0')}:00`
      } else {
        return `${this.currentTimeIndex}:00`
      }
    },
    after72hours() {
      const date = moment(this.lastestLog.created_at).add(72, 'h')
      const offset = this.lastestLog.offset
      const deviceTime = Util.getOffsetDate(date, offset)
      return Util.defineAlertOffTime(deviceTime)
    },
    lastestLog() {
      return this.recentLogListv2.find((log) => log.camera_id === this.item.id)
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.today = moment().format('YYYY-MM-DD')
        this.currentDate = moment().format('YYYY-MM-DD')
        this.calendarMinDate = moment().subtract(1, 'day')
        this.calendarMaxDate = moment(this.after72hours)
        if (this.calendarMaxDate.format('H') == 0) {
          this.calendarMaxDate.add(1, 'm')
        }
        this.currentTimeIndex = parseInt(moment().format('H')) + 1
      }
    },
  },
  methods: {
    ...mapActions(['getCamList']),
    closeCalendar() {
      this.openDatepicker = false
    },
    onClickTimeMenu() {
      this.timeMenu = !this.timeMenu
    },
    hide() {
      this.$emit('input', false)
    },
    async ok() {
      this.hide()
      try {
        await alert.updatePauseAlert({
          camera_id: this.item.id,
          start_time: moment(this.lastestLog.created_at).toISOString(),
          end_time: moment(this.currentDate).hour(this.currentTimeIndex).toISOString(),
        })
        await this.getCamList({ justCamList: true })
      } catch (e) {
        this.$log.error('updatePauseAlert error')
      }
    },
    invalidTime(currentTimeIndex) {
      if (this.currentDate === this.today) {
        const hour = parseInt(moment().format('H')) + 1
        if (currentTimeIndex < hour) {
          return true
        }
      }
      if (this.calendarMaxDate && this.currentDate === this.calendarMaxDate.format('YYYY-MM-DD')) {
        const hour = moment(this.after72hours).format('H')
        if (hour < currentTimeIndex) {
          return true
        }
      }
      return false
    },
    onUpdateDate(dates) {
      const selectedDate = moment(dates.startDate)
      this.openDatepicker = false
      this.currentDate = selectedDate.format('YYYY-MM-DD')
      this.setCurrentTime()
    },
    setCurrentTime() {
      if (this.calendarMaxDate.format('YYYY-MM-DD') === this.currentDate) {
        for (let timeIndex = 0; timeIndex < this.timeList.length; timeIndex++) {
          const hour = moment(this.after72hours).format('H')
          if (hour == timeIndex) {
            this.currentTimeIndex = timeIndex
            break
          }
        }
      } else if (this.invalidTime(this.currentTimeIndex)) {
        const firstInvalidTime = this.invalidTime(0)
        for (let i = 0, j = this.timeList.length; i < j; i++) {
          if (firstInvalidTime) {
            if (!this.invalidTime(i)) {
              this.currentTimeIndex = i
              break
            }
          } else {
            if (!this.invalidTime(i)) {
              this.currentTimeIndex = i
            }
          }
        }
      }
    },
    onClickTime(index, e) {
      if (this.invalidTime(index)) {
        e.stopPropagation()
      } else {
        this.currentTimeIndex = index
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pause-alert {
  background-color: var(--f-text-white);
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.pause-alert-dialog-title {
  padding-top: 50px;
  line-height: 25px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.pause-alert-dialog-content {
  display: flex;
  flex-direction: column;
  height: 173px;
  font-weight: 500;
  font-size: 15px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }

  .date-time-container {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .label {
      font-size: 16px;
      margin-left: 51px;
      margin-right: 16px;
    }

    .calendar-container {
      display: flex;
      align-items: center;
      padding: 0 8px 0 16px;
      width: 200px;
      height: 36px;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      background-color: var(--f-light-gray-1);
      &.dark {
        background-color: var(--f-bg-high);
      }
    }
  }
}

.time-container {
  max-height: 175px;
  border-radius: 6px;
  background-color: var(--v-backgroundAsideTooltip-base);
  padding: 6px 0;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(175px - 12px);
    overflow: auto;
  }

  .time {
    display: flex;
    align-items: center;
    min-height: 27px;
    padding-left: 15px;
    cursor: pointer;
    font-size: 14px;
    height: 32.6px;
    color: var(--f-text-black);

    &:hover {
      background-color: var(--f-primary-50);
      color: var(--f-text-white);

      &.dark {
        color: var(--f-text-white-high);
      }
    }
    &.dark {
      color: #ffffffde;
    }
    &.disabled {
      display: none;
    }
  }
}

.calendar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-color: var(--f-primary-90);
  user-select: none;
  cursor: pointer;
  margin-left: 10px;
  &:active {
    opacity: 0.5;
  }
}

.calendar-layout {
  width: 500px;
  overflow-y: unset;
  box-shadow: none !important;
  padding: 15px 20px 20px 20px;
  min-height: 550px;
  transform: translateY(-23%);
}

.pause-alert-dialog-button {
  font-weight: 600;
  font-size: 16px;
  height: 60px;

  &.cancel {
    width: 160px;
    color: var(--v-textDefault);
    background-color: var(--f-cool-gray-1);
    &.dark {
      background-color: var(--f-supplementary-modal-cancel);
    }
  }

  &.ok {
    flex-grow: 1;
    color: #ffffff;
    background-color: var(--f-supplementary-modal-blue);
    &.dark {
      color: var(--v-textDefault);
    }
  }
}

.arrow-img {
  margin-left: auto;
  margin-right: 12px;
}
</style>
