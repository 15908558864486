<template>
  <v-app>
    <v-main>
      <v-container fluid style="padding: 0; margin: 0; height: 100%; display: flex">
        <AdminNavigation ref="adminNavigation" v-if="showAdminNavigation" style="z-index: 4" />
        <div :class="{ 'nav-width': !showAdminNavigation }" style="width: calc(100% - 70px); overflow: hidden">
          <DesktopToolbar v-if="showAdminNavigation" />
          <router-view id="main"></router-view>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import DesktopToolbar from '@common/DesktopToolbar'
import AdminNavigation from '@common/Navigation'
import Constants from '@/constants'
import Toolbar from './components/mobile/alert/Toolbar.vue'

export default {
  components: { AdminNavigation, Toolbar, DesktopToolbar },
  data() {
    return {
      currentPath: '',
      currentTitle: '',
      MISC_ROUTE_LIST: ['/login', '/monitor'],
    }
  },
  metaInfo: {
    title: 'FireScout.ai | Wildfire Detection AI That Never Sleeps',
  },
  created() {
    this.controlClipboardCopy()
  },
  beforeUpdate() {
    this.currentPath = this.$route.path.substring(1)
    this.currentTitle = Constants.ANALYTICS_TITLE[this.currentPath]
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.includes('Mobile') && userAgent.includes('iPhone')) {
      const appWrap = document.getElementsByClassName('v-application--wrap')[0]
      appWrap.classList.add('v-application--wrap--mobile')
    }
  },
  computed: {
    showAdminNavigation() {
      const currentPath = this.$route.path

      const IS_ROLE_ADMIN_OR_VIEWER = [Constants.ROLE_ADMIN, Constants.ROLE_VIEWER].includes(this.$store.state.user.user_role)
      const IS_DESKTOP_ROUTE = !currentPath.includes('/m/')
      const IS_NOT_MISC_ROUTE = !this.MISC_ROUTE_LIST.includes(currentPath)

      // this.$log.debug('currentPath', currentPath)
      // this.$log.debug('App.vue # showAdminNavigation() # IS_ROLE_ADMIN_OR_VIEWER', IS_ROLE_ADMIN)
      // this.$log.debug('App.vue # showAdminNavigation() # IS_DESKTOP_ROUTE', IS_DESKTOP_ROUTE)
      // this.$log.debug('App.vue # showAdminNavigation() # IS_NOT_MISC_ROUTE', IS_NOT_MISC_ROUTE)

      return IS_ROLE_ADMIN_OR_VIEWER && IS_DESKTOP_ROUTE && IS_NOT_MISC_ROUTE
    },
  },
  updated() {
    if (this.showAdminNavigation && this.$refs?.adminNavigation) {
      // this.$refs.adminNavigation.adjustNavIndex()
    }
  },
  methods: {
    controlClipboardCopy() {
      // const regexDate = /\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d/ // date format
      // const regexDirection = /˚ / // direction
      // const share = /https?:\/\//i
      // window.addEventListener('copy', (e) => {
      //   const selection = document.getSelection()
      //   const copyTextList = selection.toString().split('\n')
      //   const filteredList = copyTextList.filter((text) => regexDate.test(text) || regexDirection.test(text) || share.test(text))
      //   const removeDuplicated = [...new Set(filteredList)]
      //   e.clipboardData.setData('text/plain', removeDuplicated.join('\n'))
      //   e.preventDefault()
      // })
    },
  },
}
</script>

<style lang="scss">
@import 'variables.scss';
/* esri styles */
@import url('https://js.arcgis.com/4.23/esri/themes/light/main.css');

html,
body,
.v-application,
.v-application--wrap {
  overflow-y: auto !important;
  height: 100%;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.theme--dark.v-application {
  background-color: var(--f-bg-low) !important;
}
.v-application--wrap--mobile {
  min-height: -webkit-fill-available !important;
}

.v-autocomplete > .v-input__control > .v-input__slot,
.v-text-field > .v-input__control > .v-input__slot {
  margin-bottom: 5px !important;
}

.v-autocomplete > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:before {
  border-width: 0px !important;
}

.v-autocomplete.input > .v-input__control > .v-input__slot:before,
.v-text-field.input > .v-input__control > .v-input__slot:before {
  border-width: 1px 0 0 0 !important;
  color: #9e9ea1;
}

.v-autocomplete.input.error--text > .v-input__control > .v-input__slot:before,
.v-text-field.input.error--text > .v-input__control > .v-input__slot:before {
  border-width: 1px 0 0 0 !important;
  color: currentColor;
}

.v-autocomplete > .v-input__control > .v-input__slot > .v-text-field__slot > input,
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  color: var(--v-textDefault);
  font-size: 14px;
}

.v-autocomplete > .v-input__control > .v-input__slot > .v-select__slot > input::placeholder,
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > input::placeholder {
  color: var(--v-textDefault);
}

.v-autocomplete.input.error--text > .v-input__control > .v-input__slot > .v-text-field__slot > input::placeholder,
.v-text-field.input.error--text > .v-input__control > .v-input__slot > .v-text-field__slot > input::placeholder {
  color: #ff3b30;
}

.v-input--is-focused > .v-input__control > .v-input__slot {
  border: 1px solid #3d51fb !important;
}

.v-input--hide-details > .v-input__control > .v-input__slot {
  border: 1px solid rgba(0, 0, 0, 0);
}

.v-input__slot > .v-select__slot > input[type='search'] {
  font-size: 14px;
  color: var(--v-textDefault);
}

.v-dialog {
  border-radius: 16px !important;
  &.calendar {
    box-shadow: none !important;
    width: 0px;
  }
}

.v-autocomplete__content {
  max-height: 181px !important;
}

.v-autocomplete .v-input__icon--clear {
  margin-right: 30px;
}

.aside-log-camera-searchbar .v-input__icon--clear {
  margin-right: 4px;
}

.v-list-item {
  min-height: 37px !important;

  &:hover {
    background-color: #6d7dff;
  }

  &.theme--dark:hover {
    background-color: #3d51fb;
  }

  &.v-list-item--highlighted {
    background-color: #6d7dff;
  }

  &.theme--dark.v-list-item--highlighted {
    background-color: #3d51fb;
  }

  .v-list-item__mask {
    color: var(--v-textDefault) !important;
    background-color: #e9ebff !important;
  }

  &.theme--dark .v-list-item__mask {
    background-color: #5f8bff !important;
  }
}

.search-mask {
  color: var(--v-textDefault);
  background-color: var(--v-backgroundSearchMask);
}

.v-list-item__title {
  font-size: 14px !important;
  color: var(--v-textDefault);
}

.v-list-item__content {
  padding: 10px 0 !important;
}

.theme--dark.v-icon {
  color: #ffffffde !important;
}

.theme--dark.v-list {
  background-color: #25262c !important;
  border-color: #25262c !important;
}

.v-autocomplete.input.error--text > .v-input__control > .v-input__slot:before,
.v-text-field.input.error--text > .v-input__control > .v-input__slot:before {
  border-width: 1.5px 0 0 0 !important;
  border-color: #ff3b30 !important;
}

.v-autocomplete.input > .v-input__control > .v-input__slot:before,
.v-text-field.input > .v-input__control > .v-input__slot:before,
.v-autocomplete.input > .v-input__control > .v-input__slot:after,
.v-text-field.input > .v-input__control > .v-input__slot:after {
  transition: none;
  border-color: #ffffffde !important;
  color: #ffffffde !important;
}

.aside-log-camera-searchbar:not(.v-select--is-menu-active)
  .v-input__control
  > .v-input__slot
  > .v-input__prepend-inner
  > .v-input__icon--prepend-inner
  > .v-icon {
  color: #00000061 !important;
}

.aside-log-camera-searchbar:not(.v-select--is-menu-active)
  .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-input__append-inner
  > .v-input__icon--clear
  > .v-icon {
  color: #00000061 !important;
}

.aside-log-camera-searchbar > .v-input__control > .v-input__slot > .v-select__slot > input::placeholder {
  color: #00000061 !important;
}

.aside-log-camera-searchbar > .v-input__control > .v-input__slot > .v-select__slot > input {
  color: var(--f-text-black) !important;
}

.v-messages {
  font-size: 10px !important;
}

.v-menu__content {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
}

.v-autocomplete__content.v-menu__content {
  border-radius: 10px !important;
  margin-top: 5px;
}

.v-autocomplete__content > .v-list > .v-list-item {
  max-height: 33px !important;
  height: 33px !important;
  min-height: 33px !important;
}

.v-btn {
  text-transform: none !important;
  // color: white !important;
}

.v-input__slot:after {
  border-style: none !important;
}

.subject-text-field > .v-input__control > .v-input__slot:before,
.subject-text-field > .v-input__control > .v-input__slot:after {
  border-style: inherit !important;
}

.subject-text-field > .v-input__control > .v-input__slot {
  border-style: inherit !important;
  border-bottom: 1px solid #0000001f !important;
  padding: 0 7px;
}

.subject-text-field > .v-input__control > .v-text-field__details {
  padding: 0 7px;
}

.v-snack__wrapper {
  width: 300px !important;
  min-width: 300px !important;
  margin: 20px !important;
}

.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  box-shadow: 0px 0px white !important;
  border-radius: 8px !important;
  height: 60px;
}

.v-snack__content {
  font-weight: 600 !important;
  padding: 0 16px !important;
}

.v-pagination__item {
  box-shadow: none !important;
}

div,
button,
img {
  user-select: none;
}

button:focus {
  outline: 0;
}

.spacer-1 {
  flex-grow: 1;
}

.spacer-2 {
  flex-grow: 2;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  display: none;
}

.draggable {
  user-select: text;
}

.v-input--selection-controls__input > .v-input--switch__track {
  opacity: 1 !important;
}

input[aria-checked='false'] ~ .v-input--switch__track {
  color: #a4a8bb !important;
}

.v-input--selection-controls__input > .v-input--switch__thumb {
  color: #ffffff !important;
}

.v-tabs > .v-item-group {
  background-color: inherit !important;
}

.v-tabs-items {
  background-color: inherit !important;
}

.v-data-table {
  background-color: transparent !important;
}

.frequency-table-header-date {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  border-top: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
  width: 50%;
  &.dark {
    border-top: 1px solid #323340;
    border-right: 1px solid #323340;
  }
}

.v-data-table__empty-wrapper {
  td {
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 95px !important;
  }
}

.aside-log-camera-searchbar > .v-input__control > .v-input__slot > fieldset {
  border-width: 0px;
}

.aside-log-camera-searchbar > .v-input__control > .v-input__slot > .v-select__slot {
  font-weight: 400;
  font-size: 14px;
  color: red;
  height: 41px;
}

.esri-view {
  height: 100%;
}

.esri-ui-top-right {
  top: 25%;
  right: 0;
}

.esri-ui-bottom-right {
  display: flex;
  flex-direction: column-reverse;

  .esri-coordinate-conversion {
    margin-bottom: 10px;
    border-radius: 8px;
    max-width: 300px;

    .esri-coordinate-conversion__conversions-view > ul {
      padding-left: 0px;
    }
  }
}

.esri-feature__content-element {
  margin-bottom: 0px !important;
}

.esri-popup__action-text {
  padding-top: 2px;
}

.esri-popup__header-title {
  user-select: all;
}

.esri-feature-fields__field-header,
.esri-feature-fields__field-data {
  user-select: all;
}

.mapview-toast-container {
  height: 86px;
  width: 360px;
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  &.dark {
    background-color: rgba(50, 51, 64, 0.75) !important;
  }
}

.esri-coordinate-conversion__row-button {
  display: none;
  opacity: 0;
  width: 0px;
  cursor: default;
}

.esri-coordinate-conversion__display {
  user-select: text;
}

.mapview-toast-body {
  display: flex;
  color: #363636;

  &.dark {
    color: #ffffffde;
  }
}

.toast-check-btn > .v-btn__content {
  letter-spacing: 0;
  color: #ffffff;
  font-size: 12px;
}

.mapview-toast-body.dark > .toast-content-container > .toast-check-btn > .v-btn__content {
  color: #ffffffde;
}

.frequency-table-header-time {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  width: 50%;
  border-top: 1px solid #d8d9da;
  width: 50%;
  &.dark {
    border-top: 1px solid #323340;
  }
}

.frequency-table-header-total {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  border-top: 1px solid #d8d9da;
  &.dark {
    border-top: 1px solid #323340;
  }
}

.frequency-table-header-time-total {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  border-top: 1px solid #d8d9da;
  border-left: 1px solid #d8d9da;
  min-width: 140px;
  &.dark {
    border-top: 1px solid #323340;
    border-left: 1px solid #323340;
  }
}

.frequency-table-cell-date {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  text-decoration-line: underline;
  border-right: 1px solid #d8d9da;
  width: 50%;
  &.dark {
    border-right: 1px solid #323340;
  }
}

.frequency-table-cell-time {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  border-right: 1px solid #d8d9da;
  &.dark {
    border-right: 1px solid #323340;
  }
}

.frequency-table-cell-total {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
}

.fullscreen-dialog-ios {
  background-color: black;
  border-radius: 0 !important;
}
.fullscreen-dialog-dev {
  background-color: black;
  border-radius: 0 !important;
}
@media (orientation: portrait) {
  .fullscreen-dialog-dev {
    overflow: hidden;
    width: 100%;
    z-index: 999;
    background-color: black;
  }
}

.nav-width {
  width: 100% !important;
}

.v-application .text-button {
  font-family: 'Avenir Next', 'Noto Sans KR', sans-serif !important;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.requestSuccessToastClass {
  background-color: #34c759 !important;
  border-radius: 40px !important;
}

.requestErrorToastClass {
  background-color: #ff3b30 !important;
  border-radius: 40px !important;
}

.select-action-option > .v-input--selection-controls__input > i.v-icon {
  font-size: 16px;
}

.select-action-option > label.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #363636;
}

.subject-text-field-error-state > .v-input__control > .v-input__slot {
  border-bottom: 1px solid #ff3b30 !important;
}

.subject-text-field > .v-input__control > .v-input__slot > .v-text-field__slot > input::placeholder {
  color: #000000 !important;
  opacity: 0.38 !important;
}

.add-filter-menu,
.camera-list-filter-menu,
.camera-list-select-action-btn-menu {
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1) !important;
}
</style>
