<template>
  <div class="support-floating-button" @click="$emit('click')" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <img src="@/assets/Support_Filled_32px_Icon.svg" width="30px" :style="imageStyle" />
    <transition name="slide-fade">
      <div v-if="hover">Contact Support</div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    imageStyle() {
      if (this.hover) {
        return 'margin-right:7px;'
      }
      return ''
    },
  },
  methods: {
    onMouseOver() {
      this.hover = true
    },
    onMouseOut() {
      this.hover = false
    },
  },
}
</script>

<style lang="scss" scoped>
.support-floating-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  background-color: var(--f-primary-90);
  border-radius: 30px;
  transition: width 0.1s;
  font-weight: 600;
  font-size: 16px;
  color: white;
  cursor: pointer;
  z-index: 6;

  &:hover {
    width: 192px;
  }
}
</style>

<style>
.slide-fade-enter {
  opacity: 0;
}
.slide-fade-enter-to {
  opacity: 1;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave {
  opacity: 1;
}
.slide-fade-leave-to {
  opacity: 0;
}
</style>
