import isMobilejs from 'ismobilejs'

export default {
  data() {
    return {
      isMobile: false,
    }
  },
  created() {
    this.setCondition(this.$windowWidth, true)
  },
  watch: {
    $windowWidth: function (width) {
      this.setCondition(width, false)
    },
    $route(val) {
      this.setCondition(this.$windowWidth, false)
    },
  },
  methods: {
    setCondition(width, isFirst) {
      const device = isMobilejs(window.navigator)
      const oldCondition = this.isMobile
      if (device.phone || device.tablet || width <= 600) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      const pathArr = this.$route.fullPath.split('/').filter((path) => !!path)
      if (isFirst || oldCondition !== this.isMobile) {
        if (pathArr[0] === 'm') {
          if (!this.isMobile) {
            pathArr.shift()
            const url = '/' + pathArr.join('/')
            this.$router.replace(url)
          }
        } else {
          if (this.isMobile) {
            const url = '/m/' + pathArr.join('/')
            this.$router.replace(url)
          }
        }
      }
    },
  },
}
