import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueLogger from 'vuejs-logger'
import { VueWindowSizePlugin } from 'vue-window-size/option-api'
import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'
import Hotjar from './hotjar'

// material icons
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Toast, { POSITION } from 'vue-toastification'
// Import vue-toastification CSS
import 'vue-toastification/dist/index.css'
const toastOptions = {
  hideProgressBar: true,
  icon: false,
  position: POSITION.TOP_RIGHT,
  timeout: 7000,
}
Vue.use(Toast, toastOptions)

//Arcgis javascript api
import { loadScript } from 'esri-loader'

Vue.config.productionTip = false

// preload the ArcGIS API
const options = {
  url: 'https://js.arcgis.com/4.23/',
}
loadScript(options)

// hide below warning message from appearing in console
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

Vue.use(VueMeta)
Vue.use(VueWindowSizePlugin)
Vue.use(VueLogger, {
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  showMethodName: true,
  separator: '|',
})
if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-TGW1CW1K0H' },
    },
    router
  )
  Vue.use(VueGtm, {
    id: 'GTM-PL3GSKH',
    debug: true, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  })
  if (process.env.VUE_APP_ENV !== 'qa' && process.env.VUE_APP_ENV !== 'develop') {
    Hotjar()
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

console.info(`version ${process.env.VUE_APP_VERSION}`)
