/**
 * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop
 */
import axios from 'axios'
import { fileDownloadInterceptorRequest, fileDownloadInterceptorResponse, interceptorRequest, interceptorResponse } from './auth'
import RESPONSE_CODE from '@/responseCode'

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL })
api.interceptors.request.use(interceptorRequest)
api.interceptors.response.use(null, interceptorResponse)

const downloadFileApi = axios.create({ baseURL: process.env.VUE_APP_API_URL })
downloadFileApi.interceptors.request.use(fileDownloadInterceptorRequest)
downloadFileApi.interceptors.response.use(null, fileDownloadInterceptorResponse)

function buildParams(data) {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()))
    } else {
      params.append(key, value.toString())
    }
  })

  return params.toString()
}

////////////////////////////////////////
// Commons
////////////////////////////////////////

/**
 * @param {string} name
 * @returns {boolean}
 */
export async function checkOrganizationName(name) {
  try {
    const { data } = await api.post('organization/check-name', { name })
    if (data?.result_code !== 'FIRE-S0000') {
      throw data
    }
    return data.duplicate
  } catch (e) {
    return true
  }
}

/**
 * @param {string} workspace
 * @returns {boolean}
 */
export async function checkWorkspaceName(workspace) {
  try {
    const { data } = await api.post('organization/check-workspace', { workspace })
    if (data?.result_code !== 'FIRE-S0000') {
      throw data
    }
    return data.duplicate
  } catch (e) {
    return true
  }
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getContractTypes() {
  const { data } = await api.get('contract-type')
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.contract_types
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getProviders() {
  const { data } = await api.get('provider')
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.providers
}

/**
 * @returns {{id:number,name:string}[]}
 */

export async function getApiKeys(providerId) {
  const { data } = await api.get(`api-key/provider/${providerId}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.api_keys
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getRegions(countries) {
  const params = {}
  if (countries) {
    params.location_ids = countries
  }
  const query = buildParams(params)
  const { data } = await api.get(`region?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.regions
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getCounties(regions) {
  const params = {}
  if (regions) {
    params.region_ids = regions
  }
  const query = buildParams(params)
  const { data } = await api.get(`county?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.counties
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getCamera(id) {
  const { data } = await api.get(`camera/${id}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return {
    information: data.camera_information,
    condition: data.camera_condition,
  }
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getCountries() {
  const { data } = await api.get('location')
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.locations
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getCameraGroups() {
  const { data } = await api.get('camera-group')
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.camera_groups
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getPanFixed() {
  //const { data } = await api.get('pan-fixed')
  const data = {
    pan_fixed: [
      {
        id: 2,
        name: 'Fixed',
      },
      {
        id: 1,
        name: 'Pan',
      },
      {
        id: 3,
        name: 'Unknown',
      },
    ],
    result_code: 'FIRE-S0000',
  }
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }

  return data.pan_fixed
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getMonitored() {
  //const { data } = await api.get('monitored')
  const data = {
    monitored: [
      {
        id: 'true',
        name: 'Y',
      },
      {
        id: 'false',
        name: 'N',
      },
    ],
    result_code: 'FIRE-S0000',
  }
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.monitored
}

/**
 * @returns {{id:number,name:string}[]}
 */
export async function getMonitoringGroups() {
  //const { data } = await api.get('monitoring-group')
  const data = {
    monitored: [
      {
        id: 'true',
        name: 'Y',
      },
      {
        id: 'false',
        name: 'N',
      },
    ],
    result_code: 'FIRE-S0000',
  }
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.monitored
}

////////////////////////////////////////
// Organizations
////////////////////////////////////////

/**
 * @param {{keyword:string, name:string}}
 * @returns {{id:number,name:string,workspace:string,contract_type_id:string,contract_start:string,contract_end:string}[]}
 */
export async function getOrganizations({ keyword, name } = {}) {
  let params = {}
  if (keyword) {
    params.q = keyword
  } else if (name) {
    params.name = name
  }
  const query = buildParams(params)
  const { data } = await api.get(`organization?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.organizations
}

/**
 * @param {{location_id: number, name: string}} payload
 * @returns {{result_code: string, region: {id: number, name: string}}}
 */
export async function createRegion(payload) {
  const { data } = await api.post('region', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
  return data.region
}

/**
 * @param {{region_id: number, name: string}} payload
 * @returns {{result_code: string, county: {id: number, name: string}}}
 */
export async function createCounty(payload) {
  const { data } = await api.post('county', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
  return data.county
}

/**
 * @param {{name: string}} payload
 * @returns {{result_code: string, provider: {id: number, name: string}}}
 */
export async function createProvider(payload) {
  const { data } = await api.post('provider', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
  return data.provider
}

/**
 * @param {{key: string, provider_id: number}} payload
 * @returns {{result_code: string, api_key: {id: number, name: string}}}
 */
export async function createApiKey(payload) {
  const { data } = await api.post('api-key', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
  return data.api_key
}

/**
 * @param {{name:string, workspace:string, contract_type_id:number, contract_start:string, contract_end: string}} payload
 */
export async function createOrganizations(payload) {
  const { data } = await api.post('organization', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

/**
 * @param {{name:string, contract_type_id:string, contract_start:string, contract_end: string}} payload
 */
export async function updateOrganizations(id, payload) {
  const { data } = await api.put(`organization/${id}`, payload)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

////////////////////////////////////////
// Users
////////////////////////////////////////

/**
 * @param {string} keyword
 * @returns {{id:number,organization_name:string,user_id:string,user_role:string,last_login_time:string}[]}
 */
export async function getUsers(keyword = '') {
  const { data } = await api.get(`user?q=${keyword}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.users
}

/**
 * @param {{organization_id:number,user_role_id:number,user_id:string,password:string}} payload user_role_id: 3(ADMIN), 4(VIEWER)
 */
export async function createUser(payload) {
  const { data } = await api.post('user', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

export async function getUserRoleList() {
  const { data } = await api.get('role')
  if (data?.result_code === RESPONSE_CODE.SUCCESS_REQUEST) {
    return data.roles
  } else return []
}

/**
 * @param {{user_role_id:number, user_id:string, password:string}} payload
 */
export async function updateUser(id, payload) {
  const { data } = await api.put(`user/${id}`, payload)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

/**
 * @param {string[]} user_ids
 */
export async function deleteUsers(user_ids) {
  const { data } = await api.post('user/delete-targets', { user_ids })
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

/**
 * @param {string} user_id
 * @returns {boolean}
 */
export async function checkUserId(user_id) {
  try {
    const { data } = await api.post('user/check-id', { user_id })
    if (data?.result_code !== 'FIRE-S0000') {
      throw data
    }
    return { duplicated: data.duplicate, deleted: data.deleted }
  } catch (e) {
    return true
  }
}

////////////////////////////////////////
// Notification
////////////////////////////////////////
/**
 * @param {number} organization_id
 * @param {string} keyword
 * @returns {{id:number,name:string,email:string,country_code:string,phone_number:string,allow_email:boolean,allow_sms:boolean,memo:string,created_at:string}[]}
 */
export async function getNotiReceiversInOrganization(organization_id, keyword = '') {
  let params = { organization_id, q: keyword }
  const query = buildParams(params)
  const { data } = await api.get(`noti?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.receivers
}

/**
 * @param {{organization_id:number,name:string,email:string,country_code:string,phone_number:string,allow_email:boolean,allow_sms:boolean,memo:string}} payload
 */
export async function createNotiReceiver(payload) {
  const { data } = await api.post('noti', payload)
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

/**
 * @param {{name:string,email:string,country_code:string,phone_number:string,allow_email:boolean,allow_sms:boolean,memo:string}} payload
 */
export async function updateNotiReceiver(receiver_id, payload) {
  const { data } = await api.put(`noti/${receiver_id}`, payload)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

/**
 * @param {string[]} receiver_ids
 */
export async function deleteNotiReceivers(receiver_ids) {
  const { data } = await api.post('noti/delete-targets', { receiver_ids })
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

export async function deleteAllNotiReceivers(organization_id) {
  const { data } = await api.delete(`/organization/${organization_id}/noti`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

////////////////////////////////////////
// Camera
////////////////////////////////////////

/**
 * @param {string} keyword
 * @param {number[]} organizationIds
 * @param {number[]} countryIds
 * @param {number[]} regionIds
 * @param {number[]} countyIds
 * @param {number[]} cameraGroupIds
 * @param {number[]} panFixedIds
 * @param {string[]} monitored
 * @returns {{id:number,name:string,region:string,county:string,description:string,organizations:{id:number,name:string}[],coordinate:string}[]}
 */
export async function getCameras({ keyword, organizationIds, countryIds, regionIds, countyIds, cameraGroupIds, panFixedIds, monitored }) {
  let params = {}
  keyword.length && (params.q = keyword)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  const { data } = await api.get(`camera/all?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.cameras
}

/**
 * @param {string} keyword
 * @param {number[]} organizationIds
 * @param {number[]} countryIds
 * @param {number[]} regionIds
 * @param {number[]} countyIds
 * @param {number[]} cameraGroupIds
 * @param {number[]} panFixedIds
 * @param {string[]} monitored
 * @returns {{id:number,name:string,region:string,county:string,description:string,organizations:{id:number,name:string}[],coordinate:string}[]}
 */
export async function getFullInfoCameras({ keyword, organizationIds, countryIds, regionIds, countyIds, cameraGroupIds, panFixedIds, monitored }) {
  let params = { full_info: true }
  keyword.length && (params.q = keyword)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  const { data } = await api.get(`camera/all?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.cameras
}

/**
 * @param {number} organization_id
 * @param {string} keyword
 * @param {number[]} region_ids
 * @param {number[]} county_ids
 * @returns {{id:number,name:string,region:string,county:string,description:string,axis_name:string}[]}
 */
export async function getAllocatedCameras(organization_id, keyword = '', region_ids = [], county_ids = []) {
  let params = { organization_id }
  keyword.length && (params.q = keyword)
  region_ids.length && (params.region_ids = region_ids)
  county_ids.length && (params.county_ids = county_ids)
  const query = buildParams(params)
  const { data } = await api.get(`allocated-camera?${query}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
  return data.cameras
}

/**
 * @param {number} organization_id
 * @param {number[]} camera_ids
 */
export async function createAllocatedCamera(organization_id, camera_ids) {
  const { data } = await api.post(`allocated-camera`, { organization_id, camera_ids })
  if (data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

/**
 * @param {number} organization_id
 * @param {number[]} camera_ids
 */
export async function deleteAllocatedCamera(organization_id, camera_ids) {
  const { data } = await api.post(`allocated-camera/delete-targets`, { organization_id, camera_ids })
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

/**
 * @param {number} organization_id
 */
export async function deleteAllocatedCameraInOrganization(organization_id) {
  const { data } = await api.delete(`allocated-camera/${organization_id}`)
  if (data?.result_code !== 'FIRE-S0000') {
    throw data
  }
}

////////////////////////////////////////
// Camera List Tab API
////////////////////////////////////////

export async function getReviewList() {
  return await api.get('camera/review')
}

export async function getHistoryList() {
  return await api.get('camera/history')
}

export async function getDiscussionList() {
  return await api.get(`camera/discussion`)
}

export async function getArchiveList() {
  return await api.get(`camera/archive`)
}

export async function createDiscussion(content) {
  return await api.post('camera/discussion', { content })
}

export async function updateDiscussionResolve(id) {
  return await api.patch(`camera/discussion/${id}`, {
    is_resolve: true,
  })
}

export async function createDiscussionReply(id, content) {
  return await api.post(`camera/discussion/${id}/comment`, { content })
}

export async function getCameraListTabStatusCount() {
  return await api.get('camera/status/count')
}

export async function mergeReviewedRequest(id) {
  return await api.post(`camera/review/${id}/merge`)
}

export async function archiveReviewedRequest(id) {
  return await api.post(`camera/review/${id}/archive`)
}

export async function deleteReviewedRequest(id) {
  return await api.delete(`camera/review/${id}`)
}

export async function reviewRequestedReview(id, status) {
  return await api.patch(`camera/review/${id}/recipient`, { status })
}

export async function rollbackHistory(id) {
  return await api.post(`camera/history/${id}/rollback`)
}

export async function updateDiscussionStatus(id, isResolve) {
  return await api.patch(`camera/discussion/${id}`, { is_resolve: isResolve })
}

export async function getFilteredRequestCamList({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  id && (params.id = id)
  const query = buildParams(params)
  return await api.get(`camera/review/${id}?${query}`)
}

export async function getFilteredHistoryCamList({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  id && (params.id = id)
  const query = buildParams(params)
  return await api.get(`camera/history/${id}?${query}`)
}

export async function getFilteredArchiveCamList({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  id && (params.id = id)
  const query = buildParams(params)
  return await api.get(`camera/archive/${id}?${query}`)
}

////////////////////////////////////////
// Camera List Changes: Submit/Merge/Archive
////////////////////////////////////////

/**
 * @param {String} subject
 * @param {number[]} rrl_ids
 * @param {Object[]} edit_data
 */
export async function submitCameraChanges(payload) {
  const { data } = await api.post(`camera/review`, payload)
  if (data?.result_code !== 'FIRE-S0000' && data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

export async function submitCameraChangesInEditModeOfExistingRequest(payload, id) {
  return await api.put(`camera/review/${id}`, payload)
}

/**
 * @param {String} subject
 * @param {Object[]} edit_data
 */
export async function mergeCameraChanges(payload) {
  return await api.post(`camera/merge`, payload)
}

/**
 * @param {String} subject
 * @param {Object[]} edit_data
 */
export async function archiveCameraChanges(payload) {
  const { data } = await api.post(`camera/archive`, payload)
  if (data?.result_code !== 'FIRE-S0000' && data?.result_code !== 'FIRE-S0001') {
    throw data
  }
}

/**
 * @param {string} column
 * @param {string} value
 */
export async function getCameraValidation(colum, value) {
  const { data } = await api.get(`camera/validation?column=${colum}&value=${value}`)
  return data.validation
}

export async function getCameraDetailInfo(id) {
  return await api.get(`camera/${id}`)
}

export async function getCameraScoringCriteria() {
  return await api.get(`camera-scoring-criteria`)
}

export async function getCameraTemplateFile() {
  return await downloadFileApi.get('camera/excel')
}

export async function getCameraListExcelFile({ cameraGroupIds, countryIds, countyIds, monitored, organizationIds, panFixedIds, q, regionIds }) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  return await downloadFileApi.get(`/camera/all/excel?${query}`)
}

export async function getCameraListRequestExcelFile({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  return await downloadFileApi.get(`/camera/review/${id}/excel?${query}`)
}

export async function getCameraListHistoryExcelFile({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  return await downloadFileApi.get(`/camera/history/${id}/excel?${query}`)
}

export async function getCameraListArchiveExcelFile({
  id,
  cameraGroupIds,
  countryIds,
  countyIds,
  monitored,
  organizationIds,
  panFixedIds,
  q,
  regionIds,
}) {
  let params = {}
  q.length && (params.q = q)
  organizationIds && organizationIds.length && (params.organization_ids = organizationIds)
  countryIds && countryIds.length && (params.country_ids = countryIds)
  regionIds && regionIds.length && (params.region_ids = regionIds)
  countyIds && countyIds.length && (params.county_ids = countyIds)
  cameraGroupIds && cameraGroupIds.length && (params.camera_group_ids = cameraGroupIds)
  panFixedIds && panFixedIds.length && (params.pan_or_fixed = panFixedIds)
  monitored && monitored.length && (params.monitored = monitored)
  const query = buildParams(params)
  return await downloadFileApi.get(`/camera/archive/${id}/excel?${query}`)
}
