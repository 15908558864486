<template>
  <button @click="clickButton" :class="{ dark: $vuetify.theme.dark }">
    {{ content }}
  </button>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
export default {
  mixins: [Theme],
  props: {
    content: {
      type: String,
      default: 'button',
    },
  },
  methods: {
    clickButton() {
      this.$emit('clickMethod')
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--f-text-white);
  height: 44px;
  color: black;
  color: var(--f-primary-90);
  &.dark {
    color: var(--f-text-white-high);
  }
}
</style>
