<template>
  <div class="toast-close-icon-container" @click="$emit('close-toast')">
    <img src="@/assets/Close_Line_18px_2_Icon_white.svg" height="14" width="14" />
  </div>
</template>

<script>
export default {}
</script>

<style>
.toast-close-icon-container {
  height: 16px;
  width: 16px;
  opacity: 1 !important;
  padding: 0px !important;
}
</style>
