<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="10px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <InputSearchBar
        v-bind="attrs"
        v-on="on"
        :value="text"
        @input="onInput"
        placeholder="Search"
        @focus="onFocus"
        @blur="onBlur"
        @clear="onClear"
        @keydown="onKeydown"
      ></InputSearchBar>
    </template>
    <div v-if="filteredItems.length" ref="dropdownContainer" class="dropdown-container">
      <div class="wrapper">
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="onClickItem(item)"
          :class="{ item: true, select: getItemValue(select) === getItemValue(item) }"
        >
          <div>{{ filteredText[index].prev }}</div>
          <div class="highlight">{{ filteredText[index].text }}</div>
          <div>{{ filteredText[index].next }}</div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'
export default {
  components: { InputSearchBar },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      text: '',
      focus: false,
      select: null,
    }
  },
  computed: {
    filteredItems() {
      if (this.text.length) {
        return this.items.filter((item) => {
          const value = this.getItemText(item)
          const text = value.toLowerCase()
          const search = this.text.toLowerCase()
          const check = text.includes(search)
          return check
        })
      } else {
        return this.items
      }
    },
    filteredText() {
      if (this.text.length) {
        return this.filteredItems.map((item) => {
          const value = this.getItemText(item)
          const text = value.toLowerCase()
          const search = this.text.toLowerCase()
          const index = text.indexOf(search)
          return {
            prev: value.substring(0, index),
            text: value.substring(index, index + search.length),
            next: value.substring(index + search.length, value.length),
          }
        })
      } else {
        return this.filteredItems.map((item) => {
          const value = this.getItemText(item)
          return { prev: value, text: '', next: '' }
        })
      }
    },
  },
  methods: {
    onChange(val) {
      if (this.focus && !val) {
        this.$nextTick(() => {
          this.open = true
        })
      } else if (!val) {
        if (this.$refs.dropdownContainer) this.$refs.dropdownContainer.scrollTop = 0
        this.select = null
      }
    },
    onFocus() {
      this.focus = true
    },
    onBlur() {
      this.focus = false
    },
    onInput(text) {
      this.text = text
    },
    onClear() {
      this.select = null
      this.$emit('input', null)
    },
    onKeydown(e) {
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        if (this.select) {
          let index = this.filteredItems.findIndex((item) => this.getItemValue(item) === this.getItemValue(this.select))
          if (index === -1) {
            this.select = this.filteredItems[0]
          } else {
            index = e.code === 'ArrowDown' ? index + 1 : index - 1
            const select = this.filteredItems[index]
            if (select) {
              this.select = select
              // scroll move
              if (this.$refs.dropdownContainer) {
                const offsetTop = this.$refs.dropdownContainer.querySelector('.select').offsetTop
                if (e.code === 'ArrowDown') {
                  this.$refs.dropdownContainer.scrollTop = offsetTop
                } else {
                  this.$refs.dropdownContainer.scrollTop = offsetTop - 37 * 2
                }
              }
            }
          }
        } else {
          this.select = this.filteredItems[0]
        }
      } else if (e.code === 'Enter') {
        const item = this.filteredItems.find((item) => item === this.select)
        if (this.select && item) {
          this.onClickItem(this.select)
        }
      }
    },
    onClickItem(item) {
      let value = this.getItemValue(item)
      this.open = false
      this.select = item
      this.onInput(this.getItemText(item))
      this.$emit('input', value)
    },
    getItemText(item) {
      return item && this.itemText ? item[this.itemText] : item
    },
    getItemValue(item) {
      return item && this.itemValue ? item[this.itemValue] : item
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-container {
  width: 100%;
  max-height: 232px;
  padding: 6px 0;
  background-color: var(--v-backgroundAsideHeaderRecent-base);
  color: var(--v-textDefault);
  overflow: auto;

  .item {
    display: flex;
    align-items: center;
    min-height: 37px;
    padding: 0 30px;
    width: 100%;
    font-size: 14px;

    &:hover,
    &.select {
      background-color: var(--v-backgroundAlertOff-base);
    }

    .highlight {
      background-color: var(--v-backgroundSearchMask);
    }
  }
}
</style>
