/**
 * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop
 */
import Vue from 'vue'
import axios from 'axios'
import Constants from '../constants'
import NativeService from '../native-service'
import router from '../router'
import store from '../store'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL })

const LOGIN_PATH = '/login'
let MAIN_PATH = '/'
let token = ''
let expire = ''
let expireMax = ''
let user = {}
let refreshTimer = null

NativeService.getData(Constants.KEEP_LOGIN, (value) => {
  NativeService.getData(
    Constants.TOKEN_NAME,
    (value) => {
      token = value || ''
    },
    !value
  )
  NativeService.getData(
    Constants.TOKEN_EXPIRE,
    (value) => {
      expire = value || ''
    },
    !value
  )
  NativeService.getData(
    Constants.TOKEN_EXPIRE_MAX,
    (value) => {
      expireMax = value || ''
      if (expireMax && new Date(expireMax).getTime() < Date.now()) {
        init()
      }
    },
    !value
  )
  // prev version compatibility
  if (value === null) {
    keepLogin(true)
  }
})

if (expire) {
  setImmediate(() => {
    startRefreshTimer(expire)
  })
}

export function init() {
  NativeService.removeData(Constants.TOKEN_NAME)
  NativeService.removeData(Constants.TOKEN_NAME, true)
  NativeService.removeData(Constants.TOKEN_EXPIRE)
  NativeService.removeData(Constants.TOKEN_EXPIRE, true)
  NativeService.removeData(Constants.TOKEN_EXPIRE_MAX)
  NativeService.removeData(Constants.TOKEN_EXPIRE_MAX, true)
  NativeService.removeData(Constants.TOKEN_NAME_SETTING, true)
  token = ''
  expire = ''
  expireMax = ''
}

export function isAuth() {
  return !!token
}

export function keepLogin(keep) {
  NativeService.setData(Constants.KEEP_LOGIN, keep)
  // remove session data
  NativeService.removeData(Constants.TOKEN_NAME, keep)
  NativeService.removeData(Constants.TOKEN_EXPIRE, keep)
  NativeService.removeData(Constants.TOKEN_EXPIRE_MAX, keep)
  // set session data
  NativeService.setData(Constants.TOKEN_NAME, token, !keep)
  NativeService.setData(Constants.TOKEN_EXPIRE, expire, !keep)
  expireMax = expire ? moment().add(Constants.TOKEN_EXPIRE_MAX_DAY, 'd').utc().format() : ''
  // expireMax = expire ? moment().add(1, 'h').utc().format() : '' // for test
  NativeService.setData(Constants.TOKEN_EXPIRE_MAX, expireMax, !keep)
}

function setTokenData(t, e) {
  NativeService.getData(Constants.KEEP_LOGIN, (keep) => {
    token = t
    expire = e || ''
    NativeService.setData(Constants.TOKEN_NAME, token, !keep)
    NativeService.setData(Constants.TOKEN_EXPIRE, expire, !keep)
    if (!expireMax) {
      expireMax = expire ? moment().add(Constants.TOKEN_EXPIRE_MAX_DAY, 'd').utc().format() : ''
      // expireMax = expire ? moment().add(1, 'h').utc().format() : '' // for test
      NativeService.setData(Constants.TOKEN_EXPIRE_MAX, expireMax, !keep)
    }
  })
}

export async function login(username, password, justLogin = true) {
  init()
  const { data } = await api.post('/sign-in', { username, password })
  await saveToken(data, justLogin)
}

export async function ssoLogin(cookie, justLogin = true) {
  init()
  const cookieData = { token: cookie }
  await saveToken(cookieData, justLogin)
}

async function saveToken(data, justLogin) {
  if (data && data.token) {
    setTokenData(data.token, data.expire)
    const userRole = await getSelfInfo()
    setAdminPageWorkerList(userRole)
    startRefreshTimer(expire)
    const redirect = router.app.$route.query.redirect
    if (redirect && redirect.length > 1) {
      replaceToPath(redirect)
    } else {
      replaceToPath(MAIN_PATH)
    }
  } else {
    const error = new Error('Token is not exist.')
    error.response = response
    response.data = error.message
    throw error
  }
}

export function logout() {
  init()
  if (!router.app.$route.fullPath.includes(LOGIN_PATH)) {
    replaceToPath(LOGIN_PATH)
  }
}

export async function signup({ name, agency, email_contract, inquiry }) {
  const { data } = await api.post('/sign-up-email', { name, agency, email_contract, inquiry })
  return data
}

export async function validPassword(password) {
  const { data } = await api.post('/password', { password }, { headers: { Authorization: `Bearer ${token}` } })
  return data?.result?.code === 'FIRE-S0000'
}

export async function checkPassword(password) {
  const { data } = await api.post('/check-password', { password }, { headers: { Authorization: `Bearer ${token}` } })
  const pass = data?.result_code === 'FIRE-S0000'
  if (pass) {
    NativeService.setData(Constants.TOKEN_NAME_SETTING, uuidv4(), true)
  }
  return pass
}

export async function isSettingPasswordConfirmed() {
  return new Promise((resolve) => {
    NativeService.getData(Constants.TOKEN_NAME_SETTING, (v) => resolve(v), true)
  })
}

export function clearSettingPasswordConfirmed() {
  NativeService.removeData(Constants.TOKEN_NAME_SETTING, true)
}

async function startRefreshTimer(expire) {
  clearTimeout(refreshTimer)
  if (expire) {
    const safeTime = 60 * 60 * 1000 // timeout 오차를 생각해서 약 1시간전에 expire시킴
    let remainExpire = new Date(expire).getTime() - Date.now() - safeTime
    if (remainExpire > 0) {
      refreshTimer = setTimeout(async () => {
        await refreshToken()
      }, remainExpire)
    } else {
      await refreshToken()
    }
  }
}

export async function getToken() {
  return token
}

async function refreshToken() {
  Vue.$log.debug('refreshToken')
  const { data } = await api.get('/token/refresh', { headers: { Authorization: `Bearer ${token}` } })
  if (data?.token) {
    setTokenData(data.token, data.expire)
    startRefreshTimer(expire)
  }
  return true
}

let selfInfoRequest = false
export async function getSelfInfo() {
  try {
    if (selfInfoRequest) {
      return
    }
    selfInfoRequest = true
    const { data } = await api.get('/self', { headers: { Authorization: `Bearer ${token}` } })
    if (data && data.info) {
      user = data.info
      if (user.organization_name) {
        MAIN_PATH = `/${user.organization_name}`
      } else if (user.organization.name) {
        MAIN_PATH = `/${user.organization.name}`
      }
    } else {
      user = {
        user_name: '',
        user: {
          role: '',
        },
      }
    }
  } catch (e) {
    user = {
      user_name: '',
      user: {
        role: '',
      },
    }
  }
  store.commit('setUser', user)
  if (!store.state.superadmin.workerList.length) setAdminPageWorkerList(user.user.role)
  setTimeout(() => {
    // 중복 요청 방지
    selfInfoRequest = false
  }, 1000)
  return user.user.role
}

async function setAdminPageWorkerList(userRole) {
  if (userRole === Constants.ROLE_WORKER) {
    await store.dispatch('superadmin/getWorkerListAction', { isSuperadmin: false })
    setAdminPageCalendar()
  } else if (userRole === Constants.ROLE_SUPER_ADMIN) {
    await store.dispatch('superadmin/getWorkerListAction', { isSuperadmin: true })
    setAdminPageCalendar()
  }
}

function setAdminPageCalendar() {
  store.commit('superadmin/setDatePeriodMutation', {
    startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
}

function replaceToPath(path, query = {}, routerGuard) {
  if (router.app.$children[0] && router.app.$children[0].isMobile) {
    if (!path.includes('/m/')) {
      path = '/m' + path
    }
  }
  if (routerGuard) {
    routerGuard(false)
  }
  Vue.$log.debug('replaceToPath', path)
  router.app.$router.replace({ path, query })
}

/**
 * @param {AxiosRequestConfig} config
 */
export function interceptorRequest(config) {
  config.headers.Authorization = `Bearer ${token}`
  return config
}

export function fileDownloadInterceptorRequest(config) {
  config.headers.Authorization = `Bearer ${token}`
  config.headers['Content-Description'] = 'File Transfer'
  config.headers['Content-Disposition'] = `attachment; filename=Camera_List_Template`
  config.headers['Content-Type'] = 'application/octet-stream'
  config.responseType = 'blob'
  return config
}

/**
 * Unauthorization
 * @param {AxiosResponse} res
 */
export async function interceptorResponse(res) {
  if (res.response && res.response.status === 401 && !router.app.$route.path.includes(LOGIN_PATH)) {
    if (!token) {
      replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
    } else {
      try {
        const result = await refreshToken()
        if (result) {
          interceptorRequest(res.config)
          return axios.request(res.config)
        } else {
          replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
        }
      } catch (e) {
        replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
      }
    }
  }
  return Promise.reject(res)
}

/**
 * Unauthorization
 * @param {AxiosResponse} res
 */
export async function fileDownloadInterceptorResponse(res) {
  if (res.response && res.response.status === 401 && !router.app.$route.path.includes(LOGIN_PATH)) {
    if (!token) {
      replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
    } else {
      try {
        const result = await refreshToken()
        if (result) {
          fileDownloadInterceptorRequest(res.config)
          return axios.request(res.config)
        } else {
          replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
        }
      } catch (e) {
        replaceToPath(LOGIN_PATH, { redirect: router.app.$route.fullPath })
      }
    }
  }
  return Promise.reject(res)
}

export async function getSsoUrl() {
  return await api.post('sso/pgne/url')
}

export function navigationGuard(to, from, next) {
  let auth = to.meta.auth
  if (auth) {
    if (token) {
      next()
    } else {
      replaceToPath(LOGIN_PATH, { redirect: to.fullPath }, next)
    }
  } else if (auth === undefined) {
    replaceToPath(LOGIN_PATH, { redirect: to.fullPath }, next)
  } else {
    next()
  }
}
