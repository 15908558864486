import axios from 'axios'
import qs from 'qs'
import { interceptorRequest, interceptorResponse } from './auth'

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL })

api.interceptors.request.use(interceptorRequest)
api.interceptors.response.use(null, interceptorResponse)

// FREQUENCY APIs
// GET /analytics/frequency/date/months
// Description: Daily list of alert frequencies for each month
export async function getFrequencyDateMonths(payload) {
  const joinPayload = {
    start_time: payload.start_time,
    end_time: payload.end_time,
  }

  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/date/months?${query}`)
}

// GET /analytics/frequency/date/periods
// Description: Daily list of alert frequencies for each period
export async function getFrequencyDatePeriods(payload) {
  const joinPayload = {
    start_time: payload.start_time.join(','),
    end_time: payload.end_time.join(','),
    time_zone: payload.time_zone,
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/date/periods?${query}`)
}

// GET /analytics/frequency/date/weekly
// Description: Daily list of alert frequencies for each week
export async function getFrequencyDateWeekly(payload) {
  const joinPayload = {
    start_time: payload.start_time,
    end_time: payload.end_time,
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/date/weekly?${query}`)
}

// GET /analytics/frequency/time/days
// Description: Hourly list of alert frequencies for each day
export async function getFrequencyTimeDays(payload) {
  const joinPayload = {
    dates: payload.dates.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/time/days?${query}`)
}

// GET /analytics/frequency/time/months
// Description: Hourly list of alert frequencies for each month
export async function getFrequencyTimeMonths(payload) {
  const joinPayload = {
    months: payload.months.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/time/months?${query}`)
}

// GET /analytics/frequency/time/periods
// Description: Hourly list of alert frequencies for each period
export async function getFrequencyTimePeriods(payload) {
  const joinPayload = {
    start_time: payload.start_time.join(','),
    end_time: payload.end_time.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/time/periods?${query}`)
}

// GET /analytics/frequency/time/weekly
// Description: Hourly list of alert frequencies for each week
export async function getFrequencyTimeWeekly(payload) {
  const joinPayload = {
    start_time: payload.start_time.join(','),
    end_time: payload.end_time.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/time/weekly?${query}`)
}

// GET /analytics/frequency/week-hour
// Description: List of alert frequencies for each weekday and time
export async function getFrequencyTimeWeekHour(payload) {
  const joinPayload = {
    start_time: payload.start_time,
    end_time: payload.end_time,
    time_zone: payload.time_zone,
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/frequency/week-hour?${query}`)
}

//------------------------------------------------------------------------

// CAMERA APIs

// GET /analytics/camera/days
// Description: Count of alerts per camera each day
export async function getCamAlertsCountByDays(payload) {
  // const query = qs.stringify(payload, { arrayFormat: 'comma', indices: false })
  const joinPayload = {
    dates: payload.dates.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/camera/days?${query}`)
}

// GET /analytics/camera/months
// Description: Count of alerts per camera each month
export async function getCamAlertsCountByMonths(payload) {
  const joinPayload = {
    months: payload.months.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/camera/months?${query}`)
}

// GET /analytics/camera/period
// Description: Count of alerts per camera each period
export async function getCamAlertsCountByPeriod(payload) {
  const joinPayload = {
    start_time: payload.start_time,
    end_time: payload.end_time,
    rank: payload.rank,
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/camera/period?${query}`)
}

// GET /analytics/camera/week
// Description: Count of alerts per camera each week
export async function getCamAlertsCountByWeek(payload) {
  const joinPayload = {
    start_time: payload.start_time.join(','),
    end_time: payload.end_time.join(','),
  }
  const query = qs.stringify(joinPayload).replaceAll('%2C', ',')
  return await api.get(`analytics/camera/weekly?${query}`)
}

//------------------------------------------------------------------------

// EXPORT/DOWNLOAD API

// Downloads excel file of analytics

// Frequency Page
export async function exportFrequencyExcelFile(payload) {
  return await api.post(`analytics/frequency/export`, payload)
}

// Camera Page
export async function exportCameraExcelFile(payload) {
  return await api.post(`analytics/camera/export`, payload)
}
