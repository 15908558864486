<template>
  <section :class="{ 'export-dropdown': true, dark: $vuetify.theme.dark }">
    <div v-if="$route.path !== '/analytics-overview'" :class="{ 'export-dropdown-option': true, dark: $vuetify.theme.dark }" @click="exportExcel">
      Excel (.xlsx)
    </div>
    <div :class="{ 'export-dropdown-option': true, dark: $vuetify.theme.dark }" @click="exportPdf">PDF</div>
    <div v-if="showDownloadDefender" class="download">
      <SnackBar :value="showDownloadDefender" :color="'secondary'" :message="snackBarMessage"></SnackBar>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { mapState } from 'vuex'
import SnackBar from '@common/SnackBarClipboard'
import { analytics } from '@/api'
import Constants from '@/constants'
import Util from '@/util'

export default {
  components: { SnackBar },
  props: {
    currentCameraOption: {
      type: Object,
      default: null,
    },
    currentFrequencyOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showDownloadDefender: false,
    }
  },
  computed: {
    ...mapState({
      overviewDateTimeRange: (state) => state.analytics.overview.selectedDateRangeText,
      cameraDateTimeRange: (state) => state.analytics.camera.selectedDateTimeRange,
      cameraDateRangeText: (state) => state.analytics.camera.selectedDateRangeText,
      frequencyDateTimeRange: (state) => state.analytics.frequency.selectedDateTimeRange,
      frequencyTimeTimeRange: (state) => state.analytics.frequency.selectedTimeTimeRange,
      exportFlag: (state) => state.analytics.exportFlag,
    }),
    snackBarMessage() {
      if (!this.exportFlag) return 'Downloading...'
      else return 'Downloaded.'
    },
    // TODO: API 콜 할때 미리 만들어놓기!!!
    frequencyDate() {
      if (this.currentFrequencyOption === null) return ''
      else {
        if (this.currentFrequencyOption.tabType === Constants.FREQUENCY_TAB_DATE) {
          return `${moment(this.frequencyDateTimeRange.start_time).format('YYYY-MM-DD')} ~ ${moment(this.frequencyDateTimeRange.end_time).format(
            'YYYY-MM-DD'
          )}`
        } else {
          if (this.currentFrequencyOption.rangeMode === Constants.RANGE_MODE_MONTH && this.frequencyTimeTimeRange.months) {
            // months
            return this.frequencyTimeTimeRange.months.map((month) => moment(month).format('YYYY-MM')).join(', ')
          } else if (this.currentFrequencyOption.rangeMode === Constants.RANGE_MODE_WEEK && !this.frequencyTimeTimeRange.months) {
            // week
            return `${moment(this.frequencyTimeTimeRange.start_time[0]).format('YYYY-MM-DD')} ~ ${moment(
              this.frequencyTimeTimeRange.end_time[0]
            ).format('YYYY-MM-DD')}`
          } else if (!this.currentFrequencyOption.comparisonMode && this.frequencyTimeTimeRange.start_time) {
            // not comparison mode, day
            return `${moment(this.frequencyTimeTimeRange.start_time[0]).format('YYYY-MM-DD')} ~ ${moment(
              this.frequencyTimeNormalRange.end_time[0]
            ).format('YYYY-MM-DD')}`
          } else if (
            this.currentFrequencyOption.comparisonMode &&
            this.currentFrequencyOption.rangeMode === Constants.RANGE_MODE_DAY &&
            this.frequencyTimeTimeRange.dates
          ) {
            // is comparison mode, day
            return this.frequencyTimeTimeRange.dates.map((i) => moment(i).format('YYYY-MM-DD')).join(', ')
          } else {
            return ''
          }
        }
      }
    },
  },
  methods: {
    async exportExcel() {
      let data = ''
      if (this.currentFrequencyOption) {
        const body = this.frequencyExportRequestBody(this.currentFrequencyOption)
        data = await analytics.exportFrequencyExcelFile(body)
      } else if (this.currentCameraOption) {
        const body = this.cameraExportRequestBody(this.currentCameraOption)
        data = await analytics.exportCameraExcelFile(body)
      }
      var link = document.createElement('a')
      link.target = '_blank'
      link.href = Util.makeUrl(process.env.VUE_APP_CDN_URL, data.data.xlsx_path)
      link.click()
    },

    pdfA4Maker(dom, date) {
      const a4Width = 210
      const a4height = 297

      const pageWidth = dom.scrollWidth
      const pageHeight = dom.scrollHeight

      const isDark = this.$vuetify.theme.dark

      const resetExportFlag = () => {
        this.$store.dispatch('analytics/setAnalyticsExportFlagAction')
        setTimeout(() => {
          this.showDownloadDefender = false
        }, 500)
      }

      html2canvas(dom, {
        width: pageWidth,
        height: pageHeight,
      }).then(function (canvas) {
        let imgHeight = (a4Width * pageHeight) / pageWidth

        const imgData = canvas.toDataURL('image/png')

        let positionY = 25

        const doc = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'a4',
        })

        if (isDark) {
          doc.setFillColor('#1b1c22')
          doc.rect(0, 0, a4Width, 25, 'F')
          doc.setTextColor('#FFFFFF')
          doc.setFontSize(20)
          doc.text(date, 105, 12, { align: 'center' })
        } else {
          doc.setFillColor('#FFFFFF')
          doc.rect(0, 0, a4Width, 25, 'F')
          doc.setTextColor('#363636')
          doc.setFontSize(20)
          doc.text(date, 105, 12, { align: 'center' })
        }

        doc.addImage(imgData, 'png', 0, positionY, a4Width, imgHeight - 25)

        let heightLeft = imgHeight - a4height

        while (heightLeft >= 0) {
          positionY = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'png', 0, positionY, a4Width, imgHeight)
          heightLeft -= a4height
        }

        if (heightLeft < 0) {
          doc.setFillColor('#1b1c22')
          doc.rect(0, a4height + heightLeft, a4Width, -heightLeft, 'F')
        }

        doc.save(`${date}.pdf`)

        resetExportFlag()
      })
    },

    pdfMaker(dom, date) {
      const pageWidth = dom.scrollWidth
      const pageHeight = dom.scrollHeight

      const resetTableRowNumber = () => {
        this.$store.dispatch('analytics/setAnalyticsExportFlagAction', true)
      }

      const isDark = this.$vuetify.theme.dark

      html2canvas(dom, {
        width: pageWidth,
        height: pageHeight,
      }).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png')

        let doc

        if (pageWidth > pageHeight) {
          doc = new jsPDF({
            orientation: 'l',
            unit: 'px',
            format: [pageWidth, pageHeight + 100],
          })

          doc.addImage(imgData, 'JPEG', 0, 100, pageWidth, pageHeight)
        } else {
          doc = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: [pageHeight + 100, pageWidth],
          })
          doc.addImage(imgData, 'JPEG', 0, 100, pageWidth, pageHeight)
        }

        if (isDark) {
          doc.setFillColor('#1b1c22')
          doc.rect(0, 0, pageWidth, 100, 'F')
          doc.setTextColor('#FFFFFF')
          doc.setFontSize(20)
          doc.text(date, pageWidth / 2, 40, { align: 'center' })
        } else {
          doc.setFillColor('#FFFFFF')
          doc.rect(0, 0, pageWidth, 100, 'F')
          doc.setTextColor('#363636')
          doc.setFontSize(20)
          doc.text(date, pageWidth / 2, 40, { align: 'center' })
        }

        // const widthPdf = doc.internal.pageSize.getWidth()
        // const heightPdf = doc.internal.pageSize.getHeight()

        doc.save(`${date}.pdf`)

        resetTableRowNumber()
      })
    },

    setExportFlagForExport() {
      this.$store.dispatch('analytics/setAnalyticsExportFlagAction')
    },

    exportPdf() {
      this.setExportFlagForExport()
      this.showDownloadDefender = true

      let dom
      let date

      if (!this.currentFrequencyOption && !this.currentCameraOption) {
        // OVERVIEW PAGE
        dom = document.getElementsByClassName('row-wrapper')[0]
        // dom = document.getElementById('main')[0]
        date = this.overviewDateTimeRange
      } else if (this.currentFrequencyOption && !this.currentCameraOption) {
        // FREQUENCY PAGE
        dom = document.getElementsByClassName('v-window-item v-window-item--active')[0]
        date = this.frequencyDate
      } else {
        // this.$store.dispatch('analytics/setAnalyticsExportFlagAction')
        // CAMERA PAGE
        dom = document.getElementById('main').childNodes[0].childNodes[0]
        date = this.cameraDateRangeText
      }

      this.$nextTick(function () {
        this.pdfA4Maker(dom, date)
      })
    },

    cameraExportRequestBody(option) {
      const body = { is_comparison: option.comparisonMode, time_zone: moment.tz.guess() }
      if (!option.comparisonMode) {
        body.div = 1
        body.start_time = [this.cameraDateTimeRange.start_time]
        body.end_time = [this.cameraDateTimeRange.end_time]
        return body
      }
      switch (option.rangeMode) {
        case Constants.RANGE_MODE_DAY:
          body.div = 1
          body.dates = this.cameraDateTimeRange.dates
          break
        case Constants.RANGE_MODE_WEEK:
          body.div = 2
          body.start_time = [this.cameraDateTimeRange.start_time[0]]
          body.end_time = [this.cameraDateTimeRange.end_time[0]]
          break
        case Constants.RANGE_MODE_MONTH:
          body.div = 3
          body.months = this.cameraDateTimeRange.months
          break
      }
      return body
    },

    frequencyExportRequestBody(option) {
      const body = { is_comparison: option.comparisonMode, time_zone: moment.tz.guess() }

      if (option.tabType === Constants.FREQUENCY_TAB_DATE) {
        body.main_div = 1
        body.start_time = [this.frequencyDateTimeRange.start_time]
        body.end_time = [this.frequencyDateTimeRange.end_time]

        switch (option.rangeMode) {
          case Constants.RANGE_MODE_DAY:
            body.sub_div = 1
            break
          case Constants.RANGE_MODE_WEEK:
            body.sub_div = 2
            break
          case Constants.RANGE_MODE_MONTH:
            body.sub_div = 3
            break
        }
        return body
      } else {
        body.main_div = 2
        if (!option.comparisonMode) {
          body.sub_div = 1
          body.start_time = this.frequencyTimeTimeRange.start_time
          body.end_time = this.frequencyTimeTimeRange.end_time
          return body
        }
        switch (option.rangeMode) {
          case Constants.RANGE_MODE_DAY:
            body.sub_div = 1
            body.dates = this.frequencyTimeTimeRange.dates
            break
          case Constants.RANGE_MODE_WEEK:
            body.sub_div = 2
            body.start_time = this.frequencyTimeTimeRange.start_time
            body.end_time = this.frequencyTimeTimeRange.end_time
            break
          case Constants.RANGE_MODE_MONTH:
            body.sub_div = 3
            body.months = this.frequencyTimeTimeRange.months
            break
        }
        return body
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.export-dropdown {
  position: absolute;
  right: 32px;
  top: 62px;
  width: 180px;
  border-radius: 8px;
  background-color: var(--f-text-white);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .export-dropdown-option {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--v-textDefault);
    padding: 4px 20px;
    cursor: pointer;
    &.dark {
      color: var(--v-textDefault);
    }
  }

  .export-dropdown-option:hover {
    background-color: var(--f-primary-50);
    color: white;
  }
}

.download {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
</style>
