<template>
  <div :class="`background-calendar ${themeName}`">
    <!-- <div style="margin-top: 24px; display: flex; width: 100%; padding-right: 20px"></div> -->

    <img v-if="$vuetify.theme.dark" class="close-btn" src="@/assets/Close_Line-16px-dark.svg" width="16px" height="16px" @click="closeCalendar" />
    <img v-else class="close-btn" src="@/assets/Close_Line-16px-light.svg" width="16px" height="16px" @click="closeCalendar" />

    <div class="header">
      <img v-if="$vuetify.theme.dark" @click="prevMonth" src="@/assets/Chevron-Left_24px_2_Icon.svg" />
      <img v-else @click="prevMonth" src="@/assets/Chevron-Left_24px_2_Icon-light.svg" />
      <div class="text-month">{{ monthNames[current.get('month')] }}</div>
      <img v-if="$vuetify.theme.dark" @click="nextMonth" src="@/assets/Chevron-Right_24px_2_Icon.svg" class="icon-arrow-right--month" />
      <img v-else @click="nextMonth" src="@/assets/Chevron-Right_24px_2_Icon-light.svg" class="icon-arrow-right--month" />
      <img v-if="$vuetify.theme.dark" @click="prevYear" src="@/assets/Chevron-Left_24px_2_Icon.svg" />
      <img v-else @click="prevYear" src="@/assets/Chevron-Left_24px_2_Icon-light.svg" />
      <div class="text-year">{{ year }}</div>
      <img v-if="$vuetify.theme.dark" @click="nextYear" src="@/assets/Chevron-Right_24px_2_Icon.svg" />
      <img v-else @click="nextYear" src="@/assets/Chevron-Right_24px_2_Icon-light.svg" />
    </div>
    <div>
      <!-- daysOfWeek -->
      <div class="row-week">
        <div v-for="(week, index) in daysOfWeek" :key="index" class="week text-box">
          {{ week }}
        </div>
      </div>
      <div v-for="(row, rowIndex) in dateProps" :key="rowIndex" class="row-date">
        <div
          v-for="(prop, colIndex) in row"
          :key="colIndex"
          @click="onClickDate(prop.date)"
          :class="{
            day: true,
            'text-box': true,
            min: isMinDate(prop.date),
            max: isMaxDate(prop.date),
            today: isToday(prop.date),
            dark: $vuetify.theme.dark,
            select: isSelected(prop.date),
          }"
          @mouseover="onMouseOver(prop.date)"
        >
          {{ prop.date.get('date') }}
        </div>
      </div>
      <!-- calendar rows -->
      <SmallButton @onClick="onClickSave" :bgColor="'#FF5B54'">Save</SmallButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import Theme from '@/mixin/theme.mixin'
import SmallButton from '@item/button/SmallButton'

export default {
  name: 'Calendar',
  mixins: [Theme],
  components: { SmallButton },
  props: {
    /**
     * emit events
     * update dateRange
     */
    daySelectMode: {
      type: Boolean,
      default: false,
    },
    weekSelectMode: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: moment,
      default: moment().startOf('day').subtract(180, 'days').subtract(1, 'days'),
    },
    maxDate: {
      type: moment,
      default: moment().startOf('day').add(1, 'days'),
    },
    isRange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      today: moment().startOf('day'),
      current: moment().startOf('day'),
      isSelection: false,
      temporarySelect: null,
      dateRange: { startDate: null, endDate: null },
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    }
  },
  computed: {
    ...mapState({
      frequencySelectedRangeMode: (state) => state.analytics.frequency.selectedRangeMode,
      frequencySelectedTab: (state) => state.analytics.frequency.selectedTab,
      cameraSelectedRangeMode: (state) => state.analytics.camera.selectedRangeMode,
    }),

    year() {
      return this.current.get('year')
    },
    dateProps() {
      let calendar = []
      let startDate = moment(this.current).startOf('month').startOf('week')
      let endDate = moment(this.current).endOf('month').endOf('week').add(1, 'days')
      let week = 0
      let daysOfWeek = []
      while (true) {
        daysOfWeek.push({ date: moment(startDate) })
        startDate.add(1, 'days')
        week++
        if (week === 7) {
          week = 0
          calendar.push(daysOfWeek)
          daysOfWeek = []
        }
        if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
          break
        }
      }
      // check showing dates
      week = calendar[0]
      let hide = true
      for (let i = 0; i < week.length; i++) {
        if (week[i].date.get('date') === 1) {
          hide = false
        }
        week[i].hide = hide
      }
      week = calendar[calendar.length - 1]
      hide = false
      for (let i = 0; i < week.length; i++) {
        if (week[i].date.get('date') === 1) {
          hide = true
        }
        week[i].hide = hide
      }
      return calendar
    },
  },
  methods: {
    closeCalendar() {
      this.$emit('closeCalendar')
    },
    nextMonth(continueTask = false) {
      if (this.maxDate.format('YYYY-MM') !== this.current.format('YYYY-MM')) {
        this.current = moment(this.current.add(1, 'months'))
      }
    },
    prevMonth(continueTask = false) {
      if (this.minDate.format('YYYY-MM') !== this.current.format('YYYY-MM')) {
        this.current = moment(this.current.subtract(1, 'months'))
      }
    },
    nextYear() {
      for (let i = 0; i < 12; i++) {
        this.nextMonth(true)
      }
    },
    prevYear() {
      for (let i = 0; i < 12; i++) {
        this.prevMonth(true)
      }
    },
    isSelected(date) {
      if (this.dateRange.startDate?.isSame(date)) return true
      if (this.dateRange.startDate && !this.isMinDate(date) && !this.isMaxDate(date)) {
        if (this.dateRange.endDate) {
          const isAfterStartDate = this.dateRange.startDate.isSameOrBefore(date)
          const isBeforeEndDate = this.dateRange.endDate.isSameOrAfter(date)
          if (isAfterStartDate && isBeforeEndDate) return true
        } else if (this.temporarySelect) {
          const isAfterStartDate = this.dateRange.startDate.isSameOrBefore(date)
          const isBeforeEndDate = this.temporarySelect.isSameOrAfter(date)
          if (isAfterStartDate && isBeforeEndDate) return true
        }
      }
      return false
    },
    onMouseOver(date) {
      if (this.dateRange.startDate) {
        this.temporarySelect = date
      }
    },
    defaultCalendarClick(date) {
      if (!this.isMinDate(date) && !this.isMaxDate(date)) {
        if (!this.dateRange.startDate) {
          this.dateRange.startDate = date
        } else if (!this.dateRange.endDate && this.dateRange.startDate.isSameOrBefore(date)) {
          this.dateRange.endDate = date
          this.isSelection = true
        } else if (this.dateRange.startDate.isAfter(date)) {
          this.isSelection = false
          this.dateRange.startDate = date
          this.dateRange.endDate = null
        } else if (this.dateRange.endDate && this.dateRange.endDate.isBefore(date) && this.dateRange.startDate.isBefore(date) && this.isSelection) {
          this.dateRange.startDate = date
          this.dateRange.endDate = null
          this.isSelection = false
        } else if (this.dateRange.startDate && this.dateRange.endDate) {
          this.dateRange.startDate = date
          this.dateRange.endDate = null
        }
      }
    },
    dayCalendarClick(date) {
      if (!this.isMinDate(date) && !this.isMaxDate(date)) {
        this.dateRange.startDate = date
        this.dateRange.endDate = date
        this.isSelection = true
      }
    },
    weeklyCalendarClick(date) {
      if (!this.isMinDate(date) && !this.isMaxDate(date)) {
        if (!this.dateRange.startDate) {
          this.dateRange.startDate = date
          return
        }
        const compareDay = this.dateRange.startDate.clone().add(6, 'days')

        if (!this.dateRange.endDate && compareDay.isSameOrBefore(date)) {
          this.dateRange.endDate = date
          this.isSelection = true
        } else if (this.dateRange.startDate.isAfter(date)) {
          this.isSelection = false
          this.dateRange.startDate = date
          this.dateRange.endDate = null
        } else if (this.dateRange.endDate && this.dateRange.endDate.isBefore(date) && this.dateRange.startDate.isBefore(date) && this.isSelection) {
          this.dateRange.startDate = date
          this.dateRange.endDate = null
          this.isSelection = false
        } else if (!this.dateRange.endDate && !compareDay.isSameOrBefore(date)) {
          this.dateRange.startDate = date
        } else if (this.dateRange.startDate && this.dateRange.endDate) {
          this.dateRange.startDate = date
          this.dateRange.endDate = null
        }
      }
    },
    onClickDate(date) {
      // range mode week or default or day
      if (this.weekSelectMode) {
        this.weeklyCalendarClick(date)
      } else if (this.daySelectMode) {
        this.dayCalendarClick(date)
      } else {
        this.defaultCalendarClick(date)
      }
    },

    isToday(date) {
      return this.today.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
    },
    isMinDate(date) {
      return this.minDate.isSameOrAfter(date)
    },
    isMaxDate(date) {
      return this.maxDate.isSameOrBefore(date)
    },
    onClickSave() {
      if (this.isSelection) {
        const range = { ...this.dateRange }
        this.$emit('updateCustomDate', range)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-calendar {
  position: absolute;
  // transform: translate(105%, 75%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  width: 360px;
  min-height: 350px;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-high);
  }
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 25px;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 5px;

  .text-year,
  .text-month {
    margin: 0px 8px;
  }

  .icon-arrow-right--month {
    margin-right: 24px;
  }

  img {
    cursor: pointer;
    width: 24px;
  }
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin: 5px;
}

.row-week {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .week {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}

.row-date {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .day {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 34px;
    border: 1.5px solid var(--f-text-black-middle);
    color: var(--f-text-black-middle);
    &.dark {
      color: var(--f-text-white-high);
      border: 1.5px solid var(--f-text-white-high);
    }

    &.today {
      color: var(--f-text-white);
      background-color: var(--f-text-black-middle);
      border-width: 0;
      &.dark {
        color: var(--f-bg-high);
        background-color: var(--f-text-white-high);
      }

      &.focus {
        color: var(--f-text-white-high);
        background-color: var(--f-primary-70);
      }
    }
    &.select {
      color: var(--f-text-white);
      background-color: var(--f-primary-70);
      border-width: 0;
    }
    &.focus {
      color: var(--f-text-white);
      background-color: var(--f-primary-70);
      border-width: 0;
    }

    &.min,
    &.max {
      color: var(--f-text-black-low);
      border-color: var(--f-text-black-low);
      &.dark {
        color: var(--f-text-white-low);
        border-color: var(--f-text-white-low);
      }

      &.hide {
        visibility: hidden;
      }
    }
  }
}

button {
  margin: 30px auto;
}

.close-btn {
  margin: 24px 20px 0 auto;
  cursor: pointer;
}
</style>
