/**
 * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop
 */
import axios from 'axios'
import { interceptorRequest, interceptorResponse } from './auth'

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL })
api.interceptors.request.use(interceptorRequest)
api.interceptors.response.use(null, interceptorResponse)

export async function getAlertsBox() {
  return api.get('log/monitor')
}

/**
 * @param {number} logid
 */
export async function extendExpireOfLog(logid) {
  return api.put(`log/extend/${logid}`)
}

/**
 * is_alert 0: sikp, 1: confirm, 2: skip(hardcase)
 * @param {{log_id:number, is_alert:number, boxes: [{x:number, y:number, width:number, height:number}]}} payload
 */
export async function updateAlertBox(payload) {
  return await api.post('log', payload)
}

export async function getBreaktime() {
  return api.get('breaktime')
}

export async function getWorkerList() {
  return api.get('worker')
}

export async function getServerStatus() {
  return api.get('health-check')
}

export async function getRecentLogsAboutCamera(id) {
  return api.get(`/log/recent/${id}`)
}
