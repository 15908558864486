<template>
  <section>
    <MapToolbar v-if="$route.path === '/mapview'" />
    <!-- <AnalyticsToolbar v-else-if="isAnalytics" :title="analyticsTitle" /> -->
    <Toolbar v-else ref="defaultToolbar" />
  </section>
</template>

<script>
import Toolbar from '@common/Toolbar'
import MapToolbar from '@desktop/adminOrViewer/mapView/MapViewNavigation'
import Constants from '@/constants'
// Analytics 기능 추가 예정
import AnalyticsToolbar from '@common/AnalyticsToolbar/AnalyticsToolbar'

export default {
  components: {
    Toolbar,
    MapToolbar,
    AnalyticsToolbar,
  },
  data() {
    return {
      currentPath: '',
    }
  },
  watch: {
    $route(nextRoute, prevRoute) {
      const nextPath = nextRoute.path.split('/')[1]
      const prevPath = prevRoute.path.split('/')[1]
      if (nextPath !== 'detection' && prevPath === 'detection') this.$refs.defaultToolbar.clearInput()
    },
  },
  computed: {
    isDefaultToolber() {
      const path = this.$route.path.split('/')[1]
      return Constants.DEFAULT_TOOLBAR_URLS.includes(path)
    },
    // isAnalytics() {
    //   return Constants.ANALYTICS_URL.includes(this.$route.path.split('/')[1])
    // },
    // analyticsTitle() {
    //   return Constants.ANALYTICS_TITLE[this.$route.path.split('/')[1]]
    // },
  },
}
</script>

<style lang="scss" scoped></style>
