<template>
  <header class="toolbar-bg">
    <!-- <div @click="onClickHome" class="d-flex">
      <img v-if="$vuetify.theme.dark" src="@/assets/group 1600-dark.svg" class="icon-logo" />
      <img v-else src="@/assets/group 1600-light.svg" class="icon-logo" />
      <div class="d-flex flex-column">
        <div class="text-title">Wildfire Alert System</div>
        <div class="text-subtitle">{{ organization }}</div>
      </div>
    </div> -->
    <div @click="onClickHome" class="d-flex icon-main-logo-container">
      <img v-if="$vuetify.theme.dark" src="@/assets/FireScoutLogo_white.svg" class="icon-logo" />
      <img v-else src="@/assets/FireScoutLogo_color.svg" class="icon-logo" />
      <div :class="`text-subtitle ${themeName}`">{{ organization }}</div>
    </div>
    <v-spacer></v-spacer>
    <div v-if="isSearch && componentShow" class="search-container">
      <DropdownSearch :items="camList" item-text="name" item-value="id" @input="onSelectSite"></DropdownSearch>
    </div>
    <img
      v-if="$vuetify.theme.dark && componentShow"
      @click="isSearch = !isSearch"
      src="@/assets/Icon ionic-ios-search-white.svg"
      class="img-search"
    />
    <img v-else-if="componentShow" @click="isSearch = !isSearch" src="@/assets/Icon ionic-ios-search.svg" class="img-search" />
    <div v-if="componentShow" class="text-logout" @click="$store.dispatch('logout')">Logout</div>
  </header>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import { mapState } from 'vuex'
import DropdownSearch from '@mobile/DropdownSearch'

export default {
  mixins: [Theme],
  components: { DropdownSearch },
  props: {
    tab: String,
    /**
     * emit events
     * navi
     */
  },
  data() {
    return {
      isSearch: false,
      isSearchFocus: false,
    }
  },
  computed: {
    ...mapState(['camList', 'user']),
    organization() {
      return this.user.organization_name
    },
    componentShow() {
      return this.tab !== 'terms' && this.tab !== 'policy'
    },
  },
  methods: {
    onSelectSite(camid) {
      this.$emit('select', camid)
    },
    onClickHome() {
      this.isSearch = false
      this.$emit('home')
    },
  },
}
</script>

<style scoped>
.toolbar-bg {
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: var(--v-background-base);
  box-shadow: 0px 5px 20px #0000001a;
  z-index: 2;
  user-select: none;
}

.icon-main-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toolbar-bg .icon-logo {
  width: 80px;
  margin-left: 16px;
  margin-right: 7px;
}

.toolbar-bg .text-title {
  color: var(--v-textDefault);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.toolbar-bg .text-subtitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #f9423a;
}

.toolbar-bg .img-search {
  width: 16px;
  cursor: pointer;
  margin-right: 16px;
}

.toolbar-bg .text-logout {
  color: var(--v-textDefault);
  font-size: 12px;
  margin-right: 16px;
  cursor: pointer;
}

.toolbar-bg .text-logout:active {
  opacity: 0.5;
}

.search-container {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 50px;
  background-color: var(--v-background-base);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
</style>
