<template>
  <v-menu v-model="open" @input="onChange" max-width="230px" nudge-right="-235px" nudge-top="15px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:activator="{ on, attrs }"></slot>
    </template>
    <div :class="`popup-disturb ${themeName}`">
      <div
        v-if="show3Hours"
        :class="{ 'popup-disturb-content': true, focus: menuId === 0, dark: $vuetify.theme.dark }"
        @mouseover="onMouseOver(0)"
        @click="onClick(0)"
      >
        For 3 hours
      </div>
      <div
        v-if="show6Hours"
        :class="{ 'popup-disturb-content': true, focus: menuId === 1, dark: $vuetify.theme.dark }"
        @mouseover="onMouseOver(1)"
        @click="onClick(1)"
      >
        For 6 hours
      </div>
      <div
        v-if="show24Hours"
        :class="{ 'popup-disturb-content': true, focus: menuId === 2, dark: $vuetify.theme.dark }"
        @mouseover="onMouseOver(2)"
        @click="onClick(2)"
      >
        For Today
      </div>
      <div :class="{ 'popup-disturb-content': true, focus: menuId === 3, dark: $vuetify.theme.dark }" @mouseover="onMouseOver(3)" @click="onClick(3)">
        Custom...
      </div>
    </div>
    <PauseAlertDialog v-model="dialog" :item="item"></PauseAlertDialog>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PauseAlertDialog from '@desktop/dialog/PauseAlertDialogAdmin'
import moment from 'moment'
import { alert } from '@/api'
import Util from '@/util'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { PauseAlertDialog },
  /**
   * emit events
   * click
   */
  props: {
    item: Object,
  },
  data() {
    return {
      open: false,
      menuId: -1,
      dialog: false,
    }
  },
  computed: {
    ...mapState(['recentLogListv2']),
    show3Hours() {
      return moment().add(3, 'h').isBefore(this.after72hours)
    },
    show6Hours() {
      return moment().add(6, 'h').isBefore(this.after72hours)
    },
    show24Hours() {
      return moment().add(24, 'h').isBefore(this.after72hours)
    },
    after72hours() {
      return moment(this.lastestLog.created_at).add(72, 'h').utc().format()
    },
    lastestLog() {
      return this.recentLogListv2.find((log) => log.camera_id === this.item.id)
    },
  },
  methods: {
    ...mapActions(['getCamList']),
    close() {
      this.open = false
      this.menuId = -1
    },
    onChange(open) {
      if (!open) {
        this.close()
      }
    },
    onMouseOver(index) {
      this.menuId = index
    },
    async onClick(index) {
      this.close()
      this.$emit('click')
      if (index === 0 || index === 1 || index === 2) {
        let end_time
        if (index === 0) {
          end_time = moment().add(3, 'h')
        } else if (index === 1) {
          end_time = moment().add(6, 'h')
        } else if (index === 2) {
          end_time = moment().endOf('day')
        }
        try {
          await alert.updatePauseAlert({
            camera_id: this.item.id,
            start_time: this.lastestLog.created_at,
            end_time,
          })
          await this.getCamList({ justCamList: true })
        } catch (e) {
          this.$log.error('updatePauseAlert error')
        }
      } else if (index === 3) {
        this.dialog = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-disturb {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }
}

.popup-disturb-content {
  display: flex;
  align-items: center;
  height: 29px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  &.focus {
    color: var(--f-text-white);
    background-color: var(--f-primary-50);
  }
}
</style>
