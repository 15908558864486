<template>
  <header :class="`toolbar ${themeName}`">
    <section :class="themeName">
      <div v-if="!isSupportPage" style="margin-left: 29px; min-width: 340px; margin-right: 15px">
        <DropdownSearch ref="input" :items="camList" item-text="name" item-value="id" @input="onSelectSite"></DropdownSearch>
      </div>
      <ButtonAlertOff v-if="!isSupportPage" :disabled="!isAdmin || isRoleViewer"></ButtonAlertOff>
      <v-spacer></v-spacer>
      <img v-if="$vuetify.theme.dark" src="@/assets/Camera_Filled_24px_Icon.svg" width="24px" style="margin: 5px" />
      <img v-else src="@/assets/Camera_Filled_24px_Icon_red.svg" width="24px" style="margin: 5px" />
      <div style="margin-right: 5px; white-space: nowrap">Monitored Cameras</div>
      <div style="color: #ff6e68; margin-right: 20px; font-weight: 600">{{ cameraSize }}</div>
      <div style="margin-right: 5px; white-space: nowrap">Hello,&nbsp;</div>
      <div style="color: #ff6e68; margin-right: 20px; white-space: nowrap">{{ user.user_name }}!</div>
      <Button @click="onClickMonitor" :disabled="!isAdmin" style="min-width: 108px; height: 44px; margin-right: 20px">Monitor</Button>
      <div @click="onClickLogout" :class="`logout ${themeName}`">Logout</div>
    </section>
    <div :class="`bottom-line ${themeName}`"></div>
  </header>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ButtonAlertOff from '@desktop/adminOrViewer/detection/ButtonAlertOff'
import DropdownSearch from '@common/DropdownSearchAdmin'
import Button from '@common/Button'
import Theme from '@/mixin/theme.mixin'
import NativeService from '@/native-service'
import Constants from '@/constants'
import Util from '@/util'

export default {
  mixins: [Theme],
  components: { ButtonAlertOff, DropdownSearch, Button },
  async created() {
    await this.getCamList({ justCamList: true })
    await this.getRecentLogListv2({ inNoti: true, inRepOrg: true, inRespStat: false, inChild: true })
  },
  computed: {
    ...mapState(['camList', 'user', 'recentLogList']),
    ...mapGetters(['setCurrentCamByName']),
    isAdmin() {
      return this.user.isAdmin
    },
    isRoleViewer() {
      return this.user.user_role === Constants.ROLE_VIEWER
    },
    cameraSize() {
      return this.camList.length
    },
    isSupportPage() {
      return this.$route.path.includes('support-submit')
    },
  },
  methods: {
    ...mapActions(['getRecentLogListv2', 'getCamList', 'getLog']),
    async onClickLogout() {
      await this.$store.dispatch('logout')
      this.$vuetify.theme.dark = false
      NativeService.setData(Constants.THEME_DARK, false)
    },
    onSelectSite(camIdOrLog) {
      if (typeof camIdOrLog === 'object' && camIdOrLog) {
        this.searchLogId(camIdOrLog.log_id)
      } else if (camIdOrLog) {
        const cam = this.camList.find((cam) => cam.id === camIdOrLog)
        this.searchCameraName(cam.name)
      }
    },
    searchCameraName(name) {
      this.moveToDetectionPage('name', name)
      if (this.$route.path.includes('detection')) this.$store.commit('setCurrentCamByName', name)
    },
    async searchLogId(id) {
      const searchedLog = await this.getLog(id)
      this.$store.commit('currentLogMutation', searchedLog)
      this.moveToDetectionPage('id', id)
    },
    moveToDetectionPage(key, value) {
      if (!this.$route.path.includes('detection')) this.$router.push({ path: `/detection?${key}=${value}` })
      else {
        const pageDom = document.getElementsByClassName('detection')[0]
        Util.moveScrollToTop(true, pageDom)
        this.$router.push({ path: `/detection?${key}=${value}` })
      }
    },
    onClickMonitor() {
      this.$router.push(`/monitor`)
    },
    clearInput() {
      this.$refs.input.clearInput()
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar {
  position: sticky;
  top: 0;
  z-index: 6;
  background-color: var(--f-text-white);
  &.dark {
    background-color: var(--f-bg-low);
  }

  section {
    display: flex;
    align-items: center;
    height: 74px;
    font-weight: 500;
    font-size: 14px;
    color: var(--f-text-black);
    &.dark {
      color: var(--f-text-white-high);
    }
  }

  .bottom-line {
    height: 1px;
    background-color: var(--f-light-gray-1);
    &.dark {
      background-color: var(--f-bg-high);
    }
  }
  .logout {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    margin-right: 30px;
    color: var(--f-primary-90);
    &.dark {
      color: var(--f-text-white-high);
    }
  }
}
</style>
