<template>
  <button @click="clickButton" class="normal" v-ripple :class="{ dark: $vuetify.theme.dark, disable }" :style="compoutedStyle">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#f9423a',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 108,
    },
    height: {
      type: Number,
      default: 44,
    },
  },
  computed: {
    compoutedStyle() {
      return `background-color: ${this.bgColor}; width: ${this.width}px; height: ${this.height}px`
    },
  },
  methods: {
    clickButton() {
      this.$emit('onClick')
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--f-text-white);
  border-radius: 8px;

  &.dark {
    color: #ffffffde;
  }

  &.disable {
    color: #00000042;
    background-color: var(--f-text-black-low) !important;
    &.dark {
      background-color: #d8d9da !important;
    }
  }
}
</style>
