import { analytics } from '@/api'
import Constants from '@/constants'
import moment from 'moment'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    exportFlag: true,
    overview: {
      // selectedDateTimeRange: {
      // componentType: 'Day'/'Month',
      // rangeType: 'Today'/'Yesterday'/'Last 7 Days'/'Last 30 Days'/'Custom'
      //   startTime: '',
      //   endTime: '',
      // },
      selectedDateRangeText: '',
      fireEvents: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            // no change color: '#61A0FF'
            // fire event decrease color: '#64D280'
            clip: 5,
            data: [],
            percentageData: 0,
            pointRadius: 0,
            borderWidth: 2,
          },
        ],
      },
      fireActivity: {
        week: [
          {
            dayText: 'SUN',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'MON',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'TUE',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'WED',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'THU',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'FRI',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            dayText: 'SAT',
            timeData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        legendDistribution: [
          {
            min: 1,
            max: 20,
            color: '#FFF0EF',
          },
          {
            min: 21,
            max: 40,
            color: '#FFCBC9',
          },
          {
            min: 41,
            max: 60,
            color: '#FF9590',
          },
          {
            min: 61,
            max: 80,
            color: '#FF6862',
          },
          {
            min: 81,
            max: 100,
            color: '#F9423A',
          },
        ],
      },
      mostActiveArea: {
        rankList: [
          // {
          //   rank: 1,
          //   camera_id: '',
          //   count: 0,
          // },
        ],
        chartData: {
          labels: [],
          datasets: [
            {
              axis: 'y',
              data: [],
              maxBarThickness: 11,
              backgroundColor: 'rgba(255, 99, 132)',
              borderRadius: 50,
              borderSkipped: false,
            },
          ],
        },
      },
    },
    frequency: {
      // Date tab selected range
      selectedDateTimeRange: {
        start_time: [moment().subtract(6, 'days').format()],
        end_time: [moment().format()],
      },
      // Time tab selected range
      selectedTimeTimeRange: {
        start_time: [moment().subtract(6, 'days').format()],
        end_time: [moment().format()],
      },
      selectedTab: { tab: Constants.FREQUENCY_TAB_DATE },
      selectedRangeMode: {
        dateTabRangeMode: Constants.RANGE_MODE_DAY,
        timeTabRangeMode: Constants.RANGE_MODE_DAY,
      },
      comparisonModeToggle: false,
      dateTab: {
        dayDateRange: {
          start_time: [],
          end_time: [],
        },
        weekDateRange: {
          start_time: [],
          end_time: [],
        },
        monthDateRange: {
          months: [],
        },
        chartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#ff5b54',
              borderColor: '#ff5b54',
              clip: 5,
              data: [],
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        },
        dayTable: {
          data: [
            // {
            //   date: '2022-05-16',
            //   total: 32,
            // },
          ],
        },
        weekTable: {
          data: [
            // {
            //   date: '2022-04-01 ~ 2022-04-07',
            //   total: 17,
            // },
          ],
        },
        monthTable: {
          data: [
            // {
            //   date: '2022-04',
            //   total: 34,
            // },
          ],
        },
      },
      timeTab: {
        normalDateRange: {
          start_time: [],
          end_time: [],
        },
        dayDateRange: {
          dates: [],
        },
        weekDateRange: {
          start_time: [],
          end_time: [],
        },
        monthDateRange: {
          months: [],
        },
        chartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#ff5b54',
              borderColor: '#ff5b54',
              clip: 5,
              data: [],
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        },
        normalTable: [],
        comparisonChartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: '#ff5b54',
              borderColor: '#ff5b54',
              clip: 5,
              data: [],
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        },

        dayTable: {
          headers: [
            // '2022-06-22',
            // '2022-06-23',
            // '2022-06-24',
            // '2022-06-25',
            // '2022-06-26',
          ],
          totals: [
            // 543,
            // 551,
            // 551,
            // 522,
            // 532,
          ],
          regularData: [
            // {
            //   time: '00:00 - 01:00',
            //   total: 3
            // }
          ],
          comparisonData: [
            // {
            //   time: '00:00 - 01:00',
            //   column1Total: 34,
            //   column2Total: 10,
            //   column3Total: 13,
            //   column4Total: 14,
            //   column5Total: 16,
            // },
          ],
        },
        weekTable: {
          headers: [
            // '2022-06-25 ~ 2022-05-31',
            // '2022-06-01 ~ 2022-06-07',
            // '2022-06-08 ~ 2022-06-14',
            // '2022-06-15 ~ 2022-06-21',
            // '2022-06-22 ~ 2022-06-28',
          ],
          totals: [
            // 543,
            // 551,
            // 551,
            // 522,
            // 532,
          ],
          regularData: [
            // {
            //   time: '00:00 - 01:00',
            //   total: 3
            // }
          ],
          comparisonData: [
            // {
            //   time: '00:00 - 01:00',
            //   column1Total: 34,
            //   column2Total: 10,
            //   column3Total: 13,
            //   column4Total: 14,
            //   column5Total: 16,
            // },
          ],
        },
        monthTable: {
          headers: [
            // '2022-02',
            // '2022-03',
            // '2022-04',
            // '2022-05',
            // '2022-06',
          ],
          totals: [
            // 543,
            // 551,
            // 551,
            // 522,
            // 532,
          ],
          regularData: [
            // {
            //   time: '00:00 - 01:00',
            //   total: 3
            // }
          ],
          comparisonData: [
            // {
            //   time: '00:00 - 01:00',
            //   column1Total: 34,
            //   column2Total: 10,
            //   column3Total: 13,
            //   column4Total: 14,
            //   column5Total: 16,
            // },
          ],
        },
      },
    },
    camera: {
      selectedDateTimeRange: { start_time: [moment().subtract(7, 'days').format()], end_time: [moment().format()] },
      selectedDateRangeText: '',
      currentData: {},
      selectedRangeMode: Constants.RANGE_MODE_DAY,
      comparisonModeToggle: false,
      unselectedCameraList: [],
      pageCount: 10,
    },
  },
  mutations: {
    // OVERVIEW
    setOverviewFireEventsMutation(state, periods) {
      if (periods.length) {
        const labels = [
          moment(periods[0].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' ~ ' +
            moment(periods[0].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
          moment(periods[1].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' ~ ' +
            moment(periods[1].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
        ]
        const prevRangeTotal = periods[0].total
        const currRangeTotal = periods[1].total

        let backgroundColor = '#61A0FF'
        let borderColor = '#61A0FF'
        let percentageColor = 'neutral-color'

        if (prevRangeTotal !== currRangeTotal) {
          backgroundColor = prevRangeTotal < currRangeTotal ? '#ff5b54' : '#64D280'
          borderColor = prevRangeTotal < currRangeTotal ? '#ff5b54' : '#64D280'
          percentageColor = prevRangeTotal < currRangeTotal ? 'increase-color' : 'decrease-color'
        }

        const percentageData = !prevRangeTotal ? 0 : ((Math.abs(prevRangeTotal - currRangeTotal) / prevRangeTotal) * 100).toFixed(0)

        state.overview.fireEvents = {
          labels,
          datasets: [
            {
              backgroundColor,
              borderColor,
              // no change color: '#61A0FF'
              // fire event decrease color: '#64D280'
              clip: 5,
              data: [prevRangeTotal, currRangeTotal],
              percentageData,
              percentageColor,
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        }
      }
    },
    setOverviewFireActivityGraphMutation(state, weekData) {
      let maxCount = 0
      for (let i = 0; i < weekData.length; i += 1) {
        state.overview.fireActivity.week[weekData[i].weekday].timeData = []
        const data = Object.values(weekData[i].data)

        for (let j = 0; j < data.length; j += 2) {
          // define week
          state.overview.fireActivity.week[weekData[i].weekday].timeData.push(data[j] + data[j + 1])

          // add to max count
          if (maxCount < data[j] + data[j + 1]) {
            maxCount = data[j] + data[j + 1]
          }
        }
      }
      const legendMax = Math.ceil(maxCount / 10) * 10
      const legendMaxUnit = legendMax / 5
      state.overview.fireActivity.legendDistribution = [
        {
          min: 1,
          max: legendMaxUnit ? legendMaxUnit : 20,
          color: '#FFF0EF',
        },
        {
          min: legendMaxUnit + 1,
          max: legendMaxUnit ? legendMaxUnit * 2 : 40,
          color: '#FFCBC9',
        },
        {
          min: legendMaxUnit * 2 + 1,
          max: legendMaxUnit ? legendMaxUnit * 3 : 60,
          color: '#FF9590',
        },
        {
          min: legendMaxUnit * 3 + 1,
          max: legendMaxUnit ? legendMaxUnit * 4 : 80,
          color: '#FF6862',
        },
        {
          min: legendMaxUnit * 4 + 1,
          max: legendMaxUnit ? legendMax : 100,
          color: '#F9423A',
        },
      ]
    },
    setOverviewMostActiveAreaMutation(state, rankList) {
      state.overview.mostActiveArea.rankList = rankList
      state.overview.mostActiveArea.chartData = {
        labels: [],
        datasets: [
          {
            axis: 'y',
            data: [],
            maxBarThickness: 11,
            backgroundColor: 'rgba(255, 99, 132)',
            borderRadius: 50,
            borderSkipped: false,
          },
        ],
      }
      for (let i = 0; i < rankList.length; i += 1) {
        state.overview.mostActiveArea.chartData.labels.push(rankList[i].camera_id)
        state.overview.mostActiveArea.chartData.datasets[0].data.push(rankList[i].count)
      }
    },
    setOverviewSelectedDateRangeTextMutation(state, payload) {
      state.overview.selectedDateRangeText = payload
    },
    // FREQUENCY
    setFrequencyDateTabRangeModeMutation(state, rangeMode) {
      state.frequency.selectedRangeMode.dateTabRangeMode = rangeMode.toUpperCase()
    },
    setFrequencyTimeTabRangeModeMutation(state, rangeMode) {
      state.frequency.selectedRangeMode.timeTabRangeMode = rangeMode.toUpperCase()
    },
    // set API data into store
    setFrequencyDateDayMutation(state, periods) {
      if (periods.length) {
        const periodData = periods[0].data
        let labels = []
        let data = []
        let tableData = []

        const startDate = moment(periods[0].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('MM-DD')
        const daysDifference = moment(periods[0].end_time).diff(periods[0].start_time, 'days')

        for (let i = 0; i < daysDifference + 1; i += 1) {
          const currentIterationDay = moment(startDate, 'MM-DD').add(i, 'days').format('MM-DD')
          const matchedDayDataItemIndex = periodData.map((item) => moment(item.date, 'YYYY-MM-DD').format('MM-DD')).indexOf(currentIterationDay)
          const matchedDayDataItem = periodData[matchedDayDataItemIndex]

          labels.push(currentIterationDay)
          data.push(matchedDayDataItemIndex !== -1 ? matchedDayDataItem.count : 0)
          tableData.push({
            date: currentIterationDay,
            total: matchedDayDataItemIndex !== -1 ? matchedDayDataItem.count : 0,
          })
        }

        state.frequency.dateTab.chartData = {
          labels,
          datasets: [
            {
              backgroundColor: '#ff5b54',
              borderColor: '#ff5b54',
              clip: 5,
              data,
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        }
        state.frequency.dateTab.dayTable.data = tableData
      } else {
        state.frequency.dateTab.chartData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#ff5b54',
              borderColor: '#ff5b54',
              clip: 5,
              data: [],
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        }
        state.frequency.dateTab.dayTable.data = []
      }
    },
    setFrequencyDateWeekMutation(state, dates) {
      let labels = []
      let data = []
      let tableData = []

      for (let i = 0; i < dates.length; i += 1) {
        const weekStartDate = moment(dates[i].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
        const weekEndDate = moment(dates[i].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')

        labels.push(weekStartDate + ' ~ ' + weekEndDate)
        data.push(dates[i]?.total ? dates[i].total : 0)
        tableData.push({
          date: weekStartDate + ' ~ ' + weekEndDate,
          total: dates[i]?.total ? dates[i].total : 0,
        })
      }

      state.frequency.dateTab.chartData = {
        labels,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data,
            pointRadius: 0,
            borderWidth: 2,
          },
        ],
      }

      state.frequency.dateTab.weekTable.data = dates.length ? tableData : []
    },
    setFrequencyDateMonthMutation(state, months) {
      let labels = []
      let data = []
      let tableData = []
      for (let i = 0; i < months.length; i += 1) {
        labels.push(moment(months[i].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM'))
        data.push(months[i]?.total ? months[i].total : 0)
        tableData.push({
          date: moment(months[i].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM'),
          total: months[i].total,
        })
      }

      state.frequency.dateTab.chartData = {
        labels,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data,
            pointRadius: 0,
            borderWidth: 2,
          },
        ],
      }

      state.frequency.dateTab.monthTable.data = months.length ? tableData : []
    },

    setFrequencyTimeNormalMutation(state, periods) {
      const graphData = []
      const tableData = []
      const dataLabel = []
      const existData = new Map()
      for (let i = 0; i < periods.length; i++) {
        const key = moment(periods[i].time, 'HH:mm:ssZ').format('HH:mm')
        existData.set(key, periods[i].count)
      }

      for (let i = 0; i < 24; i++) {
        const currentIterationTime = moment(i, 'HH').format('HH:mm')
        const nextIterationTime = moment(i + 1, 'HH').format('HH:mm')
        const currentLabel = currentIterationTime + ' - ' + nextIterationTime
        dataLabel.push(currentLabel)

        if (existData.has(currentIterationTime)) {
          const data = existData.get(currentIterationTime)
          tableData.push({
            time: currentLabel,
            total: data,
          })
          graphData.push(data)
        } else {
          tableData.push({
            time: currentLabel,
            total: 0,
          })
          graphData.push(0)
        }
      }

      state.frequency.timeTab.normalTable = tableData

      state.frequency.timeTab.chartData = {
        labels: dataLabel,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data: graphData,
            pointRadius: 0,
            borderWidth: 2,
          },
        ],
      }
    },

    setFrequencyTimeDayMutation(state, periods) {
      const column1Data = periods.length ? periods[0].data : []
      const column2Data = periods.length > 1 ? periods[1].data : []
      const column3Data = periods.length > 2 ? periods[2].data : []
      const column4Data = periods.length > 3 ? periods[3].data : []
      const column5Data = periods.length > 4 ? periods[4].data : []
      let labels = [
        '00:00 - 01:00',
        '01:00 - 02:00',
        '02:00 - 03:00',
        '03:00 - 04:00',
        '04:00 - 05:00',
        '05:00 - 06:00',
        '06:00 - 07:00',
        '07:00 - 08:00',
        '08:00 - 09:00',
        '09:00 - 10:00',
        '10:00 - 11:00',
        '11:00 - 12:00',
        '12:00 - 13:00',
        '13:00 - 14:00',
        '14:00 - 15:00',
        '15:00 - 16:00',
        '16:00 - 17:00',
        '17:00 - 18:00',
        '18:00 - 19:00',
        '19:00 - 20:00',
        '20:00 - 21:00',
        '21:00 - 22:00',
        '22:00 - 23:00',
        '23:00 - 24:00',
      ]
      let COLUMN_1_DATA = []
      let COLUMN_2_DATA = []
      let COLUMN_3_DATA = []
      let COLUMN_4_DATA = []
      let COLUMN_5_DATA = []
      let ALL_COLUMNS_DATA = []

      let regularTableData = []
      let comparisonTableData = []

      for (let i = 0; i < labels.length; i += 1) {
        const currentIterationTime = moment(i, 'HH').format('HH:mm')
        const nextIterationTime = moment(i + 1, 'HH').format('HH:mm')

        const matchedTimeDataItemIndexColumn1 = column1Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn1 = column1Data[matchedTimeDataItemIndexColumn1]
        COLUMN_1_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)
        ALL_COLUMNS_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)

        const matchedTimeDataItemIndexColumn2 = column2Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn2 = column2Data[matchedTimeDataItemIndexColumn2]
        COLUMN_2_DATA.push(matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0

        const matchedTimeDataItemIndexColumn3 = column3Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn3 = column3Data[matchedTimeDataItemIndexColumn3]
        COLUMN_3_DATA.push(matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0

        const matchedTimeDataItemIndexColumn4 = column4Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn4 = column4Data[matchedTimeDataItemIndexColumn4]
        COLUMN_4_DATA.push(matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0

        const matchedTimeDataItemIndexColumn5 = column5Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn5 = column5Data[matchedTimeDataItemIndexColumn5]
        COLUMN_5_DATA.push(matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0

        regularTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          total:
            (matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0) +
            (matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0) +
            (matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0) +
            (matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0) +
            (matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0),
        })

        comparisonTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          column1Total: matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0,
          column2Total: matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0,
          column3Total: matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0,
          column4Total: matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0,
          column5Total: matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0,
        })
      }

      state.frequency.timeTab.comparisonChartData = {
        labels,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data: COLUMN_1_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: moment(periods[0].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
          },
          {
            backgroundColor: '#61A0FF',
            borderColor: '#61A0FF',
            clip: 5,
            data: COLUMN_2_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: periods.length > 1 ? moment(periods[1].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
          },
          {
            backgroundColor: '#64D280',
            borderColor: '#64D280',
            clip: 5,
            data: COLUMN_3_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: periods.length > 2 ? moment(periods[2].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
          },
          {
            backgroundColor: '#FFA959',
            borderColor: '#FFA959',
            clip: 5,
            data: COLUMN_4_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: periods.length > 3 ? moment(periods[3].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
          },
          {
            backgroundColor: '#7B61FF',
            borderColor: '#7B61FF',
            clip: 5,
            data: COLUMN_5_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: periods.length > 4 ? moment(periods[4].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
          },
        ].slice(0, periods.length),
      }
      state.frequency.timeTab.dayTable.totals = [
        periods[0].total,
        periods.length > 1 ? periods[1].total : 0,
        periods.length > 2 ? periods[2].total : 0,
        periods.length > 3 ? periods[3].total : 0,
        periods.length > 4 ? periods[4].total : 0,
      ].slice(0, periods.length)
      state.frequency.timeTab.dayTable.regularData = regularTableData
      state.frequency.timeTab.dayTable.comparisonData = comparisonTableData

      state.frequency.timeTab.dayTable.headers = [
        moment(periods[0].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
        periods.length > 1 ? moment(periods[1].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
        periods.length > 2 ? moment(periods[2].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
        periods.length > 3 ? moment(periods[3].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
        periods.length > 4 ? moment(periods[4].date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') : '',
      ].slice(0, periods.length)
    },
    setFrequencyTimeWeekMutation(state, dates) {
      const column1Data = dates.length ? dates[0].data : []
      const column2Data = dates.length > 1 ? dates[1].data : []
      const column3Data = dates.length > 2 ? dates[2].data : []
      const column4Data = dates.length > 3 ? dates[3].data : []
      const column5Data = dates.length > 4 ? dates[4].data : []
      let labels = [
        '00:00 - 01:00',
        '01:00 - 02:00',
        '02:00 - 03:00',
        '03:00 - 04:00',
        '04:00 - 05:00',
        '05:00 - 06:00',
        '06:00 - 07:00',
        '07:00 - 08:00',
        '08:00 - 09:00',
        '09:00 - 10:00',
        '10:00 - 11:00',
        '11:00 - 12:00',
        '12:00 - 13:00',
        '13:00 - 14:00',
        '14:00 - 15:00',
        '15:00 - 16:00',
        '16:00 - 17:00',
        '17:00 - 18:00',
        '18:00 - 19:00',
        '19:00 - 20:00',
        '20:00 - 21:00',
        '21:00 - 22:00',
        '22:00 - 23:00',
        '23:00 - 24:00',
      ]
      let COLUMN_1_DATA = []
      let COLUMN_2_DATA = []
      let COLUMN_3_DATA = []
      let COLUMN_4_DATA = []
      let COLUMN_5_DATA = []
      let ALL_COLUMNS_DATA = []

      let regularTableData = []
      let comparisonTableData = []

      for (let i = 0; i < labels.length; i += 1) {
        const currentIterationTime = moment(i, 'HH').format('HH:mm')
        const nextIterationTime = moment(i + 1, 'HH').format('HH:mm')

        const matchedTimeDataItemIndexColumn1 = column1Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn1 = column1Data[matchedTimeDataItemIndexColumn1]
        COLUMN_1_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)
        ALL_COLUMNS_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)

        const matchedTimeDataItemIndexColumn2 = column2Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn2 = column2Data[matchedTimeDataItemIndexColumn2]
        COLUMN_2_DATA.push(matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0

        const matchedTimeDataItemIndexColumn3 = column3Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn3 = column3Data[matchedTimeDataItemIndexColumn3]
        COLUMN_3_DATA.push(matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0

        const matchedTimeDataItemIndexColumn4 = column4Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn4 = column4Data[matchedTimeDataItemIndexColumn4]
        COLUMN_4_DATA.push(matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0

        const matchedTimeDataItemIndexColumn5 = column5Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn5 = column5Data[matchedTimeDataItemIndexColumn5]
        COLUMN_5_DATA.push(matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0

        regularTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          total:
            (matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0) +
            (matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0) +
            (matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0) +
            (matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0) +
            (matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0),
        })

        comparisonTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          column1Total: matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0,
          column2Total: matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0,
          column3Total: matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0,
          column4Total: matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0,
          column5Total: matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0,
        })
      }

      state.frequency.timeTab.comparisonChartData = {
        labels,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data: COLUMN_1_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label:
              moment(dates[0].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
              ' - ' +
              moment(dates[0].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
          },
          {
            backgroundColor: '#61A0FF',
            borderColor: '#61A0FF',
            clip: 5,
            data: COLUMN_2_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label:
              dates.length > 1
                ? moment(dates[1].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
                  ' - ' +
                  moment(dates[1].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
                : '',
          },
          {
            backgroundColor: '#64D280',
            borderColor: '#64D280',
            clip: 5,
            data: COLUMN_3_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label:
              dates.length > 2
                ? moment(dates[2].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
                  ' - ' +
                  moment(dates[2].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
                : '',
          },
          {
            backgroundColor: '#FFA959',
            borderColor: '#FFA959',
            clip: 5,
            data: COLUMN_4_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label:
              dates.length > 3
                ? moment(dates[3].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
                  ' - ' +
                  moment(dates[3].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
                : '',
          },
          {
            backgroundColor: '#7B61FF',
            borderColor: '#7B61FF',
            clip: 5,
            data: COLUMN_5_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label:
              dates.length > 4
                ? moment(dates[4].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
                  ' - ' +
                  moment(dates[4].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
                : '',
          },
        ].slice(0, dates.length),
      }
      state.frequency.timeTab.weekTable.totals = [
        dates[0].total,
        dates.length > 1 ? dates[1].total : 0,
        dates.length > 2 ? dates[2].total : 0,
        dates.length > 3 ? dates[3].total : 0,
        dates.length > 4 ? dates[4].total : 0,
      ].slice(0, dates.length)
      state.frequency.timeTab.weekTable.regularData = regularTableData
      state.frequency.timeTab.weekTable.comparisonData = comparisonTableData

      state.frequency.timeTab.weekTable.headers = [
        moment(dates[0].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
          ' - ' +
          moment(dates[0].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
        dates.length > 1
          ? moment(dates[1].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' - ' +
            moment(dates[1].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
          : '',
        dates.length > 2
          ? moment(dates[2].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' - ' +
            moment(dates[2].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
          : '',
        dates.length > 3
          ? moment(dates[3].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' - ' +
            moment(dates[3].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
          : '',
        dates.length > 4
          ? moment(dates[4].start_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') +
            ' - ' +
            moment(dates[4].end_time, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')
          : '',
      ].slice(0, dates.length)
    },
    setFrequencyTimeMonthMutation(state, months) {
      const column1Data = months.length ? months[0].data : []
      const column2Data = months.length > 1 ? months[1].data : []
      const column3Data = months.length > 2 ? months[2].data : []
      const column4Data = months.length > 3 ? months[3].data : []
      const column5Data = months.length > 4 ? months[4].data : []
      let labels = [
        '00:00 - 01:00',
        '01:00 - 02:00',
        '02:00 - 03:00',
        '03:00 - 04:00',
        '04:00 - 05:00',
        '05:00 - 06:00',
        '06:00 - 07:00',
        '07:00 - 08:00',
        '08:00 - 09:00',
        '09:00 - 10:00',
        '10:00 - 11:00',
        '11:00 - 12:00',
        '12:00 - 13:00',
        '13:00 - 14:00',
        '14:00 - 15:00',
        '15:00 - 16:00',
        '16:00 - 17:00',
        '17:00 - 18:00',
        '18:00 - 19:00',
        '19:00 - 20:00',
        '20:00 - 21:00',
        '21:00 - 22:00',
        '22:00 - 23:00',
        '23:00 - 24:00',
      ]
      let COLUMN_1_DATA = []
      let COLUMN_2_DATA = []
      let COLUMN_3_DATA = []
      let COLUMN_4_DATA = []
      let COLUMN_5_DATA = []
      let ALL_COLUMNS_DATA = []

      let regularTableData = []
      let comparisonTableData = []

      for (let i = 0; i < labels.length; i += 1) {
        const currentIterationTime = moment(i, 'HH').format('HH:mm')
        const nextIterationTime = moment(i + 1, 'HH').format('HH:mm')

        const matchedTimeDataItemIndexColumn1 = column1Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn1 = column1Data[matchedTimeDataItemIndexColumn1]
        COLUMN_1_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)
        ALL_COLUMNS_DATA.push(matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0)

        const matchedTimeDataItemIndexColumn2 = column2Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn2 = column2Data[matchedTimeDataItemIndexColumn2]
        COLUMN_2_DATA.push(matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0

        const matchedTimeDataItemIndexColumn3 = column3Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn3 = column3Data[matchedTimeDataItemIndexColumn3]
        COLUMN_3_DATA.push(matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0

        const matchedTimeDataItemIndexColumn4 = column4Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn4 = column4Data[matchedTimeDataItemIndexColumn4]
        COLUMN_4_DATA.push(matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0

        const matchedTimeDataItemIndexColumn5 = column5Data
          .map((item) => moment(item.time, 'HH:mm:ssZ').format('HH:mm'))
          .indexOf(currentIterationTime)
        const matchedTimeDataItemColumn5 = column5Data[matchedTimeDataItemIndexColumn5]
        COLUMN_5_DATA.push(matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0)
        ALL_COLUMNS_DATA[i] += matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0

        regularTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          total:
            (matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0) +
            (matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0) +
            (matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0) +
            (matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0) +
            (matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0),
        })

        comparisonTableData.push({
          time: currentIterationTime + ' - ' + nextIterationTime,
          column1Total: matchedTimeDataItemIndexColumn1 !== -1 ? matchedTimeDataItemColumn1.count : 0,
          column2Total: matchedTimeDataItemIndexColumn2 !== -1 ? matchedTimeDataItemColumn2.count : 0,
          column3Total: matchedTimeDataItemIndexColumn3 !== -1 ? matchedTimeDataItemColumn3.count : 0,
          column4Total: matchedTimeDataItemIndexColumn4 !== -1 ? matchedTimeDataItemColumn4.count : 0,
          column5Total: matchedTimeDataItemIndexColumn5 !== -1 ? matchedTimeDataItemColumn5.count : 0,
        })
      }

      state.frequency.timeTab.comparisonChartData = {
        labels,
        datasets: [
          {
            backgroundColor: '#ff5b54',
            borderColor: '#ff5b54',
            clip: 5,
            data: COLUMN_1_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: moment(months[0].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM'),
          },
          {
            backgroundColor: '#61A0FF',
            borderColor: '#61A0FF',
            clip: 5,
            data: COLUMN_2_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: months.length > 1 ? moment(months[1].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
          },
          {
            backgroundColor: '#64D280',
            borderColor: '#64D280',
            clip: 5,
            data: COLUMN_3_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: months.length > 2 ? moment(months[2].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
          },
          {
            backgroundColor: '#FFA959',
            borderColor: '#FFA959',
            clip: 5,
            data: COLUMN_4_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: months.length > 3 ? moment(months[3].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
          },
          {
            backgroundColor: '#7B61FF',
            borderColor: '#7B61FF',
            clip: 5,
            data: COLUMN_5_DATA,
            pointRadius: 0,
            borderWidth: 2,
            label: months.length > 4 ? moment(months[4].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
          },
        ].slice(0, months.length),
      }
      state.frequency.timeTab.monthTable.totals = [
        months[0].total,
        months.length > 1 ? months[1].total : 0,
        months.length > 2 ? months[2].total : 0,
        months.length > 3 ? months[3].total : 0,
        months.length > 4 ? months[4].total : 0,
      ].slice(0, months.length)
      state.frequency.timeTab.monthTable.regularData = regularTableData
      state.frequency.timeTab.monthTable.comparisonData = comparisonTableData

      state.frequency.timeTab.monthTable.headers = [
        moment(months[0].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM'),
        months.length > 1 ? moment(months[1].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
        months.length > 2 ? moment(months[2].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
        months.length > 3 ? moment(months[3].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
        months.length > 4 ? moment(months[4].month, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM') : '',
      ].slice(0, months.length)
    },

    // TEST

    setCameraCompoarisonModeMutation(state, newData) {
      state.camera.selectedRangeMode = newData
    },
    setCameraDateRangeMutation(state, newData) {
      state.camera.selectedDateTimeRange = newData
      let dateRangeText
      switch (newData.rangeMode) {
        case Constants.RANGE_MODE_DAY:
          dateRangeText = newData.dates.map((day) => moment(day).format('YYYY-MM-DD')).join(', ')
          break
        case Constants.RANGE_MODE_WEEK:
          dateRangeText = `${moment(newData.start_time[0]).format('YYYY-MM-DD')} ~ ${moment(newData.end_time[0]).format('YYYY-MM-DD')}`
          break
        case Constants.RANGE_MODE_MONTH:
          dateRangeText = newData.months.map((month) => moment(month).format('YYYY-MM')).join(', ')
          break
        default:
          dateRangeText = `${moment(newData.start_time).format('YYYY-MM-DD')} ~ ${moment(newData.end_time).format('YYYY-MM-DD')}`
      }
      state.camera.selectedDateRangeText = dateRangeText
    },
    setFrequencyDateRangeMutation(state, newData) {
      state.frequency.selectedDateTimeRange = newData
    },
    setFrequencyTimeRangeMutation(state, newData) {
      state.frequency.selectedTimeTimeRange = newData
    },
    setFrequencyComparisonModeToggleMutation(state) {
      state.frequency.comparisonModeToggle = !state.frequency.comparisonModeToggle
    },
    setCameraComparisonModeToggleMutation(state) {
      state.camera.comparisonModeToggle = !state.camera.comparisonModeToggle
    },
    setUnselectedCameraListMutaion(state, newData) {
      state.camera.unselectedCameraList = newData
    },

    // frequency dateRage

    setFrequencySelectedTabMutation(state, tab) {
      state.frequency.selectedTab = tab
    },
    setFrequencyDateDayDateRangeMutation(state, newData) {
      state.frequency.dateTab.dayDateRange = newData
    },
    setFrequencyDateWeekDateRangeMutation(state, newData) {
      state.frequency.dateTab.weekDateRange = newData
    },
    setFrequencyDateMonthDateRangeMutation(state, newData) {
      state.frequency.dateTab.monthDateRange = newData
    },
    setFrequencyTimeNormalDateRangeMutation(state, newData) {
      state.frequency.timeTab.normalDateRange = newData
    },
    setFrequencyTimeDayDateRangeMutation(state, newData) {
      state.frequency.timeTab.dayDateRange = newData
    },
    setFrequencyTimeWeekDateRangeMutation(state, newData) {
      state.frequency.timeTab.weekDateRange = newData
    },
    setFrequencyTimeMonthDateRangeMutation(state, newData) {
      state.frequency.timeTab.monthDateRange = newData
    },

    // camera data mutation

    setCamAlertsCountByPeriodMutation(state, newData) {
      const { data, camMap } = newData
      const total = data.total
      const label = []

      for (let [key, value] of Object.entries(camMap)) {
        label.push(value.name)
      }
      const existCameraInfo = {}

      for (let i = 0; i < data.data.length; i++) {
        existCameraInfo[camMap[data.data[i].camera_id].name] = data.data[i].count
      }

      const camData = []

      label.forEach((camera) => {
        if (existCameraInfo[camera] !== undefined) camData.push(existCameraInfo[camera])
        else camData.push(0)
      })
      state.camera.currentData = { label, camData, total }
    },
    setCamAlertsCountByDaysMutation(state, newData) {
      const label = []
      const labelTotal = []
      const labelNum = newData.dates.length

      for (let i = 0; i < labelNum; i++) {
        const formatedTime = moment(newData.dates[i].date).format('YYYY-MM-DD')
        label.push(formatedTime)
        labelTotal.push(newData.dates[i].total)
      }

      const camData = new Map()
      const camNum = newData.cameras.length

      for (let i = 0; i < camNum; i++) {
        const camLogNum = newData.cameras[i].data.length
        const dateCountInfo = {} //  해당 카메라에 있는 것만 담을 예정

        for (let j = 0; j < camLogNum; j++) {
          const date = moment(newData.cameras[i].data[j].date).format('YYYY-MM-DD')
          dateCountInfo[date] = newData.cameras[i].data[j].count
        }

        const currentCameraCountInfo = []
        label.forEach((date) => {
          if (dateCountInfo[date] !== undefined) currentCameraCountInfo.push(dateCountInfo[date])
          else currentCameraCountInfo.push(0)
        })

        const noLog = camLogNum === 0 ? true : false
        const camId = newData.cameras[i].camera_id
        camData.set(camId, [noLog, currentCameraCountInfo])
      }
      state.camera.currentData = { camData, label, labelTotal }
    },
    setCamAlertsCountByMonthMutation(state, newData) {
      const label = []
      const labelTotal = []

      const labelNum = newData.dates.length

      for (let i = 0; i < labelNum; i++) {
        const formatedTime = moment(newData.dates[i].month).format('YYYY-MM')
        label.push(formatedTime)
        labelTotal.push(newData.dates[i].total)
      }
      const camData = new Map()
      const camNum = newData.cameras.length

      for (let i = 0; i < camNum; i++) {
        const camLogNum = newData.cameras[i].data.length
        const dateCountInfo = {} //  해당 카메라에 있는 것만 담을 예정

        for (let j = 0; j < camLogNum; j++) {
          const date = moment(newData.cameras[i].data[j].month).format('YYYY-MM')
          dateCountInfo[date] = newData.cameras[i].data[j].count
        }

        const currentCameraCountInfo = []
        label.forEach((date) => {
          if (dateCountInfo[date] !== undefined) currentCameraCountInfo.push(dateCountInfo[date])
          else currentCameraCountInfo.push(0)
        })

        const noLog = camLogNum === 0 ? true : false
        const camId = newData.cameras[i].camera_id
        camData.set(camId, [noLog, currentCameraCountInfo])
      }
      state.camera.currentData = { camData, label, labelTotal }
    },
    setCamAlertsCountByWeekMutation(state, newData) {
      const label = []
      const labelTotal = []
      const labelNum = newData.dates.length
      for (let i = 0; i < labelNum; i++) {
        const start = moment(newData.dates[i].start_time).format('YYYY-MM-DD')
        const end = moment(newData.dates[i].end_time).format('YYYY-MM-DD')
        label.push(`${start} ~ ${end}`)
        labelTotal.push(newData.dates[i].total)
      }
      const camData = new Map()
      const camNum = newData.cameras.length

      for (let i = 0; i < camNum; i++) {
        const camLogNum = newData.cameras[i].data.length
        const dateCountInfo = {} //  해당 카메라에 있는 것만 담을 예정
        for (let j = 0; j < camLogNum; j++) {
          const start = moment(newData.cameras[i].data[j].start_time).format('YYYY-MM-DD')
          const end = moment(newData.cameras[i].data[j].end_time).format('YYYY-MM-DD')
          dateCountInfo[`${start} ~ ${end}`] = newData.cameras[i].data[j].count
        }

        const currentCameraCountInfo = []
        label.forEach((date) => {
          if (dateCountInfo[date] !== undefined) currentCameraCountInfo.push(dateCountInfo[date])
          else currentCameraCountInfo.push(0)
        })

        const noLog = camLogNum === 0 ? true : false
        const camId = newData.cameras[i].camera_id
        camData.set(camId, [noLog, currentCameraCountInfo])
      }
      state.camera.currentData = { camData, label, labelTotal }
    },
    setAnalyticsExportFlagMutation(state) {
      state.exportFlag = !state.exportFlag
    },
  },
  actions: {
    // OVERVIEW: Fire Events
    async setOverviewFireEventsAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setOverviewFireEventsAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyDatePeriods(payload)
        commit('setOverviewFireEventsMutation', data.periods)
      } catch (e) {
        printError(e)
      }
    },
    // OVERVIEW: Fire Activity Graph
    async setOverviewFireActivityGraphAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setOverviewFireActivityGraphAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyTimeWeekHour(payload)
        commit('setOverviewFireActivityGraphMutation', data.weekdays)
      } catch (e) {
        printError(e)
      }
    },
    // OVERVIEW: Most Active Area
    async setOverviewMostActiveAreaAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setOverviewMostActiveAreaAction payload', payload)
      try {
        const { data } = await analytics.getCamAlertsCountByPeriod(payload)
        commit('setOverviewMostActiveAreaMutation', data.data)
      } catch (e) {
        printError(e)
      }
    },
    // OVERVIEW: Selected Period
    setOverviewSelectedDateRangeTextAction({ commit }, payload) {
      commit('setOverviewSelectedDateRangeTextMutation', payload)
    },
    // FREQUENCY: DATE TAB
    async setFrequencySelectedTabAction({ commit }, payload) {
      commit('setFrequencySelectedTabMutation', payload)
    },

    async setFrequencyDateTabRangeModeAction({ commit }, payload) {
      // change date tab range mode
      commit('setFrequencyDateTabRangeModeMutation', payload)
    },
    async setFrequencyTimeTabRangeModeAction({ commit }, payload) {
      // change date tab range mode
      commit('setFrequencyTimeTabRangeModeMutation', payload)
    },

    setFrequencyDateRangeAction({ commit }, payload) {
      commit('setFrequencyDateRangeMutation', payload)
    },

    async setFrequencyDateDayAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyDateDayAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyDatePeriods(payload)
        // commit('setFrequencyDateDayDateRangeMutation', payload)
        commit('setFrequencyDateDayMutation', data.periods)
      } catch (e) {
        printError(e)
      }
    },
    async setFrequencyDateWeekAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyDateWeekAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyDateWeekly(payload)
        // commit('setFrequencyDateWeekDateRangeMutation', payload)
        commit('setFrequencyDateWeekMutation', data.dates)
      } catch (e) {
        printError(e)
      }
    },
    async setFrequencyDateMonthAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyDateMonthAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyDateMonths(payload)
        // commit('setFrequencyDateMonthDateRangeMutation', payload)
        commit('setFrequencyDateMonthMutation', data.months)
      } catch (e) {
        printError(e)
      }
    },

    async setFrequencyTimeNormalAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyTimeNormalAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyTimePeriods(payload)
        commit('setFrequencyTimeNormalDateRangeMutation', payload)
        commit('setFrequencyTimeNormalMutation', data.periods[0].data)
        commit('setFrequencyTimeRangeMutation', payload)
      } catch (e) {
        printError(e)
      }
    },

    async setFrequencyTimeDayAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyTimeDayAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyTimeDays(payload)
        commit('setFrequencyTimeDayDateRangeMutation', payload)
        commit('setFrequencyTimeDayMutation', data.dates)
        commit('setFrequencyTimeRangeMutation', payload)
      } catch (e) {
        printError(e)
      }
    },
    async setFrequencyTimeWeekAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyTimeWeekAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyTimeWeekly(payload)
        commit('setFrequencyTimeWeekDateRangeMutation', payload)
        commit('setFrequencyTimeWeekMutation', data.dates)
        commit('setFrequencyTimeRangeMutation', payload)
      } catch (e) {
        printError(e)
      }
    },
    async setFrequencyTimeMonthAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setFrequencyTimeMonthAction payload', payload)
      try {
        const { data } = await analytics.getFrequencyTimeMonths(payload)
        commit('setFrequencyTimeMonthDateRangeMutation', payload)
        commit('setFrequencyTimeMonthMutation', data.months)
        commit('setFrequencyTimeRangeMutation', payload)
      } catch (e) {
        printError(e)
      }
    },
    // FREQUENCY: TIME TAB
    async setFrequencyComparisonModeToggleAction({ commit }) {
      commit('setFrequencyComparisonModeToggleMutation')
    },

    // CAMERA
    async setCameraComparisonModeToggleAction({ commit }) {
      commit('setCameraComparisonModeToggleMutation')
    },
    setCameraCompoarisonModeAction({ commit }, payload) {
      commit('setCameraCompoarisonModeMutation', payload)
    },
    setAnalyticsExportFlagAction({ commit }) {
      commit('setAnalyticsExportFlagMutation')
    },
    async getCamAlertsCountByDaysAction({ commit, state }, payload) {
      try {
        const { data } = await analytics.getCamAlertsCountByDays(payload)
        commit('setCamAlertsCountByDaysMutation', data)
        payload.rangeMode = Constants.RANGE_MODE_DAY
        commit('setCameraDateRangeMutation', payload)
      } catch (e) {
        printError(e)
      }
    },
    async getCamAlertsCountByMonthsAction({ commit, state }, payload) {
      try {
        const { data } = await analytics.getCamAlertsCountByMonths(payload)
        commit('setCamAlertsCountByMonthMutation', data)
        payload.rangeMode = Constants.RANGE_MODE_MONTH
        commit('setCameraDateRangeMutation', payload)
        return data
      } catch (e) {
        printError(e)
      }
    },
    async getCamAlertsCountByPeriodAction({ commit, state }, payload) {
      try {
        const { data } = await analytics.getCamAlertsCountByPeriod(payload[0])
        commit('setCamAlertsCountByPeriodMutation', { data, camMap: payload[1] })
        payload[0].rangeMode = Constants.RANGE_MODE_PERIOD
        commit('setCameraDateRangeMutation', payload[0])
        return data
      } catch (e) {
        printError(e)
      }
    },
    async getCamAlertsCountByWeekAction({ commit, state }, payload) {
      try {
        const { data } = await analytics.getCamAlertsCountByWeek(payload)
        commit('setCamAlertsCountByWeekMutation', data)
        payload.rangeMode = Constants.RANGE_MODE_WEEK
        commit('setCameraDateRangeMutation', payload)
        return data
      } catch (e) {
        printError(e)
      }
    },
    setUnselectedCameraListAction({ commit, state }, payload) {
      commit('setUnselectedCameraListMutaion', payload)
    },
  },
  getters: {},
}

function printError(response) {
  Vue.$log.debug('printError', response)
  if (response) {
    const { status, data, statusText } = response
    const message = typeof data === 'string' ? data : statusText
    Vue.$log.error(status, message)
  } else {
    Vue.$log.error(response)
  }
}
