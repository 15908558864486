<template>
  <article :class="`background-calendar ${themeName}`">
    <img v-if="$vuetify.theme.dark" class="close-btn" src="@/assets/Close_Line-16px-dark.svg" width="16px" height="16px" @click="closeCalendar" />
    <img v-else class="close-btn" src="@/assets/Close_Line-16px-light.svg" width="16px" height="16px" @click="closeCalendar" />
    <section class="header">
      <img v-if="$vuetify.theme.dark" @click="prevMonth" src="@/assets/Chevron-Left_24px_2_Icon.svg" />
      <img v-else @click="prevMonth" src="@/assets/Chevron-Left_24px_2_Icon-light.svg" />
      <div class="text-month">{{ monthNames[current.get('month')] }}</div>
      <img v-if="$vuetify.theme.dark" @click="nextMonth" src="@/assets/Chevron-Right_24px_2_Icon.svg" class="icon-arrow-right--month" />
      <img v-else @click="nextMonth" src="@/assets/Chevron-Right_24px_2_Icon-light.svg" class="icon-arrow-right--month" />
      <img v-if="$vuetify.theme.dark" @click="prevYear" src="@/assets/Chevron-Left_24px_2_Icon.svg" />
      <img v-else @click="prevYear" src="@/assets/Chevron-Left_24px_2_Icon-light.svg" />
      <div class="text-year">{{ year }}</div>
      <img v-if="$vuetify.theme.dark" @click="nextYear" src="@/assets/Chevron-Right_24px_2_Icon.svg" />
      <img v-else @click="nextYear" src="@/assets/Chevron-Right_24px_2_Icon-light.svg" />
    </section>
    <secttion class="calendar">
      <!-- daysOfWeek -->
      <div class="row-week">
        <div v-for="(week, index) in daysOfWeek" :key="index" class="week text-box">
          {{ week }}
        </div>
      </div>
      <div v-for="(row, rowIndex) in dateProps" :key="rowIndex" class="row-date">
        <div
          v-for="(prop, colIndex) in row"
          :key="colIndex"
          @click="onClickDate(prop.date)"
          :class="{
            day: true,
            'text-box': true,
            min: isMinDate(prop.date),
            max: isMaxDate(prop.date),
            today: isToday(prop.date),
            dark: $vuetify.theme.dark,
            select: isSelected(prop.date),
          }"
        >
          {{ prop.date.get('date') }}
        </div>
      </div>
      <!-- calendar rows -->
    </secttion>
    <section class="calendar__select--container">
      <h4 class="calendar__select--title">Selected Dates</h4>
      <div class="calendar__select--content">
        <div :class="{ 'select-date': true, dark: $vuetify.theme.dark }" v-for="(date, index) in Object.entries(selectedDates)" :key="index">
          {{ date[0] }}
          <img v-if="$vuetify.theme.dark" src="@/assets/Clear_Filled_12px_Icon-dark.svg" @click="deleteDate(date[0])" />
          <img v-else src="@/assets/Clear_Filled_12px_Icon-light.svg" @click="deleteDate(date[0])" />
        </div>
      </div>
    </section>
    <SmallButton @onClick="onClickSave">Save</SmallButton>
  </article>
</template>

<script>
import moment from 'moment'
import Theme from '@/mixin/theme.mixin'
import SmallButton from '@item/button/SmallButton'

export default {
  name: 'Calendar',
  mixins: [Theme],
  components: { SmallButton },
  props: {
    /**
     * emit events
     * update dateRange
     */
    minDate: {
      type: moment,
      default: moment().startOf('day').subtract(6, 'months').subtract(1, 'days'),
    },
    maxDate: {
      type: moment,
      default: moment().startOf('day').add(1, 'days'),
    },
    isRange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      today: moment().startOf('day'),
      current: moment().startOf('day'),
      selectedDates: {},
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    }
  },
  computed: {
    year() {
      return this.current.get('year')
    },
    dateProps() {
      let calendar = []
      let startDate = moment(this.current).startOf('month').startOf('week')
      let endDate = moment(this.current).endOf('month').endOf('week').add(1, 'days')
      let week = 0
      let daysOfWeek = []
      while (true) {
        daysOfWeek.push({ date: moment(startDate) })
        startDate.add(1, 'days')
        week++
        if (week === 7) {
          week = 0
          calendar.push(daysOfWeek)
          daysOfWeek = []
        }
        if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
          break
        }
      }
      // check showing dates
      week = calendar[0]
      let hide = true
      for (let i = 0; i < week.length; i++) {
        if (week[i].date.get('date') === 1) {
          hide = false
        }
        week[i].hide = hide
      }
      week = calendar[calendar.length - 1]
      hide = false
      for (let i = 0; i < week.length; i++) {
        if (week[i].date.get('date') === 1) {
          hide = true
        }
        week[i].hide = hide
      }
      return calendar
    },
  },
  methods: {
    closeCalendar() {
      this.$emit('closeCalendar')
    },
    makeDateToString(date) {
      return date.format('YYYY-MM-DD')
    },
    nextMonth(continueTask = false) {
      if (this.maxDate.format('YYYY-MM') !== this.current.format('YYYY-MM')) {
        this.current = moment(this.current.add(1, 'months'))
      }
    },
    prevMonth(continueTask = false) {
      if (this.minDate.format('YYYY-MM') !== this.current.format('YYYY-MM')) {
        this.current = moment(this.current.subtract(1, 'months'))
      }
    },
    nextYear() {
      for (let i = 0; i < 12; i++) {
        this.nextMonth(true)
      }
    },
    prevYear() {
      for (let i = 0; i < 12; i++) {
        this.prevMonth(true)
      }
    },
    isSelected(date) {
      const key = this.makeDateToString(date)
      const isHasSameKey = this.selectedDates[key]
      if (isHasSameKey) return true
      else return false
    },

    onClickDate(date) {
      const key = this.makeDateToString(date)
      const isHasSameKey = this.selectedDates[key]
      const selectedDateNum = Object.keys(this.selectedDates).length
      if (!isHasSameKey && selectedDateNum < 5) {
        this.$set(this.selectedDates, key, date)
      }
    },

    isToday(date) {
      return this.today.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
    },
    isMinDate(date) {
      return this.minDate.isSameOrAfter(date)
    },
    isMaxDate(date) {
      return this.maxDate.isSameOrBefore(date)
    },
    deleteDate(date) {
      this.$delete(this.selectedDates, date)
    },
    onClickSave() {
      if (Object.keys(this.selectedDates).length !== 0) {
        this.$emit('updateDaysDate', this.selectedDates)
      } else {
        alert('날짜를 정확하게 선택해주세요')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-calendar {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  width: 464px;
  min-height: 350px;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    // background-color: var(--f-bg-high);
    background-color: var(--f-bg-middle);
  }
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 25px;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 5px;

  .text-year,
  .text-month {
    margin: 0px 8px;
  }

  .icon-arrow-right--month {
    margin-right: 24px;
  }

  img {
    cursor: pointer;
    width: 24px;
  }
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin: 5px;
}

.row-week {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .week {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}

.row-date {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .day {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 34px;
    border: 1.5px solid var(--f-text-black-middle);
    color: var(--f-text-black-middle);
    &.dark {
      color: var(--f-text-white-high);
      border: 1.5px solid var(--f-text-white-high);
    }

    &.today {
      color: var(--f-text-white);
      background-color: var(--f-text-black-middle);
      border-width: 0;
      &.dark {
        color: var(--f-bg-high);
        background-color: var(--f-text-white-high);
      }

      &.focus {
        color: var(--f-text-white-high);
        background-color: var(--f-primary-70);
      }
    }
    &.select {
      color: var(--f-text-white);
      background-color: var(--f-primary-70);
      border-width: 0;
    }

    &.min,
    &.max {
      color: var(--f-text-black-low);
      border-color: var(--f-text-black-low);
      &.dark {
        color: var(--f-text-white-low);
        border-color: var(--f-text-white-low);
      }

      &.hide {
        visibility: hidden;
      }
    }
  }
}

.calendar {
  margin-bottom: 28px;
}
.calendar__select--container {
  padding: 0 40px;
  align-self: flex-start;
}

.calendar__select--content {
  display: grid;
  row-gap: 10px;
  column-gap: 12px;
  grid-template-columns: 120px 120px 120px;
  grid-template-rows: 30px 30px;
  margin: 10px 0;
}

.select-date {
  background-color: var(--f-light-gray-2);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 6px 12px 5px 12px;
  font-size: 14px;
  font-weight: 500;
  &.dark {
    background-color: var(--f-bg-high);
  }
  img {
    margin-left: 8px;
    cursor: pointer;
  }
}

button {
  margin: 30px auto;
}

.close-btn {
  cursor: pointer;
  margin: 24px 20px 0 auto;
}
</style>
