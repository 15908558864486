<template>
  <transition name="opacity-ease">
    <div :class="`navigation-tooltip ${themeName}`">
      <div :class="`navigation-tooltip-title ${themeName}`">{{ title }}</div>
      <div :class="`navigation-tooltip-description ${themeName}`">{{ description }}</div>
    </div>
  </transition>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    title: String,
    description: String,
  },
}
</script>

<style lang="scss" scoped>
.navigation-tooltip {
  position: absolute;
  top: 0;
  width: 300px;
  left: 100%;
  padding: 15px 20px;
  border-radius: 8px;
  background-color: var(--f-text-white);
  z-index: 2;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  &.dark {
    background-color: var(--f-bg-high);
  }
}

.navigation-tooltip-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  color: var(--f-primary-70);
  &.dark {
    color: var(--f-primary-50);
  }
}

.navigation-tooltip-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.opacity-ease-enter-active {
  animation: opacity-ease 0.2s ease-out both;
}

.opacity-ease-leave-active {
  animation: opacity-ease 0.1s reverse ease;
}

@keyframes opacity-ease {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
