<template>
  <nav style="height: 100%; z-index: 0">
    <div :class="`navigation ${isAdminNavExpanded ? 'nav-expanded' : ''} ${themeName}`" style="overflow-x: visible; z-index: 0">
      <div class="logo-container">
        <img
          v-show="!isAdminNavExpanded"
          @click="onClickLogo"
          data-testid="FireScout_Logo"
          src="@/assets/FireScoutSymbol_color.svg"
          height="24px"
          width="23px"
          style="margin: 50px 23px 5px; cursor: pointer"
        />
        <img
          v-show="isAdminNavExpanded && $vuetify.theme.dark"
          @click="onClickLogo"
          data-testid="FireScout_Logo"
          src="@/assets/FireScoutLogo_white.svg"
          width="140px"
          style="margin: 50px 23px 5px; cursor: pointer"
        />
        <img
          v-show="isAdminNavExpanded && !$vuetify.theme.dark"
          @click="onClickLogo"
          data-testid="FireScout_Logo"
          src="@/assets/FireScoutLogo_color.svg"
          width="140px"
          style="margin: 50px 23px 5px; cursor: pointer"
        />
      </div>
      <div class="text-organization" :style="isAdminNavExpanded ? 'opacity: 1' : 'opacity: 0'">{{ organizationName }}</div>
      <div style="display: flex; justify-content: flex-end">
        <img
          v-if="isAdminNavExpanded && $vuetify.theme.dark"
          src="@/assets/Nav_Fold_Dark.svg"
          width="32px"
          height="32px"
          @click="handleNavExpand(false)"
          class="drawer-icon"
        />
        <img
          v-else-if="!isAdminNavExpanded && $vuetify.theme.dark"
          src="@/assets/Nav_Unfold_Dark.svg"
          width="32px"
          height="32px"
          @click="handleNavExpand(true)"
          class="drawer-icon"
        />
        <img
          v-else-if="isAdminNavExpanded && !$vuetify.theme.dark"
          src="@/assets/Nav_Fold_Light.svg"
          width="32px"
          height="32px"
          @click="handleNavExpand(false)"
          class="drawer-icon"
        />
        <img v-else src="@/assets/Nav_Unfold_Light.svg" width="32px" height="32px" @click="handleNavExpand(true)" class="drawer-icon" />
      </div>

      <div v-for="(navSublist, nIndex) in navList" :key="nIndex" class="nav-list-container">
        <div :class="`text-navi-title ${themeName}`" :style="isAdminNavExpanded ? 'opacity: 1' : 'opacity: 0'">{{ navSublist.title }}</div>
        <ul>
          <li
            v-for="(nav, index) in navSublist.list"
            :key="index"
            @click.self="onClickNav(nIndex, index)"
            @mouseleave.self="resetHoverStateIndices"
            @mouseover.self="onNavItemMouseOver(nIndex, index)"
            :class="{
              'nav-item': true,
              'nav-item-collapsed': !isAdminNavExpanded,
              select: isAdminNavExpanded && isNotSupportPage && nIndex === navSublistIndex && index === navItemIndex,
              dark: $vuetify.theme.dark,
            }"
          >
            <div
              :class="{
                'nav-icon-container': true,
                select: route === nav.pathname,
              }"
              @click="onClickNav(nIndex, index)"
            >
              <img
                v-if="$vuetify.theme.dark"
                :src="$route.path === nav.pathname ? nav.icon_active : nav.icon_deactive_dark"
                width="20px"
                class="nav-icon"
              />
              <img v-else :src="$route.path === nav.pathname ? nav.icon_active : nav.icon_deactive" width="20px" class="nav-icon" />
            </div>
            <transition name="nav-item-name">
              <div v-if="isAdminNavExpanded" style="white-space: nowrap" @click.self="onClickPageName(nIndex, index)">
                {{ nav.name }}
              </div>
            </transition>
            <NavigationTooltip
              v-if="hoverNavSublistIndex === nIndex && hoverNavItemIndex === index && showNavItemTooltip"
              :title="nav.name"
              :description="nav.description"
              :style="`top:${nav.tooltipPosition.top};z-index:5;`"
            ></NavigationTooltip>
          </li>
        </ul>
      </div>
      <v-spacer style="width: 70px; margin-right: 210px"></v-spacer>
      <a :class="`link-docs ${themeName}`" href="http://docs.firescout.ai" target="_blank">
        <span v-if="isAdminNavExpanded">FireScout Documentation</span>
        <img v-else-if="!isAdminNavExpanded && $vuetify.theme.dark" :src="require('@/assets/Documentation_Collapsed_Small_Button.svg')" />
        <img v-else-if="!isAdminNavExpanded && !$vuetify.theme.dark" :src="require('@/assets/Documentation_Collapsed_Small_Button-white.svg')" />
      </a>
      <a :class="`link-docs ${themeName}`" href="http://docs.firescout.ai/others/terms-of-use" target="_blank">
        <span v-if="isAdminNavExpanded">Terms of Use</span>
        <img v-else-if="!isAdminNavExpanded && $vuetify.theme.dark" :src="require('@/assets/TermsofUse_Collapsed_Small_Button.svg')" />
        <img v-else-if="!isAdminNavExpanded && !$vuetify.theme.dark" :src="require('@/assets/TermsofUse_Collapsed_Small_Button-white.svg')" />
      </a>
      <a :class="`link-docs ${themeName}`" href="http://docs.firescout.ai/others/privacy-policy" target="_blank">
        <span v-if="isAdminNavExpanded">Privacy Policy</span>
        <img v-else-if="!isAdminNavExpanded && $vuetify.theme.dark" :src="require('@/assets/PrivacyPolicy_Collapsed_Small_Button.svg')" />
        <img v-else-if="!isAdminNavExpanded && !$vuetify.theme.dark" :src="require('@/assets/PrivacyPolicy_Collapsed_Small_Button-white.svg')" />
      </a>
    </div>
    <SupportFloatingButton v-if="navSublistIndex !== -1 && route !== '/mapview'" @click="onClickSupport"></SupportFloatingButton>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SupportFloatingButton from '@common/SupportFloatingButton'
import NavigationTooltip from '@common/NavigationTooltip'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'

export default {
  mixins: [Theme],
  components: { SupportFloatingButton, NavigationTooltip },
  data() {
    return {
      isAdminNavExpanded: false,
      isOverflowIgnore: false,
      navList: [
        {
          title: 'Logs',
          list: [
            {
              name: 'Map',
              description: 'Displays detections on a map layer. Timestamps are noted in camera local time.',
              tooltipPosition: {
                left: '260px',
                top: '0px',
              },
              icon_active: require('@/assets/Map_Line_16px_Icon.svg'),
              icon_deactive: require('@/assets/Map_Line_16px_Icon_deactive.svg'),
              icon_deactive_dark: require('@/assets/Map_Line_16px_Icon_deactive_dark.svg'),
              pathname: '/mapview',
            },
            {
              name: 'Detection',
              description: 'Displays detections from the past 72 hours. Timestamps are noted in Camera local time.',
              tooltipPosition: {
                left: '260px',
                top: '0px',
              },
              icon_active: require('@/assets/Detection_Line_16px_Icon.svg'),
              icon_deactive: require('@/assets/Detection_Line_16px_Icon_light.svg'),
              icon_deactive_dark: require('@/assets/Detection_Line_16px_Icon_0.6.svg'),
              pathname: '/detection',
            },
            {
              name: 'History',
              description: 'View all historical detections within the given timeframe. Calender dates are shown in your local time.',
              tooltipPosition: {
                left: '260px',
                top: '0px',
              },
              icon_active: require('@/assets/History_Line_16px_Icon.svg'),
              icon_deactive: require('@/assets/History_Line_16px_Icon_light.svg'),
              icon_deactive_dark: require('@/assets/History_Line_16px_Icon_0.6.svg'),
              pathname: '/history',
            },
            {
              name: 'Camera List',
              description: 'View camera information in detail.',
              tooltipPosition: {
                left: '260px',
                top: '0px',
              },
              icon_active: require('@/assets/List_Line_16px_Icon.svg'),
              icon_deactive: require('@/assets/List_Line_16px_Icon_light.svg'),
              icon_deactive_dark: require('@/assets/List_Line_16px_Icon_0.6.svg'),
              pathname: '/cameralist',
            },
          ],
        },
        // {
        //   title: 'Analytics',
        //   list: [
        //     {
        //       name: 'Overview',
        //       description: 'View the overview of the statistics on history detection data.',
        //       tooltipPosition: {
        //         left: '260px',
        //         top: '0px',
        //       },
        //       icon_active: require('@/assets/Overview_Line_16px_Icon.svg'),
        //       icon_deactive: require('@/assets/Overview_Line_1_16px_Icon.svg'),
        //       icon_deactive_dark: require('@/assets/Overview_Line_16px_Icon.svg'),
        //       pathname: '/analytics-overview',
        //     },
        //     {
        //       name: 'Frequency',
        //       description: 'View alert frequency based on date and time within the given timeframe.',
        //       tooltipPosition: {
        //         left: '260px',
        //         top: '0px',
        //       },
        //       icon_active: require('@/assets/Frequency_Line_16px_Icon.svg'),
        //       icon_deactive: require('@/assets/Calenter_Line_1_16px_Icon.svg'),
        //       icon_deactive_dark: require('@/assets/Frequency_Line_16px_Icon.svg'),
        //       pathname: '/analytics-frequency',
        //     },
        //     {
        //       name: 'Camera',
        //       description: 'View alert frequency based on the camera name within the given timeframe.',
        //       tooltipPosition: {
        //         left: '260px',
        //         top: '0px',
        //       },
        //       icon_active: require('@/assets/Camera_Line_16px_Icon.svg'),
        //       icon_deactive: require('@/assets/Camera_Line_1_16px_Icon.svg'),
        //       icon_deactive_dark: require('@/assets/Camera_Line_16px_Icon.svg'),
        //       pathname: '/analytics-camera',
        //     },
        //   ],
        // },
      ],
      // selected nav item indices
      navSublistIndex: -1,
      navItemIndex: -1,
      // hover state indices
      hoverNavSublistIndex: -1,
      hoverNavItemIndex: -1,
      showNavItemTooltip: false,
    }
  },
  watch: {
    $route(to) {
      // current Analytics are not include
      this.navList[0].list.forEach((nav, idx) => {
        if (nav.pathname === to.path) {
          this.navSublistIndex = 0
          this.navItemIndex = idx
        }
      })
    },
  },
  created() {
    this.$log.debug('Navigation#created')
    this.adjustNavIndex()
    window.onpopstate = (event) => {
      const path = event.target.location.pathname
      this.adjustNavIndex(path)
    }
  },
  beforeDestroy() {
    window.onpopstate = () => {}
  },
  computed: {
    ...mapState(['user', 'recentLogList', 'currentLog']),
    organizationName() {
      return this.user.organization_name ?? ''
    },
    isNotSupportPage() {
      return this.$route.path !== '/support-submit'
    },
    route() {
      return this.$route.path
    },
  },
  methods: {
    ignoreOverflowHover(flag) {
      this.isOverflowIgnore = flag
    },
    handleNavExpand(isHover) {
      if (isHover && !this.isAdminNavExpanded && !this.isOverflowIgnore) {
        this.isAdminNavExpanded = true
      } else if (!isHover && this.isAdminNavExpanded) {
        this.isAdminNavExpanded = false
      }
    },
    adjustNavIndex(currentPath) {
      let path = this.$route.path
      if (currentPath) path = currentPath
      if (path === '/') {
        this.navSublistIndex = 1
        this.navItemIndex = 0
        return
      }
      for (let i = 0; i < this.navList.length; i++) {
        const navSublist = this.navList[i].list
        for (let j = 0; j < navSublist.length; j++) {
          if (path.includes(navSublist[j].pathname)) {
            this.navSublistIndex = i
            this.navItemIndex = j
            return
          }
        }
      }
    },
    onClickLogo() {
      if (this.$route.path.includes('detection')) {
        this.$router.replace('/detection')
        this.$router.go()
      } else {
        this.navSublistIndex = 0
        this.navItemIndex = 1
        this.$router.push('/')
      }
    },
    onNavItemMouseOver(sublistIndex, itemIndex) {
      this.hoverNavSublistIndex = sublistIndex
      this.hoverNavItemIndex = itemIndex

      if (!this.showNavItemTooltip) {
        setTimeout(() => {
          this.showNavItemTooltip = true
        }, 500)
      }
    },
    resetHoverStateIndices() {
      this.hoverNavSublistIndex = -1
      this.hoverNavItemIndex = -1
      this.showNavItemTooltip = false
    },
    onClickNav(sublistIndex, itemIndex) {
      const currentPathName = this.$route.path
      const nextPathName = this.navList[sublistIndex].list[itemIndex].pathname
      if (currentPathName !== nextPathName) {
        this.$router.push(this.navList[sublistIndex].list[itemIndex].pathname)
      } else if (nextPathName === '/detection') {
        this.$router.replace('/detection')
        this.$router.go()
      }
    },
    onClickPageName(sublistIndex, itemIndex) {
      if (!this.isAdminNavExpanded) return
      const currentPathName = this.$route.path
      const nextPathName = this.navList[sublistIndex].list[itemIndex].pathname
      if (currentPathName !== nextPathName) {
        this.$router.push(this.navList[sublistIndex].list[itemIndex].pathname)
      } else if (nextPathName === '/detection') {
        this.$router.replace('/detection')
        this.$router.go()
      }
    },
    onClickSupport() {
      this.navSublistIndex = -1
      this.navItemIndex = -1
      this.$router.push('/support-submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70px;
  background-color: var(--f-supplementary-highlight);
  transition: width 0.5s ease-in-out;
  -webkit-transition: width 0.5s ease-in-out;

  &.nav-expanded {
    width: 280px;
    transition: width 0.5s ease-in-out;
    -webkit-transition: width 0.5s ease-in-out;
  }

  &.dark {
    border-right: 1px solid #323340;
    background-color: var(--f-bg-middle);
  }

  .logo-container {
    width: 100%;
    overflow-x: hidden;
  }

  .text-organization {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--f-primary-100);
    padding: 0 23px;
    transition: 0.5s ease-in-out;
  }
  .nav-list-container {
    .text-navi-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      padding: 0 23px;
      margin-top: 10px;
      margin-bottom: 15px;
      color: var(--f-text-black-middle);
      transition: 0.5s ease-in-out;
      &.dark {
        color: var(--f-text-white-high);
      }
    }

    ul {
      margin-bottom: 8px;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
    }
    .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 250px;
      height: 46px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--f-text-black-high);
      margin-right: 30px;
      // color change animation
      background: linear-gradient(to left, transparent 50%, var(--f-primary-90) 50%);
      background-size: 200%;
      background-position: right;
      transition: background-position 0.5s ease-in-out;

      &.dark {
        color: var(--f-text-white-middle);
      }

      &.nav-item-collapsed {
        width: 70px;
        cursor: default;
      }

      cursor: pointer;

      &.select {
        background-position: left;
        border-radius: 0px 16px 16px 0px;
        color: var(--f-text-white);
        &.dark {
          color: var(--f-text-white-high);
        }
      }

      .nav-icon-container {
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 23px;
        margin-right: 26px;

        cursor: pointer;

        &.select {
          background-color: var(--f-primary-90);
          color: var(--f-text-white);
          &.dark {
            color: var(--f-text-white-high);
          }
        }
      }
    }
  }

  .nav-item-name-enter-active {
    animation: fadeIn 0.4s ease-in-out;
  }
  .nav-item-name-leave-active {
    animation: fadeOut 0.4s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .link-docs {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 0 30px;
    margin-bottom: 15px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;
    overflow-x: hidden;

    color: var(--f-primary-50);
    &.dark {
      color: var(--f-text-white-high);
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }
}
.nav-icon {
  user-select: none;
}
.drawer-icon {
  cursor: pointer;
  user-select: none;
  margin-right: -16px;
}
</style>
