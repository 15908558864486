<template>
  <div class="toast-content-container">
    <div class="toast-content">
      <div class="toast-title">{{ cameraName }}</div>
      <div class="toast-text" v-html="descriptionText"></div>
    </div>
    <v-btn class="toast-check-btn" color="#FF5B54" depressed @click="onClickCheck()">Check</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    cameraName: {
      type: String,
      default: '',
    },
    log: {
      type: Object,
    },
    fireCoordinatesFound: {
      type: Boolean,
      default: false,
    },
    fireDirectionFound: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    descriptionText() {
      if (this.fireCoordinatesFound) {
        return 'Fire Location coordinates found.<br>Center map on Fire Location.'
      } else if (this.fireDirectionFound) {
        return 'Fire Direction found.<br>Center map on Fire Direction.'
      }
      return 'Fire Info not found.<br>Center map on detected Camera.'
    },
  },
  methods: {
    onClickCheck() {
      this.$emit('check-toast', this.log)
    },
  },
}
</script>

<style lang="scss" scoped>
.toast-content-container {
  display: flex;
  flex-grow: 1;

  .toast-content {
    display: flex;
    width: calc(100% - 70px);
    flex-direction: column;
    justify-content: space-between;

    .toast-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .toast-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .toast-check-btn {
    height: 28px !important;
    width: 70px;
    position: fixed;
    bottom: 12px;
    right: 16px;
    font-weight: 600;
  }
}
</style>
