<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="5px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <InputSearchBar
        ref="input"
        v-bind="attrs"
        v-on="on"
        :value="text"
        @input="onInput"
        placeholder="Camera Name or Event ID"
        @focus="onFocus"
        @blur="onBlur"
        @clear="onClear"
        @keydown="onKeydown"
      ></InputSearchBar>
    </template>
    <div v-if="filteredItems.length" ref="dropdownContainer" :class="`dropdown-container ${themeName}`" data-testid="Search_Bar_Dropdown">
      <div class="wrapper" data-testid="Search_Bar_List">
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="onClickItem(item)"
          :class="{ item: true, select: getItemValue(select) === getItemValue(item) }"
        >
          <div>
            {{ filteredText[index].prev }}<span :class="`highlight ${themeName}`">{{ filteredText[index].text }}</span
            >{{ filteredText[index].next }}
          </div>
        </div>
      </div>
    </div>
    <div v-else ref="dropdownContainer" :class="`dropdown-container ${themeName}`">
      <div class="wrapper">
        <div :class="`no-item ${themeName}`">No data available</div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputSearchBar from '@common/InputSearchBarAdmin'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { InputSearchBar },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      open: false,
      text: '',
      focus: false,
      select: null,
      eventSearchRegx: /^#\d+$/,
      timerId: null,
      isCorrectEventId: false,
    }
  },
  computed: {
    ...mapState(['currentCam', 'currentLog']),
    isEventSearch() {
      return this.eventSearchRegx.test(this.text)
    },
    filteredItems() {
      if (this.isEventSearch) {
        if (this.isCorrectEventId) {
          return [`#${this.text}`]
        } else {
          return []
        }
      } else {
        if (this.text && this.text.length) {
          return this.items.filter((item) => {
            const value = this.getItemText(item)
            const text = value.toLowerCase()
            const search = this.text.toLowerCase()
            const check = text.includes(search)
            return check
          })
        } else {
          return this.items
        }
      }
    },
    filteredText() {
      if (this.isEventSearch) {
        return [
          {
            prev: '',
            text: this.text,
            next: '',
          },
        ]
      } else {
        if (this.text && this.text.length) {
          return this.filteredItems.map((item) => {
            const value = this.getItemText(item)
            const text = value.toLowerCase()
            const search = this.text.toLowerCase()
            const index = text.indexOf(search)
            return {
              prev: value.substring(0, index),
              text: value.substring(index, index + search.length),
              next: value.substring(index + search.length, value.length),
            }
          })
        } else {
          return this.filteredItems.map((item) => {
            const value = this.getItemText(item)
            return { prev: value, text: '', next: '' }
          })
        }
      }
    },
  },
  methods: {
    ...mapActions(['getLog', 'detectionPageAction/forceInitialize']),
    onChange(val) {
      if (this.focus && !val) {
        this.$nextTick(() => {
          this.open = true
        })
      } else if (!val) {
        if (this.$refs.dropdownContainer) this.$refs.dropdownContainer.scrollTop = 0
        this.select = null
      }
    },
    onFocus() {
      this.focus = true
    },
    onBlur() {
      this.focus = false
    },
    onInput(text) {
      this.text = text
    },
    onClear() {
      this.select = null
      this.$emit('input', null)
    },
    clearInput() {
      this.$refs.input.onClear()
    },
    onKeydown(e) {
      if (this.isCorrectEventId) this.isCorrectEventId = false
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        if (this.select) {
          let index = this.filteredItems.findIndex((item) => this.getItemValue(item) === this.getItemValue(this.select))
          if (index === -1) {
            this.select = this.filteredItems[0]
          } else {
            index = e.code === 'ArrowDown' ? index + 1 : index - 1
            const select = this.filteredItems[index]
            if (select) {
              this.select = select
              // scroll move
              if (this.$refs.dropdownContainer) {
                const offsetTop = this.$refs.dropdownContainer.querySelector('.select').offsetTop
                if (e.code === 'ArrowDown') {
                  this.$refs.dropdownContainer.scrollTop = offsetTop
                } else {
                  this.$refs.dropdownContainer.scrollTop = offsetTop - 37 * 2
                }
              }
            }
          }
        } else {
          this.select = this.filteredItems[0]
        }
      } else if (e.code === 'Enter') {
        if (this.isEventSearch) {
          this.onClickItem()
        } else {
          const item = this.filteredItems.find((item) => item === this.select)
          if (this.select && item) {
            this.onClickItem(this.select)
          }
        }
      } else {
        // 이벤트 로그 검색을 위한 로직
        if (this.text[0] === '#') {
          if (this.timerId) {
            clearTimeout(this.timerId)
          }
          this.timerId = setTimeout(async () => {
            const info = await this.getLog(this.text.substring(1))
            if (info) {
              this.isCorrectEventId = true
            } else {
              this.isCorrectEventId = false
            }
          }, 500)
        }
      }
    },
    async onClickItem(item) {
      if (this.isEventSearch) {
        const info = await this.getLog(this.text.substring(1))
        if (info) {
          this.open = false
          this.select = info
          this.$emit('input', info)
        }
      } else {
        let value = this.getItemValue(item)
        this.open = false
        this.select = item
        this.onInput(this.getItemText(item))
        this.$emit('input', value)
      }
    },
    getItemText(item) {
      return item && this.itemText ? item[this.itemText] : item
    },
    getItemValue(item) {
      return item && this.itemValue ? item[this.itemValue] : item
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-container {
  width: 100%;
  max-height: 181px;
  padding: 8px 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  overflow: auto;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }

  .no-item {
    text-align: center;
    margin: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: #00000042;

    &.dark {
      color: #ffffff99;
    }
  }

  .item {
    display: flex;
    align-items: center;
    min-height: 33px;
    padding: 0 30px;
    width: 100%;
    font-size: 14px;

    &:hover,
    &.select {
      background-color: var(--f-primary-50);
    }

    .highlight {
      background-color: var(--f-supplementary-highlight);
      &.dark {
        background-color: var(--f-primary-90);
      }
    }
  }
}
</style>
