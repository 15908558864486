<template>
  <div
    data-testid="Search_Bar"
    :class="{ 'search-bar-root': true, focus, dark: $vuetify.theme.dark }"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHaspopup"
    :role="role"
    @click="$emit('click', $event)"
  >
    <img v-if="focus" src="@/assets/Search_16px_Icon-red.svg" class="icon-search" />
    <img v-else-if="$vuetify.theme.dark" src="@/assets/Search_16px_Icon-dark.svg" class="icon-search" />
    <img v-else src="@/assets/Search_16px_Icon.svg" class="icon-search" />
    <input
      data-testid="Search_InputField"
      ref="input"
      :class="themeName"
      type="text"
      :placeholder="placeholder"
      @keydown="onKeydown"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      maxlength="50"
    />
    <img v-if="!empty && $vuetify.theme.dark" @click="onClickClearButton" src="@/assets/Input_Search_Bar_Close.svg" class="icon-clear" />
    <img v-else-if="!empty" @click="onClickClearButton" src="@/assets/Input_Search_Bar_Close-red.svg" class="icon-clear" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'

export default {
  mixins: [Theme],
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    ariaExpanded: String,
    ariaHaspopup: Boolean,
    role: String,
  },
  data() {
    return {
      focus: false,
      text: this.value,
    }
  },
  computed: {
    ...mapState(['isLogoClick']),
    empty() {
      return !this.text.length
    },
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
    isLogoClick() {
      this.onClear()
    },
  },
  methods: {
    ...mapActions(['detectionPageAction/forceInitialize']),
    onInput(e) {
      this.text = e.target.value
      this.$emit('input', this.text)
    },
    onBlur(e) {
      this.focus = false
      this.$emit('blur', e)
    },
    onFocus(e) {
      this.focus = true
      this.$emit('focus', e)
    },
    onClear() {
      this.$refs.input.value = ''
      this.text = ''
      this.$emit('input', this.text)
      this.$emit('clear')
    },
    onClickClearButton() {
      this.$refs.input.value = ''
      this.text = ''
      this.$emit('input', this.text)
      this.$emit('clear')
      if (this.$route.path === '/detection') {
        this.scrollToTop()
        this.$router.replace('/detection')
        this['detectionPageAction/forceInitialize']()
      }
    },
    scrollToTop() {
      const pageDom = document.getElementsByClassName('detection')[0]
      Util.moveScrollToTop(true, pageDom)
    },
    onKeydown(e) {
      if (e.code === 'Space') {
        this.text = e.target.value + ' '
        this.$emit('input', this.text)
      } else {
        this.$emit('keydown', e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-bar-root {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  min-width: 300px;

  &.dark {
    background-color: #25262c;
    border-width: 0;
  }

  &.focus {
    border: 1px solid var(--f-primary-90);
  }
  .icon-search {
    width: 16px;
    margin: 0 8px 0 16px;
  }

  input {
    flex-grow: 1;
    outline: none;
    font-size: 14px;
    caret-color: var(--f-primary-90);
    color: var(--f-text-black);
    &.dark {
      color: var(--f-text-white-high);
      &::placeholder {
        color: var(--f-text-white-middle);
      }
    }

    &::placeholder {
      color: #00000061;

      &.dark {
        color: var(--f-text-white-middle);
      }
    }
  }

  .icon-clear {
    width: 16px;
    cursor: pointer;
    margin-right: 16px;
  }
}
</style>
