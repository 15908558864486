export default Object.freeze({
  TOKEN_NAME: 'vadt_token',
  TOKEN_NAME_SETTING: 'vs',
  TOKEN_EXPIRE: 'vadt_expire',
  TOKEN_EXPIRE_MAX: 'vadt_em',
  TOKEN_EXPIRE_MAX_DAY: 14,
  KEEP_LOGIN: 'keep_login',
  THEME_DARK: 'vadt_theme_dark',
  CONTRACT_TYPE_POC: 'POC',
  ROLE_SUPER: 'SUPER',
  ROLE_ADMIN: 'ADMIN',
  ROLE_VIEWER: 'VIEWER',
  ROLE_WORKER: 'WORKER',
  ROLE_SUPER_ADMIN: 'SUPER ADMIN',
  ROLE_OBSERVER: 'OBSERVER',
  ROLE_EDITOR: 'EDITOR',
  ROLE_ID: {
    'SUPER ADMIN': 1,
    WORKER: 2,
    ADMIN: 3,
    VIEWER: 4,
    EDITOR: 5,
    OBSERVER: 6,
  },
  CONFIRMED: 'confirmed',
  SKIPPED: 'skipped',
  SKIPPED_HARD: 'skipped-hard',
  SETTINGS: 'settings',
  MINUTE_TIME: 60000,
  NAVI_ORGANIZATION: 0,
  NAVI_USER_ACCOUNT: 1,
  NAVI_ALERT_RECIPIENT: 2,
  NAVI_CAMERA_LIST: 3,
  NAVI_CAMERA_ALLOCATION: 4,
  COLORS: ['#FF8181', '#FFAE81', '#FFD66C', '#9CE782', '#81D1FF', '#8186FF', '#A981FF', '#FF81CC', '#A4A4A4', '#D4B585'],
  INTERVAL_SITE_LOG: 5000,
  DEFAULT_TOOLBAR_URLS: ['detection', 'history', 'cameralist'],
  ANALYTICS_URL: ['analytics-camera', 'analytics-frequency', 'analytics-overview'],
  ANALYTICS_TITLE: {
    'analytics-camera': 'Camera',
    'analytics-frequency': 'Frequency',
    'analytics-overview': 'Overview',
  },
  FREQUENCY_TAB_DATE: 'DATE',
  FREQUENCY_TAB_TIME: 'TIME',
  RANGE_MODE_DAY: 'DAY',
  RANGE_MODE_WEEK: 'WEEK',
  RANGE_MODE_MONTH: 'MONTH',
  RANGE_MODE_PERIOD: 'PERIOD',
  ANALYTICS_CAMERA_PAGE: 'Camera',
  ANALYTICS_FREQUENCY_PAGE: 'Frequency',
  ANALYTICS_OVERVIEW_PAGE: 'Overview',
  RANGE_MODE_LIST: ['DAY', 'WEEK', 'MONTH'],
  GRAPH_COLORS: ['#7B61FF', '#64D280', '#FFA959', '#61A0FF', '#FF6E68'],
  MAP_VIEW_FILTER: {
    detectionType: {
      allDetection: false,
      firstDetection: true,
    },
    camera: {
      allCamera: true,
      alertCamera: false,
      noCamera: false,
    },
    detectionWithin: {
      dateObj: null,
      isCustom: false,
      selectedHoursFilter: 72,
    },
    responseStatus: {
      alerted: true,
      validated: true,
      responded: true,
    },
    eventType: {
      fire: true,
      nonFire: true,
      unknown: true,
      plannedBurn: true,
      unselected: true,
    },
    pfirsData: {
      allBurns: true,
      noBurns: false,
    },
  },
  cameraListUpload: {
    templateFileHeaders: [
      'ID',
      'Camera Name',
      'API URL',
      'Public URL',
      'Private URL',
      'Private URL 2',
      'Guide URL',
      'Latitude',
      'Longitude',
      'Altitude(m)',
      'Country',
      'Region',
      'County',
      'Camera Provider',
      'API Key',
      'Monitored',
      'Camera Group',
      'PTZ Value',
      'Image Quality',
      'Night Detection',
      'IR Filter',
      'Serviced',
      'Evaluated',
      'Internal Usage',
      'Within HFTD Area',
      'Pan/Fixed',
      'Camera Cleanness',
      'Sky Area',
      'False Positive Area',
    ],
    errorTexts: {
      EMPTY_CAMERA_NAME: 'Empty camera name value.',
      EMPTY_COUNTRY: 'empty country value.',
      EMPTY_REGION: 'empty region value.',
      EMPTY_COUNTY: 'empty county value.',
      EMPTY_LATITUDE: 'empty latitude value.',
      EMPTY_LONGITUDE: 'empty longitude value.',
      EMPTY_ALTITUDE: 'empty altitude value.',
      EMPTY_CAMERA_PROVIDER: 'empty camera provider value.',
      EMPTY_API_KEY: 'empty api key value.',
      EMPTY_API_URL: 'empty api url value.',
      EMPTY_MONITORED: 'empty monitored value.',
      EMPTY_CAMERA_GROUP: 'empty camera group value.',
      EMPTY_PTZ_VALUE: 'empty ptz value column.',
      EMPTY_IMAGE_QUALITY: 'empty image quality column.',
      EMPTY_NIGHT_DETECTION: 'empty night detection column.',
      EMPTY_IR_FILTER: 'empty ir filter column.',
      EMPTY_SERVICED: 'empty serviced column.',
      EMPTY_EVALUATED: 'empty evaluated column.',
      EMPTY_INTERNAL_USAGE: 'empty internal usage column.',
      DUPLICATE_CAMERA_NAME: 'camera name is duplicate.',
      EXCEED_MAX_LENGTH_CAMERA_NAME: 'camera name exceeded maximum length (40).',
      INVALID_CAMERA_NAME_FORMAT: 'camera name format is invalid.',
      DUPLICATE_API_URL: 'API URL is duplicate.',
      INVALID_API_URL_FORMAT: 'API URL format is invalid.',
      INVALID_PUBLIC_URL_FORMAT: 'Public URL format is invalid.',
      INVALID_PRIVATE_URL_FORMAT: 'Private URL format is invalid.',
      INVALID_GUIDE_URL_FORMAT: 'Guide URL format is invalid.',
      INVALID_LATITUDE: 'latitude value is invalid.',
      INVALID_LONGITUDE: 'longitude value is invalid.',
      INVALID_ALTITUDE: 'altitude value is invalid.',
      // UNLISTED_API_KEY: 'API key is unlisted,',
      // UNLISTED_COUNTRY: 'country is unlisted.',
      // UNLISTED_REGION: 'region is unlisted.',
      // UNLISTED_COUNTY: 'county is unlisted.',
      // UNLISTED_CAMERA_PROVIDER: 'camera provider is unlisted',
      // INVALID_REGION: 'region is not related to country.',
      // INVALID_COUNTY: 'county is not related to region.',
      INVALID_COUNTRY: 'country format is invalid.',
      INVALID_REGION: 'region format is invalid.',
      INVALID_COUNTY: 'county format is invalid.',
      INVALID_CAMERA_GROUP_ID: 'camera group value is invalid.',
    },
    cameraInformationOptions: {
      CAMERA_CLEARNESS: {
        'Very Dirty': 1,
        Dirty: 2,
        Normal: 3,
        Clean: 4,
        'Very Clean': 5,
      },
      EVALUATED: {
        Yes: 1,
        No: 2,
      },
      FALSE_POSITIVE_AREAS: {
        Yes: 1,
        No: 2,
      },
      IMAGE_QUALITY: {
        'Above FHD': 1,
        FHD: 2,
        'Under FHD': 3,
      },
      INTERNAL_USAGE: {
        Yes: 1,
        No: 2,
      },
      IR_FILTER: {
        Yes: 1,
        No: 2,
      },
      NIGHT_DETECTION: {
        Yes: 1,
        No: 2,
      },
      PAN_OR_FIXED: {
        Pan: 1,
        Fixed: 2,
        Unknown: 3,
      },
      PTZ_VALUE: {
        Yes: 1,
        No: 2,
      },
      SERVICED: {
        Yes: 1,
        No: 2,
      },
      SKY_AREA: {
        'Very High': 1,
        High: 2,
        Normal: 3,
        Low: 4,
        'Very Low': 5,
      },
      HFTD_AREA: {
        'Tier 1': 1,
        'Tier 2': 2,
        'Tier 3': 3,
        Unselected: 4,
      },
    },
    cameraInformationOptionsByID: {
      CAMERA_CLEARNESS: {
        1: 'Very Dirty',
        2: 'Dirty',
        3: 'Normal',
        4: 'Clean',
        5: 'Very Clean',
      },
      EVALUATED: {
        1: 'Yes',
        2: 'No',
      },
      FALSE_POSITIVE_AREAS: {
        1: 'Yes',
        2: 'No',
      },
      IMAGE_QUALITY: {
        1: 'Above FHD',
        2: 'FHD (1920*1080)',
        3: 'Under FHD',
      },
      INTERNAL_USAGE: {
        1: 'Yes',
        2: 'No',
      },
      IR_FILTER: {
        1: 'Yes',
        2: 'No',
      },
      NIGHT_DETECTION: {
        1: 'Yes',
        2: 'No',
      },
      PAN_OR_FIXED: {
        1: 'Pan',
        2: 'Fixed',
        3: 'Unknown',
      },
      PTZ_VALUE: {
        1: 'Yes',
        2: 'No',
      },
      SERVICED: {
        1: 'Yes',
        2: 'No',
      },
      SKY_AREA: {
        1: 'Very High',
        2: 'High',
        3: 'Normal',
        4: 'Low',
        5: 'Very Low',
      },
      HFTD_AREA: {
        1: 'Tier1',
        2: 'Tier2',
        3: 'Tier3',
        4: 'Unselected',
      },
    },
  },
  ARCGIS_API_KEY: 'AAPK93b745066d5a4b1d823d8c3bee7803c1Na5Z6v86g5fZYPjKCUgGn7HQgYOBchAKFEUB7CdgNclAMr-KylNYI7JW3r-mfbcq',
  ARCGIS_POINT_DEFAULT: {
    id: '',
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [0, 0] },
    properties: {
      called_time: '',
      camera_id: -1,
      camera_name: '',
      offset: '',
      log_id: -1,
      pan_direction: 0,
      tilt_direction: 0,
      latitude: 0,
      longitude: 0,
      event_type_id: -1,
      created_at: '',
      response_status: {
        is_alerted: false,
        is_validated: false,
        is_responded: false,
        alerted_time: '',
        validated_time: null,
        responded_time: null,
      },
      camera_coordinate: [0, 0],
    },
  },
  ARCGIS_LINE_DEFAULT: {
    id: '',
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [
        [0, 0],
        [0, 0],
      ],
    },
    properties: {
      called_time: '',
      camera_id: -1,
      camera_name: '',
      offset: '',
      log_id: -1,
      pan_direction: 0,
      tilt_direction: 0,
      latitude: -1,
      longitude: -1,
      event_type_id: -1,
      created_at: '',
      response_status: {
        is_alerted: false,
        is_validated: false,
        is_responded: false,
        alerted_time: '',
        validated_time: null,
        responded_time: null,
      },
      camera_coordinate: [0, 0],
    },
  },
})
