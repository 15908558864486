<template>
  <section :class="{ 'month-wrapper': true, dark: $vuetify.theme.dark }">
    <div style="width: 100%; display: flex">
      <img v-if="$vuetify.theme.dark" class="close-btn" src="@/assets/Close_Line-16px-dark.svg" width="16px" height="16px" @click="closeCalendar" />
      <img v-else class="close-btn" src="@/assets/Close_Line-16px-light.svg" width="16px" height="16px" @click="closeCalendar" />
    </div>
    <div class="year">
      <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Left_24px_2_Icon.svg" class="arrow left" @click="prevYear" />
      <img v-else src="@/assets/Chevron-Left_24px_2_Icon-light.svg" class="arrow left" @click="prevYear" />
      {{ year }}
      <img v-if="$vuetify.theme.dark && !isCurrentYear" src="@/assets/Chevron-Right_24px_2_Icon.svg" @click="nextYear" class="arrow right" />
      <img
        v-else-if="!$vuetify.theme.dark && !isCurrentYear"
        src="@/assets/Chevron-Right_24px_2_Icon-light.svg"
        @click="nextYear"
        class="arrow right"
      />
      <img v-else-if="$vuetify.theme.dark && isCurrentYear" src="@/assets/Chevron-Right_24px_disable_dark.svg" class="arrow right" />
      <img v-else src="@/assets/Chevron-Right_24px_disable_light.svg" class="arrow right" />
    </div>

    <div class="month-container">
      <div
        v-for="(month, index) in monthText"
        :key="index"
        :class="{ month: true, focus: isSelected(month), min: isMinMonth(index), max: isMaxMonth(index), dark: $vuetify.theme.dark }"
        @click="addMonth(index)"
      >
        {{ month }}
      </div>
    </div>
    <div class="select">
      Selected months
      <div class="select-container">
        <div :class="{ 'select-month': true, dark: $vuetify.theme.dark }" v-for="(month, index) in selectedMonths" :key="index">
          {{ month }}
          <img v-if="$vuetify.theme.dark" src="@/assets/Clear_Filled_12px_Icon-dark.svg" @click="removeMonth(month)" />
          <img v-else src="@/assets/Clear_Filled_12px_Icon-light.svg" @click="removeMonth(month)" />
        </div>
      </div>
      <SmallButton style="margin: 0 auto 30px auto" @onClick="onClickSave">Save</SmallButton>
    </div>
  </section>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import SmallButton from '@item/button/SmallButton'
import moment from 'moment'

export default {
  mixins: [Theme],
  components: { SmallButton },
  props: {
    minDate: {
      type: Object,
      default: moment().subtract(6, 'months').subtract(1, 'days'),
    },
  },
  data() {
    return {
      year: moment().format('YYYY'),
      today: moment().format('YYYY'),
      monthText: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      monthNum: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      selectedMonths: [],
    }
  },
  computed: {
    isCurrentYear() {
      if (this.year == this.today) return true
      else false
    },
  },
  methods: {
    closeCalendar() {
      this.$emit('closeCalendar')
    },
    isMinMonth(index) {
      const currentMonth = this.monthNum[index]
      if (this.minDate.isAfter(`${this.year}-${currentMonth}-01`)) return true
      return false
    },
    isMaxMonth(index) {
      const currentMonth = this.monthNum[index]
      if (moment().isBefore(`${this.year}-${currentMonth}-01`)) return true
      else return false
    },
    nextYear() {
      this.year = `${1 + parseInt(this.year)}`
    },
    prevYear() {
      this.year = `${-1 + parseInt(this.year)}`
    },
    addMonth(index) {
      const isMax = this.isMaxMonth(index)
      const isMin = this.isMinMonth(index)
      if (this.selectedMonths.length >= 5 || isMax || isMin) return
      const month = this.monthNum[index]
      const selectMonth = `${this.year}-${month}`
      const isExist = this.selectedMonths.includes(selectMonth)
      if (isExist) return
      this.selectedMonths.push(selectMonth)
    },
    isSelected(month) {
      const index = this.monthText.indexOf(month)

      const current = `${this.year}-${index >= 9 ? index + 1 : `0${index + 1}`}`

      if (this.selectedMonths.includes(current)) {
        return true
      } else {
        return false
      }
    },
    removeMonth(month) {
      this.selectedMonths = this.selectedMonths.filter((v) => v !== month)
    },
    onClickSave() {
      const range = [...this.selectedMonths]
      this.$emit('updateMonthDate', this.selectedMonths)
    },
  },
}
</script>

<style lang="scss" scoped>
.year {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  cursor: pointer;
  &.left {
    margin-right: 16px;
  }
  &.right {
    margin-left: 16px;
  }
}

.month-wrapper {
  width: 400px;
  background-color: #ffffff;

  border-radius: 16px;
  position: absolute;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  padding: 0 40px;
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.month-container {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  // border-top: 1px solid var(--f-bg-high);
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 40px 40px 40px;
  margin-bottom: 28px;
}

.select-container {
  display: grid;
  row-gap: 10px;
  column-gap: 12px;
  grid-template-columns: 99px 99px 99px;
  grid-template-rows: 30px 30px;
  margin: 10px 0 40px 0;
}

.month {
  border: 1px solid #00000042;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: #00000042;
  &.dark {
    color: #ffffffde;
    border: 1px solid #ffffffde;
  }
  &.max,
  &.min {
    border: 1px solid #0000001f;
    color: #0000001f;
    &.dark {
      color: #ffffff61;
      border: 1px solid #ffffff61;
    }
  }
}
.select {
  text-align: start;
  font-weight: 600;
  font-size: 14px;
}
.select-month {
  width: 100px;
  height: 30px;
  background-color: var(--f-light-gray-2);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  &.dark {
    background-color: var(--f-bg-high);
  }
  img {
    margin-left: 8px;
    cursor: pointer;
  }
}
.focus {
  background-color: var(--f-primary-70);
  border: none !important;
  color: #ffffffde;
}

.close-btn {
  cursor: pointer;
  margin: 24px -20px 0 auto;
}
</style>
