<template>
  <div :class="`map-view-navigation ${themeName}`">
    <div class="nav-left">
      <img v-if="$vuetify.theme.dark" src="@/assets/Camera_Filled_24px_Icon.svg" width="24px" style="margin: 5px" />
      <img v-else src="@/assets/Camera_Filled_24px_Icon_red.svg" width="24px" style="margin: 5px" />
      <div :class="`map-view-navigation-label ${themeName}`">Monitored Cameras</div>
      <div class="camera-count-text">{{ cameraCount }}</div>
      <div :class="`map-view-navigation-label ${themeName}`">Hello,</div>
      <div class="user-id-text">{{ user.user_name }}!</div>
      <ButtonAlertOff :disabled="isRoleViewer" :isMapview="true"></ButtonAlertOff>
    </div>
    <div class="nav-right">
      <Button @click="onClickMonitor" :disabled="!isAdmin" style="min-width: 108px; height: 44px; margin-right: 20px">Monitor</Button>
      <div @click="onClickLogout" :class="`logout ${themeName}`">Logout</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'
import Constants from '@/constants'

import ButtonAlertOff from '@desktop/adminOrViewer/detection/ButtonAlertOff'
import Button from '@common/Button'

export default {
  mixins: [RotuerMixin, SelfInfoMixin, Theme],
  components: {
    ButtonAlertOff,
    Button,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['camList', 'user']),
    isAdmin() {
      return this.user.isAdmin
    },
    isRoleViewer() {
      return this.user.user_role === Constants.ROLE_VIEWER
    },
    cameraCount() {
      return this.camList.length
    },
  },
  methods: {
    onClickMonitor() {
      this.$router.push(`/monitor`)
    },
    async onClickLogout() {
      await this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
.map-view-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 15px 0; */
  padding: 15px 22px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-low);
    border-bottom: 1px solid var(--f-bg-high);
  }

  .nav-left {
    display: flex;
    justify-content: center;
    align-items: center;
    .map-view-navigation-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #363636;
      &.dark {
        color: #ffffffde;
      }
    }

    .camera-count-text {
      color: #ff6e68;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-left: 5px;
      margin-right: 20px;
    }

    .user-id-text {
      color: #ff6e68;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      white-space: nowrap;
      margin-left: 5px;
      margin-right: 24px;
    }
  }

  .nav-right {
    display: flex;
    justify-content: center;
    align-items: center;

    .logout {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      margin-right: 30px;
      color: var(--f-primary-90);
      &.dark {
        color: var(--f-text-white-high);
      }
    }
  }
}
</style>
