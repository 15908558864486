<template>
  <div class="text-center">
    <v-menu offset-y v-model="menuOpen" :close-on-click="closeOnClick" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <button :class="{ 'dropdown-button': true, dark: $vuetify.theme.dark }" v-bind="attrs" v-on="on">
          {{ currentDisplay }}

          <img v-if="menuOpen && $vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-up-gray-small.svg" />
          <img v-else-if="!menuOpen && $vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-down-gray-small.svg" />
          <img v-else-if="menuOpen && !$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-up-black-small.svg" />
          <img v-else-if="!menuOpen && !$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-down-black-small.svg" />
        </button>
      </template>
      <v-list>
        <div :class="{ 'current-item': true, dark: $vuetify.theme.dark }">
          <span style="font-weight: 600; font-size: 10px; line-height: 14px">{{ currentOption }}</span>
          <span style="font-weight: 400; font-size: 12px">{{ currentPeriod }}</span>
        </div>

        <div
          :class="{ item: true, dark: $vuetify.theme.dark, select: isFocus(option) }"
          @click="clickOption"
          v-for="(option, index) in options"
          :key="index"
        >
          {{ option }}
          <img v-if="isFocus(option)" src="@/assets/check.svg" style="width: 10px" />
        </div>
        <div :class="{ item: true, dark: $vuetify.theme.dark, select: customSelect }" @click="clickCustomOption">
          Custom <img v-if="customSelect" src="@/assets/check.svg" style="width: 10px" />
        </div>
      </v-list>
    </v-menu>
    <div v-if="openCalender" class="calendar-wrapper" @click="clickCalendar" ref="menuRef">
      <Calendar @updateCustomDate="updateCustomDate" :minDate="minDate" @closeCalendar="closeCalendar" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import Theme from '@/mixin/theme.mixin'
import Calendar from '@item/calendar/DefaultCalendar'
import MonthCalendar from '@common/AnalyticsToolbar/AnalyticsMonthCalendar'

export default {
  components: { Calendar, MonthCalendar },
  mixins: [Theme],
  data() {
    return {
      closeOnClick: true,
      menuOpen: false,
      currentDisplay: 'Last 7 days',
      currentOption: 'Last 7 days',
      currentPeriod: `${moment().subtract(6, 'days').format('YYYY-MM-DD')} ~ ${moment().format('YYYY-MM-DD')}`,
      openCalender: false,
      options: ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days'],
    }
  },

  computed: {
    ...mapState({
      contractStartDate: (state) => state.user.contract_start,
    }),
    minDate() {
      const contractDate = moment(this.contractStartDate).subtract(1, 'days')
      const beforeSixMonth = moment().subtract(6, 'months').subtract(1, 'days')
      const dateDiff = contractDate.diff(beforeSixMonth, 'days')
      return dateDiff > 0 ? contractDate : beforeSixMonth
    },
    customSelect() {
      if (this.currentOption === 'Custom') return true
      else return false
    },
  },

  methods: {
    closeCalendar() {
      this.openCalender = false
      this.closeOnClick = true
    },
    isFocus(option) {
      if (this.currentOption === option) return true
      else return false
    },
    closeMenu(event) {
      if (event.target) {
        this.openCalender = false
        this.menuOpen = false
        this.closeOnClick = true
      }
    },
    updateCustomDate(range) {
      let payload = {
        start_time: [],
        end_time: [],
        time_zone: moment.tz.guess(),
      }
      const diffDays = range.endDate.diff(range.startDate, 'days')

      payload.start_time = [
        range.startDate
          .clone()
          .subtract(diffDays + 1, 'days')
          .startOf('date')
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        range.startDate.clone().startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
      ]
      payload.end_time = [
        range.startDate.clone().subtract(1, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
        range.endDate.endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
      ]

      this.$store.dispatch('analytics/setOverviewFireEventsAction', payload)
      this.$store.dispatch('analytics/setOverviewFireActivityGraphAction', {
        start_time: payload.start_time[1],
        end_time: payload.end_time[1],
        time_zone: payload.time_zone,
      })
      this.$store.dispatch('analytics/setOverviewMostActiveAreaAction', {
        start_time: payload.start_time[1],
        end_time: payload.end_time[1],
        rank: 5,
      })

      this.currentOption = 'Custom'
      const selectRangeText = `${range.startDate.format('YYYY-MM-DD')} ~ ${range.endDate.format('YYYY-MM-DD')}`
      this.currentDisplay = selectRangeText
      this.currentPeriod = selectRangeText

      this.$store.dispatch('analytics/setOverviewSelectedDateRangeTextAction', 'Custom : ' + this.currentPeriod)
      this.openCalender = false
      this.menuOpen = false
    },

    clickCalendar(event) {
      event.stopPropagation()
      return
    },
    clickOption(event) {
      const option = event.target.innerText
      // OVERVIEW: fire events
      let payload = {
        start_time: [],
        end_time: [],
        time_zone: moment.tz.guess(),
      }
      switch (option) {
        case 'Today': {
          const now = moment()
          this.currentPeriod = now.format('YYYY-MM-DD')
          // OVERVIEW: fire events
          payload.start_time = [
            moment().subtract(1, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          payload.end_time = [
            moment().subtract(1, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]

          this.$store.dispatch('analytics/setOverviewSelectedDateRangeTextAction', 'Today : ' + this.currentPeriod)
          break
        }
        case 'Yesterday': {
          const now = moment()
          this.currentPeriod = now.subtract(1, 'days').format('YYYY-MM-DD')
          // OVERVIEW: fire events
          payload.start_time = [
            moment().subtract(2, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().subtract(1, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          payload.end_time = [
            moment().subtract(2, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().subtract(1, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          this.$store.dispatch('analytics/setOverviewSelectedDateRangeTextAction', 'Yesterday : ' + this.currentPeriod)
          break
        }
        case 'Last 7 days': {
          const start = moment().subtract(6, 'days').format('YYYY-MM-DD')
          const end = moment().format('YYYY-MM-DD')
          this.currentPeriod = `${start} ~ ${end}`
          // OVERVIEW: fire events
          payload.start_time = [
            moment().subtract(13, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().subtract(6, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          payload.end_time = [
            moment().subtract(7, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          this.$store.dispatch('analytics/setOverviewSelectedDateRangeTextAction', 'Last 7 days : ' + this.currentPeriod)
          break
        }
        case 'Last 30 days': {
          const start = moment().subtract(30, 'days').format('YYYY-MM-DD')
          const end = moment().format('YYYY-MM-DD')
          this.currentPeriod = `${start} ~ ${end}`
          // OVERVIEW: fire events
          payload.start_time = [
            moment().subtract(60, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().subtract(29, 'days').startOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]
          payload.end_time = [
            moment().subtract(30, 'days').endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
            moment().endOf('date').format('YYYY-MM-DDTHH:mm:ssZ'),
          ]

          this.$store.dispatch('analytics/setOverviewSelectedDateRangeTextAction', 'Last 30 days : ' + this.currentPeriod)
          break
        }
      }
      this.$store.dispatch('analytics/setOverviewFireEventsAction', payload)
      this.$store.dispatch('analytics/setOverviewFireActivityGraphAction', {
        start_time: payload.start_time[1],
        end_time: payload.end_time[1],
        time_zone: payload.time_zone,
      })
      this.$store.dispatch('analytics/setOverviewMostActiveAreaAction', {
        start_time: payload.start_time[1],
        end_time: payload.end_time[1],
        rank: 5,
      })
      this.menuOpen = false
      this.openCalender = false
      this.currentOption = option
      this.currentDisplay = option
    },
    clickCustomOption(event) {
      event.stopPropagation()
      this.openCalender = !this.openCalender
      this.closeOnClick = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  width: 250px;
  height: 36px;
  text-align: start;
  padding-left: 16px;
  padding-right: 13px;
  background-color: var(--f-light-gray-1);
  display: flex;
  align-items: center;
  &.dark {
    background-color: var(--f-bg-middle);
  }
  img {
    margin-left: auto;
  }
}

.calendar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  .background-calendar {
    transform: translate(115%, 55%);
  }
}

.current-item {
  width: 180px;
  overflow: hidden;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  &.dark {
    border-bottom: 1px solid var(--f-bg-high);
  }
}

.item {
  display: flex;
  margin: 4.5px 0;
  padding-left: 20px;
  padding-right: 23px;
  height: 28px;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  &.select {
    color: var(--f-primary-90);
  }
}
.item:hover {
  background-color: var(--f-primary-50);
}
</style>
