import { v4 as uuid } from 'uuid'

function getItem(key, isSession) {
  let item
  if (isSession) {
    item = sessionStorage.getItem(key)
  } else {
    item = localStorage.getItem(key)
  }
  try {
    item = item && JSON.parse(item)
  } catch (e) {}
  return item
}

function setItem(key, value, isSession) {
  try {
    value = JSON.stringify(value)
  } catch (e) {}
  if (isSession) {
    sessionStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, value)
  }
}

/**
 *
 * @public
 * @param {string} key
 * @param {function} callback
 * @param {boolean} [isSession] for usage only web
 * @returns {string}
 */
function getData(key, callback, isSession) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key }
    if (!mobileCallFuncName('getData', params, callback)) {
      callback && callback(JSON.parse(sessionStorage.getItem(key)))
    }
  } else {
    callback && callback(getItem(key, isSession))
  }
}

/**
 *
 * @public
 * @param {string} key
 * @param {string} value
 * @param {boolean} [isSession] for usage only web
 */
function setData(key, value, isSession) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key, value: stringifyValue(value) }
    if (!mobileCallFuncName('setData', params)) {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  } else {
    setItem(key, value, isSession)
  }
}

/**
 *
 * @public
 * @param {string} key
 * @param {boolean} [isSession] for usage only web
 */
function removeData(key, isSession) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key }
    if (!mobileCallFuncName('removeData', params)) {
      sessionStorage.removeItem(key)
    }
  } else {
    if (isSession) {
      sessionStorage.removeItem(key)
    } else {
      localStorage.removeItem(key)
    }
  }
}

/**
 *
 * @public
 * @param {boolean} [isSession] for usage only web
 */
function clearData(isSession) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid() }
    if (!mobileCallFuncName('clearData', params)) {
      sessionStorage.clear()
    }
  } else {
    if (isSession) {
      sessionStorage.clear()
    } else {
      localStorage.clear()
    }
  }
}

/**
 *
 * @public
 * @param {string} key
 * @param {function} callback
 * @returns {string}
 */
function getEncryptedData(key, callback) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key }
    if (!mobileCallFuncName('getEncryptedData', params, callback)) {
      callback && callback(JSON.parse(sessionStorage.getItem(key)))
    }
  } else {
    throw 'not support'
  }
}

/**
 *
 * @public
 * @param {string} key
 * @param {string} value
 */
function setEncryptedData(key, value) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key, value: stringifyValue(value) }
    if (!mobileCallFuncName('setEncryptedData', params)) {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  } else {
    throw 'not support'
  }
}

/**
 *
 * @public
 * @param {string} key
 */
function removeEncryptedData(key) {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid(), key }
    if (!mobileCallFuncName('removeEncryptedData', params)) {
      sessionStorage.removeItem(key)
    }
  } else {
    throw 'not support'
  }
}

/**
 *
 * @public
 */
function clearEncryptedData() {
  if (process.env.VUE_APP_MOBILE) {
    const params = { id: uuid() }
    if (!mobileCallFuncName('clearEncryptedData', params)) {
      sessionStorage.clear()
    }
  } else {
    throw 'not support'
  }
}

/**
 *
 * @public
 * @param {string} command
 * @param {(object|null)} params
 * @param {function} callback
 * @returns {(object|null)}
 */
function call(command, params, callback) {
  if (process.env.VUE_APP_MOBILE) {
    mobileCall({ id: uuid(), command, ...params }, callback)
  } else {
    console.log('call not support')
  }
}

const callbackMap = new Map()

/**
 * @private
 * @param {object} params
 * @param {function} [callback]
 */
function mobileCall(params, callback) {
  callback && callbackMap.set(params.id, callback)
  if (window.webkit && window.webkit.messageHandler) {
    // iOS
    window.webkit.messageHandler['nativeservice'].postMessage(JSON.stringify(params))
  } else {
    // Android
    window['nativeservice'] && window['nativeservice']['call'] && window['nativeservice']['call'](JSON.stringify(params))
  }
}

/**
 * @private
 * @param {string} name
 * @param {object} params
 * @param {function} [callback]
 */
function mobileCallFuncName(name, params, callback) {
  if (window['nativeservice'] && window['nativeservice'][name]) {
    callback && callbackMap.set(params.id, callback)
    window['nativeservice'][name](JSON.stringify(params))
    return true
  }
  return false
}

function stringifyValue(value) {
  if (value !== null && value !== undefined) {
    if (typeof value === 'object') {
      return JSON.stringify(value)
    } else {
      return value
    }
  } else {
    return ''
  }
}

/**
 * Mobile Callback
 * @private
 * @param {object} event
 */
window.receive = function (event) {
  console.log('NativeService received from Native:', event)
  if (callbackMap.has(event.id)) {
    const callback = callbackMap.get(event.id)
    callbackMap.delete(event.id)
    callback()
  }
}

export default {
  getData,
  setData,
  removeData,
  clearData,
  getEncryptedData,
  setEncryptedData,
  removeEncryptedData,
  clearEncryptedData,
  call,
}
