import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import locales from '../locales'
import NativeService from '@/native-service'
import Constants from '@/constants'

Vue.use(Vuetify)

let language
NativeService.getData('lang', (lang) => {
  language = lang || 'ko'
})

let themeDark
NativeService.getData(Constants.THEME_DARK, (dark) => {
  themeDark = dark || false
})

export default new Vuetify({
  theme: {
    dark: themeDark,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#3D51FB',
        secondary: '#34C759',
        accent: '#82B1FF',
        error: '#FF3B30',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#363636',
        background: '#FFFFFF',
        // custom variants
        backgroundSearch: '#F9F9F9',
        backgroundLine: '#CCCCCC',
        backgroundAside: '#E9EBFF',
        backgroundSite: '#f3f3f9',
        backgroundAsideHeader: '#E9EBFF',
        backgroundAsideHeaderRecent: '#FFFFFF',
        backgroundAsideHeaderRecentBorder: '#d3d5dd',
        backgroundAsideHeaderRecentLabel: '#e9ebff',
        backgroundAsideTooltip: '#FFFFFF',
        backgroundButtonCancel: '#D8D9DA',
        backgroundDate: '#F9F9F9',
        backgroundPlayer: '#000000',
        backgroundMonitorSplitLine: '#d3d5dd',
        backgroundMonitorSplitLine2: '#eeeeee',
        backgroundLinkGotoBox: '#E9EBFF',
        backgroundAsideRecentSpace: '#F7F7F7',
        backgroundAlertOff: '#6D7DFF',
        backgroundMap: '#8D90A2',
        backgroundMapDirection: '#FF6A6A',
        borderSearch: '#F3F3F9',
        borderToolbarBottom: '#d3d5dd',
        borderLineBottom: '#F7F7F7',
        borderLineBottom1: '#F7F7F7',
        borderWidthSearch: '1px',
        borderLabel: '#D3D5DD',
        backgroundFire: '#FF6A6A',
        backgroundNoneFire: '#74E08F',
        backgroundUnknown: '#528CFF',
        borderAlertOff: 'D3D5DD',
        textDefault50: '#363636',
        textButtonCancel: '#43425D',
        textCalendarSun: '#FF6A6A',
        textCalendarSat: '#528CFF',
      },
      dark: {
        primary: '#3D51FB',
        secondary: '#34C759',
        accent: '#82B1FF',
        error: '#ff3b30',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#E6E6E6',
        background: '#1B1C22',
        // custom variants
        backgroundSearch: '#25262C',
        backgroundLine: '#666666',
        backgroundAside: '#25262C',
        backgroundSite: '#25262C',
        backgroundAsideHeader: '#25262C',
        backgroundAsideHeaderRecent: '#25262C',
        backgroundAsideHeaderRecentBorder: '#25262C',
        backgroundAsideHeaderRecentLabel: '#323340',
        backgroundAsideTooltip: '#323340',
        backgroundButtonCancel: '#323340',
        backgroundDate: '#323340',
        backgroundPlayer: '#25262C',
        backgroundMonitorSplitLine: '#1B1C22',
        backgroundMonitorSplitLine2: '#1B1C22',
        backgroundLinkGotoBox: '#323340',
        backgroundAsideRecentSpace: '#1B1C22',
        backgroundAlertOff: '#3D51FB',
        backgroundMap: '#25262C',
        backgroundMapDirection: '#FF5A5A',
        borderSearch: '#000000',
        borderToolbarBottom: '#3D3A41',
        borderLineBottom: '#1B1C22',
        borderLineBottom1: '#3D3A41',
        borderWidthSearch: '0px',
        borderLabel: '#DDDDDE',
        backgroundFire: '#FF5A5A',
        backgroundNoneFire: '#42E081',
        backgroundUnknown: '#619CF4',
        borderAlertOff: '#25262c',
        textDefault50: '#666666', // white 60
        textButtonCancel: '#E6E6E6',
        textCalendarSun: '#FF5A5A',
        textCalendarSat: '#619CF4',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    current: language,
    locales: locales,
  },
})
