import Vue from 'vue'
import VueRouter from 'vue-router'
import Constants from '@/constants'
import { auth } from '@/api'
import store from '@/store'

//  DESKTOP

// analytics
// const AnalyticsOverview = () => import('../views/desktop/adminOrViewer/AnalyticsOverview.vue')
// const AnalyticsFrequency = () => import('../views/desktop/adminOrViewer/AnalyticsFrequency.vue')
// const AnalyticsCamera = () => import('../views/desktop/adminOrViewer/AnalyticsCamera.vue')

// common
const SignIn = () => import('../views/desktop/common/SignIn.vue')
// admin or viewer
const MapView = () => import('../views/desktop/adminOrViewer/MapView.vue')
const CameraList = () => import('../views/desktop/adminOrViewer/CameraList.vue')
const Detection = () => import('../views/desktop/adminOrViewer/Detection.vue')
const History = () => import('../views/desktop/adminOrViewer/History.vue')
const Monitor = () => import('../views/desktop/adminOrViewer/Monitor')
const Support = () => import('../views/desktop/adminOrViewer/Support')
const SupportSuccess = () => import('../views/desktop/adminOrViewer/SupportSuccess')
// superadmin or worker
const Workspace = () => import('../views/desktop/superadminOrWorker/Workspace')

// MOBILE
const AlertMobile = () => import('../views/mobile/Alert.vue')
const SignInMobile = () => import('../views/mobile/SignIn.vue')

Vue.use(VueRouter)

/**
 * 브라우저 새로 고침의 경우엔 sessionStorage에 token정보가 js가 메모리에 가지고 있는 정보는 모두 사라진다.
 * 때문에 동적 라우팅을 적용하고 있는 현재 시스템에 문제가 생길 수 있기 때문에 사용자 정보가 있는지 확인 후 새롭게 불러오는 작업이 필요하다.
 */
async function checkSelfInfo() {
  if (auth.isAuth() && !store.state.user.organization_name) {
    await auth.getSelfInfo()
  }
}

async function guardWorkspace(to, from, next) {
  await checkSelfInfo()
  const role = store.state.user.user_role
  const org = store.state.user.organization_name
  Vue.$log.debug('guardWorkspace', to.path, from.path, role, org)
  if (
    role &&
    (role.includes(Constants.ROLE_WORKER) ||
      role.includes(Constants.ROLE_SUPER) ||
      role.includes(Constants.ROLE_OBSERVER) ||
      role.includes(Constants.ROLE_EDITOR))
  ) {
    const tab = to.params.tab
    const name = to.params.name
    const logId = to.params.logId
    const workspace = to.params.workspace || Constants.CONFIRMED
    if (tab && [Constants.CONFIRMED, Constants.SKIPPED, Constants.SKIPPED_HARD, Constants.SETTINGS].includes(tab)) {
      next()
    } else if (to.path === '/workspace') {
      next()
    } else if (logId) {
      next(`/workspace/${workspace}/${name}/${logId}`)
    } else if (name) {
      next(`/workspace/${workspace}/${name}`)
    } else {
      next('/workspace')
    }
  } else if (role && (role.includes(Constants.ROLE_ADMIN) || role.includes(Constants.ROLE_VIEWER))) {
    next('/detection')
  } else {
    processLogin(to, next)
  }
}

async function guardUserRole(to, from, next) {
  await checkSelfInfo()
  const role = store.state.user.user_role
  const org = store.state.user.organization_name
  Vue.$log.debug('guardUserRole', to.path, from.path, role, org)
  if (role && ((!role.includes(Constants.ROLE_SUPER) && role.includes(Constants.ROLE_ADMIN)) || role.includes(Constants.ROLE_VIEWER))) {
    next()
  } else if (
    role &&
    (role.includes(Constants.ROLE_WORKER) ||
      role.includes(Constants.ROLE_SUPER) ||
      role.includes(Constants.ROLE_OBSERVER) ||
      role.includes(Constants.ROLE_EDITOR))
  ) {
    if (to.path.includes('workspace')) {
      next()
    } else {
      next('/workspace')
    }
  } else {
    processLogin(to, next)
  }
}

function processLogin(to, next) {
  auth.init()
  if (to.fullPath.includes('/m/')) {
    next(to.fullPath && to.fullPath.length > 3 ? `/m/login?redirect=${to.fullPath}` : '/m/login')
  } else {
    next(to.fullPath && to.fullPath.length > 1 ? `/login?redirect=${to.fullPath}` : '/login')
  }
}

const routes = [
  // {
  //   path: '/analytics-overview',
  //   component: AnalyticsOverview,
  //   meta: {
  //     auth: true,
  //   },
  // },
  // {
  //   path: '/analytics-frequency',
  //   component: AnalyticsFrequency,
  //   meta: {
  //     auth: true,
  //   },
  // },
  // {
  //   path: '/analytics-camera',
  //   component: AnalyticsCamera,
  //   meta: {
  //     auth: false,
  //   },
  // },
  {
    path: '',
    meta: {
      auth: true,
    },
    redirect: '/detection',
  },
  {
    path: '/login',
    component: SignIn,
    meta: {
      auth: false,
    },
  },
  {
    path: '/m/login',
    component: SignInMobile,
    meta: {
      auth: false,
    },
  },
  {
    path: '/mapview',
    component: MapView,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/detection',
    component: Detection,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/m/detection',
    component: AlertMobile,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/history',
    component: History,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/cameralist',
    component: CameraList,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/support-submit',
    component: Support,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: '/support-submit-success',
    component: SupportSuccess,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: `/monitor`,
    component: Monitor,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      /*
       * 모니터링 화면의 경우 소리가 나오며 소리와 같은 사용자 액션은 사용자 인터랙션이 있어야지만 조작이 가능하다.(브라우저 정책)
       * 그렇기 때문에 바로 모니터링 화면에 진입을 못하도록 하기 위해서 최초 브라우저 입력으로 들어오는지 확인하기 위해서 fullPath를 확인한다.
       * fullPath는 브라우저 최초 진입 시는 '/'이기 때문이다.
       */
      if (from.fullPath.length > 1) {
        next()
      } else {
        await checkSelfInfo()
        const org = store.state.user.organization_name
        if (org) {
          next(`/detection`)
        } else {
          next('/login')
        }
      }
    },
  },
  {
    path: `/workspace`,
    component: Workspace,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardWorkspace(to, from, next)
    },
  },
  {
    path: `/workspace/:tab`,
    component: Workspace,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardWorkspace(to, from, next)
    },
  },
  {
    path: `/workspace/:tab/:name`,
    component: Workspace,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardWorkspace(to, from, next)
    },
  },
  {
    path: `/workspace/:tab/:name/:logId`,
    component: Workspace,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardWorkspace(to, from, next)
    },
  },
  {
    path: `/workspace/:tab/:name/:logId`,
    component: Workspace,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardWorkspace(to, from, next)
    },
  },
  /**
   * Mobile route, 화면 고도화가 안되었기 때문에 이전 버전 그대로 사용
   */
  {
    path: `/m/:organization`,
    component: AlertMobile,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: `/m/:organization/:name`,
    component: AlertMobile,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: `/m/:organization/:name/:logId`,
    component: AlertMobile,
    props: true,
    meta: {
      auth: true,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  {
    path: `/m/:organization/:name/:logId/:workspace`,
    component: AlertMobile,
    props: true,
    meta: {
      auth: false,
    },
    beforeEnter: async (to, from, next) => {
      await guardUserRole(to, from, next)
    },
  },
  /**
   * Old path redirect, 이전 버전의 라우팅 최신 버전으로 변경
   */
  {
    // deprecated path --> /detection
    path: `/:organization`,
    redirect: '/detection',
  },
  {
    // deprecated path --> /detection
    path: `/:organization/:name`,
    redirect: (to) => {
      return `/detection?name=${to.params.name}`
    },
  },
  {
    // deprecated path --> /detection
    path: `/:organization/:name/:logId`,
    redirect: (to) => {
      return `/detection?name=${to.params.name}&id=${to.params.logId}`
    },
  },
  {
    // deprecated path --> /detection
    path: `/:organization/:name/:logId/:workspace`,
    redirect: (to) => {
      return `/detection?name=${to.params.name}&id=${to.params.logId}`
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_MOBILE ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
})

setImmediate(() => {
  router.beforeResolve(auth.navigationGuard)
})

export default router
