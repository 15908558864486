import Vue from 'vue'
import Vuex from 'vuex'
import { workspace, alert, auth } from '../api'
import Constants from '@/constants'
import superadmin from './superadmin'
import analytics from './analytics'
import detectionPageAction from './detectionPageAction'
import moment from 'moment'
import MapviewToastContent from '@/components/common/MapviewToastContent.vue'
// import WorkspaceToastContent from '@/components/common/WorkspaceToastContent.vue'
import ToastCloseIconDark from '@/components/common/ToastCloseIconDark.vue'
import ToastCloseIconLight from '@/components/common/ToastCloseIconLight.vue'
import util from '@/util'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: { user_name: '' },
    workspaceTabIndex: 0,
    workspaceCameraLogsMap: {},
    /**
     * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop#getcameras
     */
    camList: [],
    camMap: {},
    currentLog: null,
    logInfo: null,
    currentCam: null,
    /**
     * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop#getworkedlog
     */
    logListv2: [],
    logMap: {},
    /**
     * @see https://github.com/AlcheraInc/VADT_Mointor_API/tree/develop#getworkedlogrecent
     */
    recentLogList: [],
    logChildren: {},
    recentLogListv2: [],
    isLogListLoaded: false,
    mapviewExpandedAccordionLogId: -1,
    mapViewSelectedLog: null,
    ArcGISMapInstance: null,
    mapViewFilter: {
      detectionType: {
        allDetection: false,
        firstDetection: true,
      },
      camera: {
        allCamera: true,
        alertCamera: false,
        noCamera: false,
      },
      detectionWithin: {
        dateObj: moment().subtract(72, 'hours').startOf('day'),
        isCustom: false,
        selectedHoursFilter: 72,
      },
      responseStatus: {
        alerted: true,
        validated: true,
        responded: true,
      },
      eventType: {
        fire: true,
        nonFire: true,
        unknown: true,
        plannedBurn: true,
        unselected: true,
      },
      pfirsData: {
        allBurns: true,
        noBurns: false,
      },
    },
    showMapViewResponseStatusPopover: false,
    isMapviewAlertOff: false,
    /**
     * [{
            "id": 1,
            "name": "jh.kang",
            "email": "jh.kang@alcherainc.com",
            "allow_email": true,
            "country_code": "+082",
            "phone_number": "1024713404",
            "allow_sms": true,
        }]
     */
    eventTypeList: [
      { id: 0, name: '' },
      { id: 1, name: 'Unselected' },
      { id: 2, name: 'Fire' },
      { id: 3, name: 'Non-Fire' },
      { id: 4, name: 'Unknown' },
      { id: 5, name: 'Planned Burn' },
    ],
    adminSoundSetting: false,
    networkError: false,
    networkErrorCode: '',
    GeoJSONUrl: '',
    // History Page variables
    historyViewSelectedDateRange: [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    historyViewSelectedDateList: [],
  },
  mutations: {
    setArcGISMapInstance(state, ref) {
      state.ArcGISMapInstance = ref
    },
    currentLogMutation(state, newLog) {
      state.currentCam = null
      state.currentLog = newLog
    },
    setCurrentCamByName(state, cameraName) {
      state.currentLog = null
      if (cameraName) state.currentCam = state.camList.find((cam) => cam.name === cameraName)
      else state.currentCam = state.camList[0]
    },
    setIsLogListLoadedMutation(state, flag) {
      state.isLogListLoaded = flag
    },
    setMapViewFilterMutation(state, filter) {
      state.mapViewFilter = filter
    },
    setMapviewAlertToggleMutation(state) {
      state.isMapviewAlertOff = !state.isMapviewAlertOff
    },
    setUser(state, info) {
      if (info.user && info.organization) {
        /*
         * new format: convert from new format to old format
         * {"user": {"name": "", "role": "ADMIN", "id": 1}, "organization": {"name": "Alchera", "contract_type": "POC", "contract_start": "2020-07-07T02:19:57Z","contract_end": "2020-07-07T02:19:57Z","history_start": "2020-07-07T02:19:57Z","history_end": "2020-07-07T02:19:57Z"}}
         */
        const user = info.user
        const organization = info.organization
        state.user = {
          id: user.id,
          user_name: user.name,
          user_role: user.role,
          organization_name: organization.name,
          contract_type: organization.contract_type,
          contract_start: organization.contract_start,
          history_start: organization.history_start,
        }
      } else {
        /*
         * old format
         * {"user_name":"alchera","user_role":"SUPER ADMIN","organization_name":"alchera"}
         */
        info.id = 1 // default
        state.user = info
      }
      state.user.isAdmin =
        state.user.user_role &&
        (state.user.user_role.includes(Constants.ROLE_ADMIN) ||
          state.user.user_role.includes(Constants.ROLE_OBSERVER) ||
          state.user.user_role.includes(Constants.ROLE_EDITOR))
      state.user.isWorker =
        state.user.user_role &&
        (state.user.user_role.includes(Constants.ROLE_WORKER) ||
          state.user.user_role.includes(Constants.ROLE_SUPER) ||
          state.user.user_role.includes(Constants.ROLE_OBSERVER) ||
          state.user.user_role.includes(Constants.ROLE_EDITOR))
      state.user.isViewer = !state.user.user_role || state.user.user_role.includes(Constants.ROLE_VIEWER) || state.user.isWorker
    },
    setCamList(state, payload) {
      if (payload.length) {
        payload.sort((a, b) => (a.name < b.name ? -1 : 1))
        state.camList = payload
      } else {
        state.camList = []
      }
      state.camList.forEach((cam) => {
        cam.logs = []
        state.camMap[cam.id] = cam
      })
      state.camMap = { ...state.camMap }
    },
    setLogListv2(state, payload) {
      if (payload.length) {
        payload.sort((a, b) => a.log_id - b.log_id)
        payload.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
        state.logListv2 = payload
      } else state.logListv2 = []
    },
    setLogListv2InMapview(state, payload) {
      if (payload.logs.length) {
        let logMap = payload.logListMap
        payload.logs.forEach((log) => {
          logMap[log.log_id] = { ...logMap[log.log_id], ...log }
        })
        const sortedByIdLogList = Object.values(logMap)
        const sortedByTimestamp = sortedByIdLogList.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
        state.logListv2 = sortedByTimestamp
      } else {
        state.logListv2 = []
      }
    },
    setLogInfoInLogList(state, payload) {
      const matchedLogIndex = state.logList.findIndex((log) => {
        return log.log_id === payload.log_id
      })
      state.logList[matchedLogIndex] = payload
    },
    setWorkspaceCameraLogsMap(state, payload) {
      state.workspaceCameraLogsMap[payload.cameraId] = payload.logs.reverse()
    },
    emptyWorkspaceCameraLogsMapMutation(state) {
      state.workspaceCameraLogsMap = {}
    },
    setRecentLogList(state, payload) {
      if (payload.length) {
        payload.sort((a, b) => a.log_id - b.log_id)
        state.recentLogList = payload.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
      } else {
        state.recentLogList = []
      }
    },
    setRecentLogListInWorkspace(state, payload) {
      if (payload.length) {
        payload.sort((a, b) => a.log_id - b.log_id)
        const mostRecentFirstLogs = payload.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
        state.recentLogList = mostRecentFirstLogs
      } else {
        state.recentLogList = []
      }
    },
    setRecentLogListInMapview(state, payload) {
      if (payload.logs.length) {
        let logMap = payload.recentLogMap
        payload.logs.forEach((log) => {
          logMap[log.log_id] = { ...logMap[log.log_id], ...log }
        })

        const fullInfoLogList = Object.values(logMap).sort((a, b) => a.log_id - b.log_id)

        const mostRecentFirstLogs = fullInfoLogList.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()

        // get list of previous logs that do not have latitude/longitude/pan_direction values
        const noFireInfoLogs = state.recentLogList.filter((log) => log.latitude === null || log.longitude === null || log.pan_direction === null)

        // get list of new logs that got fire location/direction values immediately when API is called
        const prevRecentLogListIDs = state.recentLogList.length ? state.recentLogList.map((log) => log.log_id) : []
        const hasFireInfoImmediatelyLogs = state.recentLogList.length
          ? mostRecentFirstLogs.filter(
              (log) => !prevRecentLogListIDs.includes(log.log_id) && log.latitude !== null && log.longitude !== null && log.pan_direction !== null
            )
          : []

        const toMakeToastLogsList = noFireInfoLogs.concat(hasFireInfoImmediatelyLogs)

        if ([Constants.ROLE_ADMIN, Constants.ROLE_VIEWER].includes(state.user.user_role) && toMakeToastLogsList.length) {
          const toMakeToastLogsListIDs = toMakeToastLogsList.map((l) => l.log_id)
          const updatedWithFireInfoLogs = mostRecentFirstLogs.filter(
            (log) =>
              toMakeToastLogsListIDs.includes(log.log_id) &&
              log.latitude !== null &&
              log.longitude !== null &&
              log.pan_direction !== null &&
              !util.isDuplicateLog(log)
          )

          // create toast for each updated log with fire info
          updatedWithFireInfoLogs.forEach((log) => {
            Vue.$toast(
              {
                component: MapviewToastContent,
                props: {
                  cameraName: log.camera_name,
                  log,
                  fireCoordinatesFound: log.latitude !== null && log.longitude !== null && log.latitude !== -1 && log.longitude !== -1,
                  fireDirectionFound: log.pan_direction !== null && log.pan_direction !== -1,
                },
                listeners: {
                  'check-toast': (toastLog) => {
                    state.mapViewSelectedLog = toastLog
                  },
                },
              },
              {
                bodyClassName: `mapview-toast-body ${payload.themeName}`,
                closeButton: payload.themeName === 'dark' ? ToastCloseIconLight : ToastCloseIconDark,
                toastClassName: `mapview-toast-container ${payload.themeName}`,
              }
            )
          })
        }
        state.recentLogListv2 = payload.logs
        state.recentLogList = mostRecentFirstLogs
      } else {
        state.recentLogList = []
      }
    },
    setLogChildren(state, obj) {
      state.logChildren = {}
      state.logChildren[obj.logId] = obj.logs.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
    },
    setMapviewExpandedAccordionLogIdMutation(state, logId) {
      state.mapviewExpandedAccordionLogId = state.mapviewExpandedAccordionLogId === logId ? -1 : logId
    },
    setWorkspaceTabIndex(state, tabIndex) {
      state.workspaceTabIndex = tabIndex
    },
    setAdminSoundSetting(state, sound) {
      state.adminSoundSetting = sound
    },
    /*
     * {
     *    flag: true,
     *    errorCode: 'FIRE-F1201',
     * }
     */
    setNetworkError(state, obj) {
      state.networkError = obj.flag
      state.networkErrorCode = obj.flag ? obj.errorCode : ''
    },
    setShowMapViewResponseStatusPopoverMutation(state, flag) {
      state.showMapViewResponseStatusPopover = flag
    },
    setMapViewSelectedLogMutation(state, log) {
      if (!!log?.log_id && !!state.mapViewSelectedLog?.log_id && log.log_id === state.mapViewSelectedLog.log_id) {
        state.mapViewSelectedLog = { ...state.mapViewSelectedLog, ...log }
      } else {
        state.mapViewSelectedLog = log
      }
    },
    setLogInfoMutation(state, log) {
      state.logInfo = log
    },
    async setRecentLogListv2(state, payload) {
      if (payload.length) {
        payload.sort((a, b) => a.log_id - b.log_id)
        state.recentLogListv2 = payload.sort((a, b) => (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)).reverse()
      } else {
        state.recentLogListv2 = []
      }
    },
    setHistoryViewSelectedDateRangeMutation(state, dateList) {
      state.historyViewSelectedDateRange = dateList
    },
    setHistoryViewSelectedDateListMutation(state, dateList) {
      state.historyViewSelectedDateList = dateList
    },
  },
  actions: {
    setWorkspaceTabIndexAction({ commit }, payload) {
      commit('setWorkspaceTabIndex', payload)
    },
    setMapViewFilterAction({ commit }, payload) {
      commit('setMapViewFilterMutation', payload)
      commit('setIsLogListLoadedMutation', true)
    },
    async setLogDetectionTypeAction({ commit }, payload) {
      try {
        const { data } = await alert.updateDetectionType(payload)
        return data
      } catch (e) {
        Vue.$log.debug('setLogDetectionTypeAction catch')
        printError(e)
      }
    },
    async setLogResponseStatusAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setLogResponseStatusAction payload', payload)
      try {
        await alert.setResponseStatus(payload)
      } catch (e) {
        Vue.$log.debug('setLogResponseStatusAction catch')
        printError(e)
      }
    },
    async setLogEventTypeAction({ commit }, payload) {
      // Vue.$log.debug('STORE#setLogResponseStatusAction payload', payload)
      try {
        await alert.setEventType(payload)
      } catch (e) {
        Vue.$log.debug('setLogEventTypeAction catch')
        printError(e)
      }
    },
    async getLogChildrenAction({ commit }, logId) {
      try {
        const { data } = await alert.getChildLogs(logId)
        if (!!data && !!data.logs) {
          commit('setLogChildren', { logId, logs: data.logs })
        }
        return data.logs
      } catch (e) {
        Vue.$log.debug('getLogChildrenAction catch')
        printError(e)
      }
    },
    setMapviewExpandedAccordionLogIdAction({ commit }, logId) {
      commit('setMapviewExpandedAccordionLogIdMutation', logId)
    },
    setMapviewAlertToggleAction({ commit }) {
      commit('setMapviewAlertToggleMutation')
    },
    async setShowMapViewResponseStatusPopoverAction({ commit }, flag) {
      commit('setShowMapViewResponseStatusPopoverMutation', flag)
    },
    async setMapViewSelectedLogAction({ commit }, log) {
      commit('setMapViewSelectedLogMutation', log)
    },
    async setMapViewSelectedLogParentAction({ commit }, logId) {
      try {
        const { data } = await alert.getLog(logId)
        if (!!data && !!data.info) {
          commit('setMapViewSelectedLogMutation', data.info)
        }
      } catch (e) {
        Vue.$log.debug('setMapViewSelectedLogParentAction catch')
        printError(e)
      }
    },
    async setMapViewSelectedLogChildAction({ commit }, logId) {
      try {
        const { data } = await alert.getLog(logId)
        if (!!data && !!data.info) {
          commit('setMapViewSelectedLogMutation', data.info)
        }
      } catch (e) {
        Vue.$log.debug('setMapViewSelectedLogChildAction catch')
        printError(e)
      }
    },
    async setNetworkErrorAction({ commit }, obj) {
      commit('setNetworkError', obj)
    },
    async setCurrentLog({ state, dispatch }, currentLog) {
      // Vue.$log.debug('STORE#setCurrentLogAction', currentLog)
      let log = currentLog
      if (!log && state.logList.length) {
        log = state.logList[0]
      }
      // try {
      //   const { data } = await alert.getLog(log.log_id)
      //   log = {
      //     ...log,
      //     ...data.info,
      //   }
      // } catch (e) {
      //   printError(e.response)
      // }
      // do not update currentLog if log info is the same
      state.currentLog = log
      state.currentCam = null
    },
    setLogInfo({ commit }, log) {
      commit('setLogInfoMutation', log)
    },
    async setCurrentCam({ state, dispatch }, currentCam) {
      // Vue.$log.debug('STORE#setCurrentCamAction', currentCam)
      state.currentCam = currentCam
      if (!currentCam && state.recentLogListv2.length) {
        const mostRecentLog = await dispatch('getLog', state.recentLogListv2[0].log_id)
        dispatch('setCurrentLog', mostRecentLog)
      } else {
        state.currentLog = null
      }
    },
    async getCamList({ commit, dispatch, state }, { tabIndex, justCamList = false } = {}) {
      const { data } = await alert.getCamList()
      commit('setCamList', data.cameras)
      if (!justCamList) {
        await dispatch('getRecentLogList', tabIndex)
      }
    },
    async getLogListByPeriodInMapview({ commit }, { inChild, inNoti, inRepOrg, inRespStat, tabIndex, startDate, endDate }) {
      try {
        const { data } = await alert.getLogList(startDate, endDate)

        let logListMap = {}
        if (!!data && !!data.logs && !!data.logs.length) {
          data.logs.forEach((log) => {
            logListMap[log.log_id] = log
          })

          try {
            const getLogPeriodResponse = await alert.getLogPeriod(inChild, inNoti, inRepOrg, inRespStat, tabIndex, startDate, endDate)

            if (!!getLogPeriodResponse && !!getLogPeriodResponse.data) {
              commit('setLogListv2InMapview', {
                logListMap,
                logs: getLogPeriodResponse.data.logs,
              })
            }
          } catch (error) {
            Vue.$log.debug('getLogListByPeriodInMapview# /log/period catch')
            printError(e.response)
          }
        }
      } catch (e) {
        Vue.$log.debug('getLogListByPeriodInMapview catch')
        printError(e.response)
      }
    },
    async getLogListByPeriodInHistory({ commit }, { inChild, inNoti, inRepOrg, inRespStat, tabIndex, startDate, endDate }) {
      try {
        const { data: logs } = await alert.getLogPeriod(inChild, inNoti, inRepOrg, inRespStat, tabIndex, startDate, endDate)
        commit('setLogListv2', logs.logs)
      } catch (error) {
        Vue.$log.debug('getLogListByPeriodInHistory catch')
        printError(e.response)
      }
    },
    async getLogList({ commit }, { startDate, endDate, tabIndex }) {
      try {
        const { data } = await alert.getLogList(startDate, endDate, tabIndex)
        commit('setLogListv2', data.logs)
      } catch (e) {
        Vue.$log.debug('getLogList catch')
        printError(e.response)
      }
    },
    async getLogListv2({ commit }, { startDate, endDate, tabIndex, workerIds, inChild, inRepOrg }) {
      try {
        const { data } = await alert.getLogListv2(startDate, endDate, tabIndex, workerIds, inChild, inRepOrg)
        commit('setLogListv2', data.logs)
        return data.logs
      } catch (e) {
        Vue.$log.debug('getLogListv2 catch')
        printError(e.response)
      }
    },
    async getLogListv2ByCameraId({ commit }, { startDate, endDate, tabIndex, workerIds, cameraId, inChild, inRepOrg }) {
      try {
        const { data } = await alert.getLogListv2ByCameraId(startDate, endDate, tabIndex, workerIds, cameraId, inChild, inRepOrg)
        commit('setWorkspaceCameraLogsMap', { cameraId: cameraId, logs: data.logs })
      } catch (e) {
        Vue.$log.debug('getLogListv2ByCameraId catch')
        printError(e.response)
      }
    },
    async emptyWorkspaceCameraLogsMap({ commit }) {
      commit('emptyWorkspaceCameraLogsMapMutation')
    },
    async getRecentLogList({ commit }, tabIndex) {
      try {
        const { data } = await alert.getRecentLogList(tabIndex)
        commit('setRecentLogList', data.logs)
        return data.logs
      } catch (e) {
        Vue.$log.debug('getRecentLogList catch')
        printError(e.response)
      }
    },
    async getRecentLogListv2({ commit }, { tabIndex, inNoti, inRepOrg, inRespStat, inChild }) {
      try {
        const { data } = await alert.getRecentLogListv2(tabIndex, inNoti, inRepOrg, inRespStat, inChild)
        commit('setRecentLogListv2', data.logs)
        return data.logs
      } catch (e) {
        Vue.$log.debug('getRecentLogListv2 catch')
        printError(e.response)
      }
    },

    async getRecentLogListInWorkspace({ commit, state }) {
      try {
        const { data } = await alert.getRecentLogList(state.workspaceTabIndex)
        commit('setRecentLogListInWorkspace', data.logs)
        return data.logs
      } catch (e) {
        Vue.$log.debug('getRecentLogListInWorkspace catch')
        printError(e.response)
      }
    },
    async getRecentLogListInMapview(
      { commit, state },
      { includeChildLogs, includeNotificationInformation, includeDuplicatesByOrganization, includeResponseStatusInformation, tabIndex, themeName }
    ) {
      try {
        // get recent log list without repetition info
        const { data } = await alert.getRecentLogList()

        let recentLogMap = {}
        if (!!data && !!data.logs && data.logs.length) {
          data.logs.forEach((log) => {
            recentLogMap[log.log_id] = log
            // remove event_type_id key due to backend integration issue
            delete recentLogMap[log.log_id].event_type_id
          })

          try {
            const v2result = await alert.getRecentLogListWithRepetitive(
              includeChildLogs,
              includeNotificationInformation,
              includeDuplicatesByOrganization,
              includeResponseStatusInformation,
              tabIndex
            )

            if (!!v2result && !!v2result.data) {
              commit('setRecentLogListInMapview', {
                recentLogMap,
                themeName,
                logs: v2result.data.logs,
              })
            }
          } catch (error) {
            Vue.$log.debug('getRecentLogListInMapview#v2 API call catch')
            printError(e.response)
          }
        }
        if (!state.isLogListLoaded) commit('setIsLogListLoadedMutation', true)
        return data.logs
      } catch (e) {
        Vue.$log.debug('getRecentLogListInMapview catch')
        printError(e.response)
      }
    },
    setIsLogListLoadedAction({ commit }, flag) {
      commit('setIsLogListLoadedMutation', flag)
    },
    async getLog({ commit }, logid) {
      try {
        const { data } = await alert.getLog(logid)
        // Vue.$log.debug('getLog', data)
        commit('setLogInfoMutation', data.info)
        return data.info
      } catch (e) {
        Vue.$log.debug('getLog catch', e)
        printError(e.response)
      }
    },
    async getLogInfo({}, logid) {
      try {
        const { data } = await alert.getLog(logid)
        // Vue.$log.debug('getLog', data)
        return data.info
      } catch (e) {
        Vue.$log.debug('getLogInfo catch', e)
        printError(e.response)
      }
    },
    async login(_, { username, password }) {
      return await auth.login(username, password)
    },
    async getSsoUrlAction() {
      try {
        const { data } = await auth.getSsoUrl()
        return data.url
      } catch (e) {
        Vue.$log.debug('getLogInfo catch', e)
        printError(e.response)
      }
    },
    logout(_) {
      return auth.logout()
    },
    async getChildLogs({}, { id, inRepOrg }) {
      const {
        data: { logs },
      } = await alert.getChildLogs(id, inRepOrg)
      return logs
    },
    setHistoryViewSelectedDateRangeAction({ commit }, { startDate, endDate }) {
      commit('setHistoryViewSelectedDateRangeMutation', [startDate, endDate])
    },
    setHistoryViewSelectedDateListAction({ commit }, dateList) {
      commit('setHistoryViewSelectedDateListMutation', dateList)
    },
  },
  modules: {
    superadmin,
    analytics,
    detectionPageAction,
  },
})

function printError(response) {
  if (response) {
    const { status, data, statusText } = response
    const message = typeof data === 'string' ? data : statusText
    Vue.$log.error(status, message)
  } else {
    Vue.$log.error(response)
  }
}
