/**
 * 관련해서는 FireScout 팀에 문의
 * @see https://alcherainc.atlassian.net/wiki/spaces/SP/pages/138281251/2019+FireScout#Salesforce-API
 */
import axios from 'axios'
import qs from 'qs'

export async function contactSupportForm(payload) {
  let data = {
    orgid: '00D5g000004DYMV',
    // Type, 문의 분야
    '00N5g00000CwLi4': payload.type,
    // 사례분류(고정)
    '00N5g00000CwLiJ': 'FireScout Customer Support',
    // Description, 상세 설명
    description: payload.description,
    // Firt Name, 이름(성 없이)
    '00N5g00000S4Pwz': payload.firstName,
    // Last Name, 성
    '00N5g00000S4Pwf': payload.lastName,
    // Organization, 조직
    '00N5g00000S6PKt': payload.organization,
    // Email
    email: payload.email,
    // Case URL, 파일 URL
    '00N5g00000S4Pvr': payload.file,
  }
  try {
    // CORS error가 발생되어 CORS 설정을 했으나 salesforce에 적용이 되지 않는 것 같음.
    // Web To Case에 접수는 문제 없이 등록이 되는 것 같음.
    // 관련하여 이슈는 있는듯 하나(https://developer.salesforce.com/forums/?id=906F0000000BI96IAG) 해결이 안된 것 같음.
    if (process.env.VUE_APP_ENV === 'qa') {
      data.retURL = 'https://qa.d2pr7q4w27khla.amplifyapp.com/support-submit'
    } else if (process.env.VUE_APP_ENV === 'develop') {
      return Promise.resolve()
    } else {
      data.retURL = 'https://product.firescout.ai/support-submit'
    }
    return await axios.post('https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8', qs.stringify(data))
  } catch (e) {}
}

export async function contactSupportFormToInbound(payload) {
  let data = {
    orgid: '00D5g000004DYMV',
    // Type, 문의 분야
    '00N5g00000S6wnM': payload.type,
    // 사례분류(고정)
    '00N5g00000S6woU': 'FireScout Customer Support',
    // Description, 상세 설명
    '00N5g00000S6wnS': payload.description,
    // Firt Name, 이름(성 없이)
    first_name: payload.firstName,
    // Last Name, 성
    last_name: payload.lastName,
    // Organization, 조직
    '00N5g00000S4vnW': payload.organization,
    // Email
    email: payload.email,
    // Case URL, 파일 URL
    '00N5g00000S6wo5': payload.file,
  }
  try {
    // CORS error가 발생되어 CORS 설정을 했으나 salesforce에 적용이 되지 않는 것 같음.
    // Web To Case에 접수는 문제 없이 등록이 되는 것 같음.
    // 관련하여 이슈는 있는듯 하나(https://developer.salesforce.com/forums/?id=906F0000000BI96IAG) 해결이 안된 것 같음.
    if (process.env.VUE_APP_ENV === 'qa') {
      data.retURL = 'https://qa.d2pr7q4w27khla.amplifyapp.com/support-submit'
    } else if (process.env.VUE_APP_ENV === 'develop') {
      return Promise.resolve()
    } else {
      data.retURL = 'https://product.firescout.ai/support-submit'
    }
    return await axios.post('https://go.alchera.ai/l/956902/2022-05-13/hhsj', qs.stringify(data))
  } catch (e) {}
}
