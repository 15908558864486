<template>
  <button
    :type="type"
    v-ripple
    @click="$emit('click', $event)"
    :disalbed="disalbed"
    :class="{ invert, dark: $vuetify.theme.dark, [`color-${colorStrength}`]: true }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  /**
   * emit
   * click
   */
  props: {
    type: {
      type: String,
      default: 'button',
    },
    disalbed: {
      type: String,
      default: false,
    },
    /**
     * color inversion
     */
    invert: {
      type: Boolean,
      default: false,
    },
    colorStrength: {
      type: String,
      default: '100',
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--f-text-white);
  border-radius: 8px;

  &.color-100 {
    background-color: var(--f-primary-100);
  }

  &.color-90 {
    background-color: var(--f-primary-90);
  }

  &.invert {
    color: var(--f-primary-90);
    border: 1px solid var(--f-primary-90);
    background-color: var(--f-supplementary-highlight);
  }

  &:disabled {
    color: var(--f-text-middle);
    background-color: var(--f-text-black-low);

    &.dark {
      background-color: var(--f-light-gray-2);
    }
  }
}
</style>
