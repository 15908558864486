import Constants from './constants'
import moment from 'moment'
import axios from 'axios'
import Vue from 'vue'

/**
 * Apply multiline ellipsis text
 * @param {string} text
 * @param {string} querySelector element id for ellipsis
 * @returns {string} ellipsis text
 */
function applyEllipsisText(text, querySelector) {
  let ellipsisText = text.replace(/\n/g, '<br>')
  let container = document.querySelector(querySelector)
  if (container) {
    container.innerHTML = ellipsisText
    while (text.length > 0 && container.clientHeight < container.scrollHeight) {
      text = text.substr(0, text.length - 1)
      ellipsisText = text.replace(/\n/g, '<br>') + '...'
      container.innerHTML = ellipsisText
    }
  }
  return ellipsisText
}

function getTextLineCount(element) {
  const size = element.getBoundingClientRect()
  const style = window.getComputedStyle(element)
  return Math.round(size.height / parseFloat(style.lineHeight))
}

function moveScrollToTop(isSmooth = true, element) {
  const htmlEl = element ?? document.querySelector('html')
  htmlEl.scroll({ top: 0, behavior: isSmooth ? 'smooth' : 'auto' })
}

function moveScrollToBottom(isSmooth = true, element) {
  const htmlEl = element ?? document.querySelector('html')
  htmlEl.scroll({ top: htmlEl.scrollHeight, behavior: isSmooth ? 'smooth' : 'auto' })
}

function makeUrl(from, to, returnURL) {
  let url = to
  if (from && !from.endsWith('/')) {
    from += '/'
  }
  try {
    if (returnURL) {
      url = new URL(to, from, null)
    } else {
      url = new URL(to, from, null).href
    }
  } catch {}
  return url
}

function getImageUrl(path, nocache) {
  path = this.makeUrl(process.env.VUE_APP_CDN_URL, path)
  if (nocache) {
    path += '?nocache=' + Date.now()
  }
  return path
}

const regxEmail = /.+@.+\..+/
function checkValidEmail(email) {
  return regxEmail.test(email) || typeof email === 'undefined'
}

function openUrl(url, target = '_blank') {
  var link = document.createElement('a')
  link.target = target
  link.href = url
  link.click()
}

function openFileInMemoryOnBrowser(url, type) {
  fetch(url, { headers: { Accept: type } })
    .then((res) => res.blob())
    .then((blob) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([blob], { type })

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob)
      var link = document.createElement('a')
      link.target = '_blank'
      link.href = data
      link.click()
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
    })
}
async function copyClipboard(value) {
  const type = 'text/plain'
  let text
  const getTinyUrl = async () => {
    const { data } = await axios.post('https://api.tinyurl.com/create?api_token=OeJpna3aCsrQ2ep6B0u6cyy7Y8vEq7mMtJWewirzVpK6ZMhcXrm6HE6Px9A2', {
      url: value,
      domain: 'tinyurl.com',
    })
    text = data.data?.tiny_url
    // console.log('copied', type, text)
    return new Blob([text], { type })
  }
  try {
    navigator.clipboard.write([new ClipboardItem({ [type]: getTinyUrl() })])
    return true
  } catch (e) {
    try {
      navigator.clipboard.write([new ClipboardItem({ [type]: await getTinyUrl() })])
      return true
    } catch (error) {
      try {
        await getTinyUrl()
        navigator.clipboard.writeText(text)
        return true
      } catch (writeError) {
        // console.info('link copy error')
        return false
      }
    }
  }
}

function getOffsetDate(date, offset) {
  const local = moment(date).utcOffset(offset * 60)
  const result = local.format('YYYY-MM-DD HH:mm:ss')
  return result
}

function getRawOffsetDate(date, offset) {
  if (date !== false) {
    return moment(date).utcOffset(offset * 60)
  } else {
    return moment().utcOffset(offset * 60)
  }
}

function checkToday(date, offset) {
  const local = moment(date).utcOffset(offset * 60)
  const start = moment()
    .utcOffset(offset * 60)
    .startOf('date')
  const end = moment()
    .utcOffset(offset * 60)
    .endOf('date')
  return local.isBetween(start, end, undefined, '[]')
}

function checkRecent72(date, offset) {
  const local = moment(date).utcOffset(offset * 60)
  const end = moment().utcOffset(offset * 60)
  const start = end.clone().subtract(3, 'd')
  return local.isBetween(start, end, undefined, '[]')
}

function getScrollParent(node) {
  if (node == null) {
    return null
  }
  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

function defineAlertOffTime(time) {
  const timeNumber = parseInt(moment(time).format('mmss'))
  const minute30 = 3000
  if (timeNumber < minute30) {
    return moment(time).startOf('hour').format()
  } else {
    return moment(time).add(1, 'h').startOf('hour').format()
  }
}

function isEllipsis(element) {
  if (element.children[0]) {
    const child = element.children[0]
    return child.clientWidth < child.scrollWidth
  } else {
    return element.clientWidth < element.scrollWidth
  }
}

function locaitonToDegree(degree) {
  if (degree !== null && !isNaN(Number(degree))) {
    degree = Math.round(degree)
    if (degree === -1) {
      degree = '-'
    } else if (0 <= degree && degree < 22.6) {
      degree += '˚ N'
    } else if (22.6 <= degree && degree < 67.6) {
      degree += '˚ NE'
    } else if (67.6 <= degree && degree < 112.5) {
      degree += '˚ E'
    } else if (112.5 <= degree && degree < 157.6) {
      degree += '˚ SE'
    } else if (157.6 <= degree && degree < 202.6) {
      degree += '˚ S'
    } else if (202.6 <= degree && degree < 247.6) {
      degree += '˚ SW'
    } else if (247.6 <= degree && degree < 292.6) {
      degree += '˚ W'
    } else if (292.6 <= degree && degree < 337.6) {
      degree += '˚ NW'
    } else {
      degree += '˚ N'
    }
  }
  return degree
}

function isDuplicateLog(log) {
  const logKeys = Object.keys(log)
  const necessaryKeys = ['is_checked_organization', 'is_duplicated_by_organization', 'is_duplicated_by_worker']
  if (necessaryKeys.every((k) => logKeys.includes(k))) {
    //  check if duplicate
    if (log.is_checked_organization) {
      return log.is_duplicated_by_organization
    }
    return log.is_duplicated_by_worker
  } else {
    // Vue.$log.debug('Util#isDuplicatedLog#necessary keys not found')
  }
  return true
}

function makeRecentLogIdSet(recentLogList) {
  const recentLogIdSet = new Set()
  for (let i = 0; i < recentLogList.length; i++) {
    recentLogIdSet.add(recentLogList[i].log_id)
  }
  return recentLogIdSet
}

function getUrlQuery(path) {
  const searchParams = new URLSearchParams(path)
  const name = searchParams.get('name')
  const id = searchParams.get('id')
  return { name, id }
}

function getCookie(key, cookies) {
  const cookieKey = key + '='
  let result = ''
  const cookieArr = cookies.split(';')
  for (let i = 0; i < cookieArr.length; i++) {
    if (cookieArr[i][0] === ' ') {
      cookieArr[i] = cookieArr[i].substring(1)
    }

    if (cookieArr[i].indexOf(cookieKey) === 0) {
      result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length)
      return result
    }
  }
  return null
}

function deleteCookie(key) {
  document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
function getMonitoringLogsChangeFlag(nextLogs, prevLogs) {
  // 모니터링 로그 업데이트 Flag 작동 방식
  // 새롭게 들어온 로그의 길이가 다르거나 이전 로그의 길이와는 다른 경우
  // 가장 최근 로그의 만료시간이 다른 경우 (만료시간 확장)
  // 가장 최근 로그의 아이디가 다른 경우 (업데이트 처리)
  // 두번째 최근 로그의 아이디가 다른 경우 (만료시간 확장으로 인하여 두번째 로그가 첫번째 로그보다 먼저 만료되는 경우)
  if (
    nextLogs.length !== prevLogs.length ||
    (nextLogs[0] && prevLogs[0] && nextLogs[0].expire_time !== prevLogs[0].expire_time) ||
    (nextLogs[0] && prevLogs[0] && nextLogs[0].id !== prevLogs[0].id) ||
    (nextLogs[1] && prevLogs[1] && nextLogs[1].id !== prevLogs[1].id)
  )
    return true
  return false
}

export default {
  getUrlQuery,
  applyEllipsisText,
  getTextLineCount,
  moveScrollToTop,
  moveScrollToBottom,
  makeUrl,
  getImageUrl,
  checkValidEmail,
  openUrl,
  openFileInMemoryOnBrowser,
  copyClipboard,
  getOffsetDate,
  getRawOffsetDate,
  checkToday,
  checkRecent72,
  getScrollParent,
  defineAlertOffTime,
  isEllipsis,
  locaitonToDegree,
  isDuplicateLog,
  makeRecentLogIdSet,
  getCookie,
  deleteCookie,
  getMonitoringLogsChangeFlag,
}
