<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="10px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }" class="button-alert-off">
      <v-btn
        :class="{ 'btn-disabled': disabled, dark: themeName === 'dark' }"
        color="#ff6e68"
        depressed
        :disabled="disabled"
        height="40"
        :outlined="!disabled"
        ripple
        rounded
        width="126"
        v-on="on"
        v-bind="attrs"
      >
        <img v-if="!disabled" src="@/assets/Timeout_Icon_red.svg" width="16px" />
        <!-- <img v-else-if="$vuetify.theme.dark && disabled" src="@/assets/Alert_Off_Button-disabled-dark.svg" width="22px" /> -->
        <img v-else src="@/assets/Alert_Off_Button-disabled.svg" width="22px" />
        <!-- <img v-if="$vuetify.theme.dark && disabled" src="@/assets/Alert_Off_Button-disabled-dark.svg" width="24px" />
        <img v-else-if="disabled" src="@/assets/Alert_Off_Button-disabled.svg" width="24px" />
        <img v-else-if="$vuetify.theme.dark" v-bind="attrs" v-on="on" src="@/assets/Alert_Off_Button-dark.svg" width="24px" />
        <img v-else v-bind="attrs" v-on="on" src="@/assets/Alert_Off_Button.svg" width="24px" /> -->
        <div :class="`button-alert-off-main-button-text ${disabled ? 'disabled' : ''}`" data-testid="Alert_Off_Button">Timeout</div>
      </v-btn>
    </template>
    <div :class="`button-alert-off-menu ${themeName}`" @click="detectOutsideContentClick($event)">
      <div class="button-alert-off-title" @click="closePrevMenu">Recent Cameras</div>
      <div style="max-height: 148px; overflow-y: auto">
        <div v-for="recent in recentList" :key="recent.id">
          <ButtonAlertOffRecent :item="recent" @click="close">
            <template v-slot="{ activator: { attrs, on } }">
              <div
                v-bind="attrs"
                @mouseover="onMouseOver(recent, $event, on)"
                @click="onClick($event, on)"
                :class="{ 'button-alert-off-content': true, focus: menuId === recent.id, dark: $vuetify.theme.dark }"
              >
                <div class="text-truncate">{{ recent.name }}</div>
                <v-spacer></v-spacer>
                <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_16px_Icon.svg" />
                <img v-else-if="menuId === recent.id" src="@/assets/Chevron-Right_16px_Icon.svg" />
                <img v-else src="@/assets/Chevron-Right_16px_Icon-black.svg" />
              </div>
            </template>
          </ButtonAlertOffRecent>
        </div>
      </div>
      <div :class="`split-line ${themeName}`"></div>
      <div class="button-alert-off-title" @click="closePrevMenu">Paused Alerts</div>
      <div style="max-height: 148px; overflow-y: auto">
        <div v-for="paused in pausedList" :key="paused.id">
          <ButtonAlertOffPaused :item="paused" @click="close">
            <template v-slot="{ activator: { attrs, on } }">
              <div
                v-bind="attrs"
                @mouseover="onMouseOver(paused, $event, on)"
                @click="onClick($event, on)"
                :class="{ 'button-alert-off-content': true, focus: menuId === paused.id, dark: $vuetify.theme.dark }"
              >
                <div class="text-truncate">{{ paused.name }}</div>
                <v-spacer></v-spacer>
                <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_16px_Icon.svg" />
                <img v-else-if="menuId === paused.id" src="@/assets/Chevron-Right_16px_Icon.svg" />
                <img v-else src="@/assets/Chevron-Right_16px_Icon-black.svg" />
              </div>
            </template>
          </ButtonAlertOffPaused>
        </div>
      </div>
      <div v-if="isMapview" :class="`browse-alert-off`">
        <div :class="`browse-alert-off-text`">
          Don't disturb me while <br />
          browsing the dashboard
        </div>
        <v-switch v-model="isAlertOff" @change="onToggleInput" @click="test" class="switch" color="#FF6E68" dense inset> </v-switch>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import ButtonAlertOffRecent from './ButtonAlertOffRecent'
import ButtonAlertOffPaused from './ButtonAlertOffPaused'
import moment from 'moment'
import Theme from '@/mixin/theme.mixin'

/**
 * 해당 카메라에서 발생한 마지막 로그를 기준으로 72시간 내 까지만 알림을 끌 수 있음. 72시간을 초과하는 옵션(3 hours, 6hours, Tomorrow)은 숨김 처리.
 * Custom 옵션에서 마지막 로그의 발생 시각을 30분 00초 기준으로 설정. ex) 6:29 로그 -> 6시까지 설정 가능 6:30 -> 7시까지 설정 가능
 */
export default {
  mixins: [Theme],
  components: { ButtonAlertOffRecent, ButtonAlertOffPaused },
  props: {
    disabled: Boolean,
    isMapview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      menuId: -1,
      prevMenuOn: null,
    }
  },
  computed: {
    ...mapState(['camMap', 'recentLogListv2', 'isMapviewAlertOff', 'user']),
    list() {
      const cameraIdSet = new Set()
      this.recentLogListv2.forEach((log) => cameraIdSet.add(log.camera_id))
      const cameraList = [...cameraIdSet].map((id) => this.camMap[id]).filter((cam) => !!cam)
      return cameraList
    },
    recentList() {
      return this.list.filter((cam) => !cam.noti_pause_end || moment().isAfter(cam.noti_pause_end))
    },
    pausedList() {
      return this.list.filter((cam) => !!cam.noti_pause_end && moment().isBefore(cam.noti_pause_end))
    },
    isAlertOff() {
      return this.isMapviewAlertOff
    },
  },
  methods: {
    onToggleInput() {
      this.$store.dispatch('setMapviewAlertToggleAction')
    },
    detectOutsideContentClick(event) {
      const clickedTagetClassNames = event.target.classList
      for (let i = 0; i < clickedTagetClassNames.length; i++) {
        if (clickedTagetClassNames[i] === 'button-alert-off-menu' || clickedTagetClassNames[i] === 'button-alert-off-title') {
          this.closePrevMenu()
          break
        }
      }
    },
    closePrevMenu() {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
    },
    close() {
      this.open = false
      this.menuId = -1
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
    },
    onChange(open) {
      if (!open) {
        this.close()
      }
    },
    onMouseOver(item, e, on) {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
      this.menuId = item.id
      if (on) {
        this.prevMenuOn = on
        on.click(new MouseEvent('click'))
      }
    },
    onClick(e, on) {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
      this.prevMenuOn = on
      setTimeout(() => {
        on.click(new MouseEvent('click'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-alert-off {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.v-btn--disabled.v-btn--has-bg.btn-disabled {
  &.theme--light {
    background-color: #0000001f !important;
  }

  &.theme--dark {
    background-color: #d8d9da !important;
  }
}

.button-alert-off-main-button-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  margin-left: 8px;

  &.disabled {
    color: #00000042;
  }
}

.button-alert-off-menu {
  display: flex;
  flex-direction: column;
  width: 250px;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 12px;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }

  img {
    width: 16px;
  }
}

.split-line {
  height: 1px;
  margin-top: 11px;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-high);
  }
}

.button-alert-off-title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  margin: 15px 20px;
}

.button-alert-off-content {
  display: flex;
  align-items: center;
  height: 29px;
  padding: 0 20px;

  &.focus {
    color: var(--f-text-white);
    background-color: var(--f-primary-50);
    &.dark {
      color: var(--f-text-white-high);
    }
  }
}

.browse-alert-off {
  display: flex;
  margin: 0 20px;
  align-items: center;

  .browse-alert-off-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-right: 17px;
  }
}

.switch {
  width: 36px;
  border-radius: 10px;
}
</style>
