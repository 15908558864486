import { alert } from '../api'

export default {
  namespaced: true,
  actions: {
    detectionPageIntialize({ commit, dispatch }, { cameraName, logId }) {
      if (logId) dispatch('getLogAction', logId)
      else if (cameraName) commit('setCurrentCamByName', cameraName, { root: true })
      else dispatch('showMostRecentLogOrLiveView')
    },
    showMostRecentLogOrLiveView({ commit, dispatch, rootState }, preveiosLogId) {
      if (rootState.recentLogListv2.length) {
        const currentLogId = rootState.currentLog?.log_id
        const updatedLogId = rootState.recentLogListv2[0].log_id

        if (preveiosLogId !== updatedLogId) dispatch('getLogAction', updatedLogId)
        else if (rootState.currentLog && !rootState.currentLog.after_image_paths) dispatch('checkAfterImageAndUpdate', currentLogId)
      } else commit('setCurrentCamByName', null, { root: true }) // 알파벳 순서 가장 맨앞 카메라
    },
    nameSearchCase({ commit, rootState }, cameraName) {
      if ((rootState.currentCam && cameraName !== rootState.currentCam.name) || !rootState.currentCam) {
        commit('setCurrentCamByName', cameraName, { root: true })
      }
    },
    idSearchCase({ dispatch, rootState }, logId) {
      if (logId !== rootState.currentLog?.log_id) dispatch('getLogAction', logId)
      else if (rootState.currentLog && logId === rootState.currentLog.log_id && rootState.currentLog.after_image_paths === null) {
        dispatch('checkAfterImageAndUpdate', logId)
      }
    },
    async getLogAction({ commit }, logId) {
      const { data } = await alert.getLog(logId)
      commit('currentLogMutation', data.info, { root: true })
    },
    async checkAfterImageAndUpdate({ commit }, logId) {
      const logChekc = await alert.getLog(logId)
      if (logChekc.after_image_paths) commit('currentLogMutation', logChekc, { root: true })
    },
    forceInitialize({ commit, dispatch, rootState }) {
      if (rootState.recentLogListv2.length) dispatch('getLogAction', rootState.recentLogListv2[0].log_id)
      else commit('setCurrentCamByName', null, { root: true })
    },
  },
}
