<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="5px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <InputSearchBar
        v-bind="attrs"
        v-on="on"
        :value="text"
        @input="onInput"
        placeholder="Search"
        @focus="onFocus"
        @blur="onBlur"
        @clear="onClear"
        @keydown="onKeydown"
      ></InputSearchBar>
    </template>
    <div v-if="filteredItems.length" ref="dropdownContainer" class="dropdown-container">
      <div class="wrapper">
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="onClickItem(item)"
          :class="{ item: true, select: getItemValue(select) === getItemValue(item) }"
        >
          <div>{{ filteredText[index].prev }}</div>
          <div class="highlight">{{ filteredText[index].text }}</div>
          <div>{{ filteredText[index].next }}</div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import DropdownSearch from '@common/DropdownSearch'
import InputSearchBar from './InputSearchBar'

export default {
  extends: DropdownSearch,
  components: { InputSearchBar },
}
</script>

<style lang="scss" scoped>
.dropdown-container {
  width: 100%;
  max-height: 207px;
  padding: 6px 0;
  background-color: var(--v-backgroundAsideHeaderRecent-base);
  color: var(--v-textDefault);
  overflow: auto;

  .item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 30px;
    width: 100%;
    font-size: 12px;

    &:hover,
    &.select {
      background-color: var(--v-backgroundAlertOff-base);
    }

    .highlight {
      background-color: var(--v-backgroundSearchMask);
    }
  }
}
</style>
