<template>
  <section class="calendar-dropdown" ref="calendar">
    <div :class="{ 'dropdown-button': true, dark: $vuetify.theme.dark }" @click="showCalendar">
      <div class="text">
        {{ processedDateRange }}
      </div>
      <img v-if="menuOpen && $vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-up-gray-small.svg" />
      <img v-else-if="!menuOpen && $vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-down-gray-small.svg" />
      <img v-else-if="menuOpen && !$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-up-black-small.svg" />
      <img v-else-if="!menuOpen && !$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-down-black-small.svg" />
    </div>
    <AnalyticsCalendar
      v-if="menuOpen && calendarType === 'DEFAULT'"
      @updateCustomDate="updateCustomDate"
      :isWeekCalendar="isWeekCalendar"
      :minDate="minDate"
      @closeCalendar="showCalendar"
    />
    <AnalyticsMonthCalendar v-else-if="menuOpen && calendarType === 'MONTH'" @updateMonthDate="updateMonthDate" @closeCalendar="showCalendar" />
    <AnalyticsDateCalendar
      v-else-if="menuOpen && calendarType === 'DAY'"
      @updateDaysDate="updateDaysDate"
      :minDate="minDate"
      @closeCalendar="showCalendar"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import Constants from '@/constants'
import AnalyticsCalendar from '@item/calendar/DefaultCalendar'
import AnalyticsMonthCalendar from './AnalyticsMonthCalendar.vue'
import AnalyticsDateCalendar from './AnalyticsDateCalendar.vue'

export default {
  components: { AnalyticsCalendar, AnalyticsMonthCalendar, AnalyticsDateCalendar },
  props: {
    calendarType: {
      type: String,
      default: 'DEFAULT',
    },
    pageType: {
      type: String,
      default: 'DEFAULT',
    },
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  computed: {
    ...mapState({
      camMap: (state) => state.camMap,
      frequencyTabType: (state) => state.analytics.frequency.selectedTab.tab,
      cameraSelectedRangeMode: (state) => state.analytics.camera.selectedRangeMode,
      frequencyDateTabSelectedRangeMode: (state) => state.analytics.frequency.selectedRangeMode.dateTabRangeMode,
      frequencyTimeTabSelectedRangeMode: (state) => state.analytics.frequency.selectedRangeMode.timeTabRangeMode,
      cameraDateRange: (state) => state.analytics.camera.selectedDateTimeRange,
      cameraDateRangeText: (state) => state.analytics.camera.selectedDateRangeText,
      frequencyDateTimeRange: (state) => state.analytics.frequency.selectedDateTimeRange,
      frequencyTimeTimeRange: (state) => state.analytics.frequency.selectedTimeTimeRange,
      cameraComparisonModeToggle: (state) => state.analytics.camera.comparisonModeToggle,
      frequencyComparisonModeToggle: (state) => state.analytics.frequency.comparisonModeToggle,
      // get frequency dateRanges
      frequencyTimeDayRange: (state) => state.analytics.frequency.timeTab.dayDateRange,
      frequencyTimeWeekRange: (state) => state.analytics.frequency.timeTab.weekDateRange,
      frequencyTimeMonthRange: (state) => state.analytics.frequency.timeTab.monthDateRange,
      frequencyTimeNormalRange: (state) => state.analytics.frequency.timeTab.normalDateRange,
      // minData
      contractStartDate: (state) => state.user.contract_start,
    }),
    minDate() {
      const contractDate = moment(this.contractStartDate).subtract(1, 'days')
      const beforeSixMonth = moment().subtract(6, 'months').subtract(1, 'days')
      const dateDiff = contractDate.diff(beforeSixMonth, 'days')
      return dateDiff > 0 ? contractDate : beforeSixMonth
    },
    frequencyDateRange() {
      return this.frequencyTabType === Constants.FREQUENCY_TAB_DATE ? this.frequencyDateTimeRange : this.frequencyTimeTimeRange
    },
    frequencySelectedRangeMode() {
      return this.frequencyTabType === Constants.FREQUENCY_TAB_DATE ? this.frequencyDateTabSelectedRangeMode : this.frequencyTimeTabSelectedRangeMode
    },
    cameraMap() {
      return this.camMap
    },
    isWeekCalendar() {
      if (
        this.pageType === Constants.ANALYTICS_CAMERA_PAGE &&
        this.cameraComparisonModeToggle &&
        this.cameraSelectedRangeMode === Constants.RANGE_MODE_WEEK
      )
        return true
      else if (
        this.pageType === Constants.ANALYTICS_FREQUENCY_PAGE &&
        this.frequencyTabType === Constants.FREQUENCY_TAB_TIME &&
        this.frequencyComparisonModeToggle &&
        this.frequencySelectedRangeMode === Constants.RANGE_MODE_WEEK
      )
        return true
      else return false
    },
    selectedRangeMode() {
      return this.pageType === Constants.ANALYTICS_CAMERA_PAGE ? this.cameraSelectedRangeMode : this.frequencySelectedRangeMode
    },
    processedDateRange() {
      if (this.pageType === Constants.ANALYTICS_FREQUENCY_PAGE) {
        const originDateRange = this.frequencyDateRange
        // this.$log.debug('test', originDateRange)

        if (this.frequencyTabType === Constants.FREQUENCY_TAB_DATE) {
          // 1st tab (date)
          return `${moment(originDateRange.start_time).format('YYYY-MM-DD')} ~ ${moment(originDateRange.end_time).format('YYYY-MM-DD')}`
        } else {
          // 2nd tab (time)
          if (this.frequencyTimeTabSelectedRangeMode === Constants.RANGE_MODE_MONTH && originDateRange.months) {
            // months
            return originDateRange.months.map((month) => moment(month).format('YYYY-MM')).join(', ')
          } else if (this.frequencyTimeTabSelectedRangeMode === Constants.RANGE_MODE_WEEK && !originDateRange.months) {
            // week
            return `${moment(this.frequencyTimeWeekRange.start_time[0]).format('YYYY-MM-DD')} ~ ${moment(
              this.frequencyTimeWeekRange.end_time[0]
            ).format('YYYY-MM-DD')}`
          } else if (!this.frequencyComparisonModeToggle) {
            // not comparison mode, day
            return `${moment(this.frequencyTimeNormalRange.start_time[0]).format('YYYY-MM-DD')} ~ ${moment(
              this.frequencyTimeNormalRange.end_time[0]
            ).format('YYYY-MM-DD')}`
          } else if (this.frequencyComparisonModeToggle && this.frequencyTimeTabSelectedRangeMode === Constants.RANGE_MODE_DAY) {
            // is comparison mode, day
            return this.frequencyTimeDayRange.dates.map((i) => moment(i).format('YYYY-MM-DD')).join(', ')
          } else {
            return ''
          }
        }
      } else return this.cameraDateRangeText
    },
  },
  methods: {
    clickOutside() {
      this.menuOpen = false
    },
    showCalendar() {
      this.menuOpen = !this.menuOpen
    },
    updateCustomDate(range) {
      const startTimeValue = range.startDate.startOf('date').format()
      const endTimeValue = range.endDate.endOf('date').format()

      if (this.pageType === Constants.ANALYTICS_CAMERA_PAGE) {
        if (this.isWeekCalendar) {
          this.$store.dispatch('analytics/getCamAlertsCountByWeekAction', {
            start_time: [startTimeValue],
            end_time: [endTimeValue],
          })
        } else {
          this.$store.dispatch('analytics/getCamAlertsCountByPeriodAction', [
            {
              start_time: startTimeValue,
              end_time: endTimeValue,
            },
            this.cameraMap,
          ])
        }
      } else {
        if (this.isWeekCalendar && this.frequencyTabType === Constants.FREQUENCY_TAB_TIME) {
          // Time, Week
          this.$store.dispatch('analytics/setFrequencyTimeWeekAction', {
            start_time: [startTimeValue],
            end_time: [endTimeValue],
          })
        } else if (!this.isWeekCalendar && this.frequencyTabType === Constants.FREQUENCY_TAB_TIME) {
          // Time, Normal
          this.$store.dispatch('analytics/setFrequencyTimeNormalAction', {
            start_time: [startTimeValue],
            end_time: [endTimeValue],
          })
        } else {
          this.$store.dispatch('analytics/setFrequencyDateRangeAction', {
            start_time: startTimeValue,
            end_time: endTimeValue,
          })
          switch (this.frequencyDateTabSelectedRangeMode) {
            case Constants.RANGE_MODE_DAY:
              // Date, Day
              this.$store.dispatch('analytics/setFrequencyDateDayAction', {
                start_time: [startTimeValue],
                end_time: [endTimeValue],
                time_zone: moment.tz.guess(),
              })
              break
            case Constants.RANGE_MODE_WEEK:
              // Date, Week
              this.$store.dispatch('analytics/setFrequencyDateWeekAction', {
                start_time: startTimeValue,
                end_time: endTimeValue,
              })
              break
            case Constants.RANGE_MODE_MONTH:
              // Date, Month
              this.$store.dispatch('analytics/setFrequencyDateMonthAction', {
                start_time: startTimeValue,
                end_time: endTimeValue,
              })
              break
          }
        }
      }
      this.menuOpen = false
    },
    updateDaysDate(range) {
      const dayDateArray = []

      for (let [key, value] of Object.entries(range)) dayDateArray.push(value.startOf('date').format())
      dayDateArray.sort()
      const startTimeArray = dayDateArray.map((i) => moment(i).startOf('date').format())
      const endTimeArray = dayDateArray.map((i) => moment(i).endOf('date').format())

      if (this.pageType === Constants.ANALYTICS_CAMERA_PAGE) {
        this.$store.dispatch('analytics/getCamAlertsCountByDaysAction', { dates: dayDateArray })
      } else {
        this.$store.dispatch('analytics/setFrequencyTimeDayAction', {
          dates: startTimeArray,
        })
      }
      this.menuOpen = false
    },
    updateMonthDate(range) {
      const monthDateArray = range.map((month) => moment(`${month}-01`).startOf('month').format())
      monthDateArray.sort()
      if (this.pageType === Constants.ANALYTICS_CAMERA_PAGE) {
        this.$store.dispatch('analytics/getCamAlertsCountByMonthsAction', { months: monthDateArray })
      } else {
        this.frequencyTabType === Constants.FREQUENCY_TAB_DATE
          ? this.$store.dispatch('analytics/setFrequencyDateMonthAction', {
              months: monthDateArray,
            })
          : this.$store.dispatch('analytics/setFrequencyTimeMonthAction', {
              months: monthDateArray,
            })
      }
      this.menuOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  width: 233px;
  height: 36px;
  text-align: start;
  padding-left: 16px;
  padding-right: 13px;
  background-color: var(--f-light-gray-1);
  display: flex;
  box-sizing: border-box;
  align-items: center;

  .text {
    width: 184px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  &.dark {
    background-color: var(--f-bg-middle);
  }
  img {
    margin-left: auto;
  }
}
</style>
