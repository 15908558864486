export const YES_OR_NO_OPTION = ['Yes', 'No']
export const PAN_OPTION = ['Pan', 'Fixed', 'Unknown']

export const CONDITION_OPTION = {
  ClEARNESS: 'Camera Cleanness',
  EVALUATED: 'Evaluated',
  FALSE_POSITIVE_AREAS: 'False Positive Areas',
  IR_FILTER: 'IR Filter',
  IMAGE_QUALITY: 'Image Quality',
  INTERNAL_USAGE: 'Internal Usage',
  NIGHT_DETECTION: 'Night Detection',
  PTZ_VALUE: 'PTZ Value',
  PAN_FIXED: 'Pan/Fixed',
  SERVICED: 'Serviced',
  SKY_AREA: 'Sky Area',
  HFTD_AREA: 'Within HFTD Area',
}

export const IMAGE_QUALITY = [
  { text: 'Above FHD', value: 1 },
  { text: 'FHD (1920*1080)', value: 2 },
  { text: 'Under FHD', value: 3 },
]
export const HFTD_AREA = [
  { text: 'Tier 1', value: 1 },
  { text: 'Tier 2', value: 2 },
  { text: 'Tier 3', value: 3 },
  { text: 'Unselected', value: 4 },
]
export const CAMERA_CLEARNESS = [
  { text: 'Very Dirty', value: 1 },
  { text: 'Dirty', value: 2 },
  { text: 'Normal', value: 3 },
  { text: 'Clean', value: 4 },
  { text: 'Very Clean', value: 5 },
]
export const SKY_AREA = [
  { text: 'Very High', value: 1 },
  { text: 'High', value: 2 },
  { text: 'Normal', value: 3 },
  { text: 'Low', value: 4 },
  { text: 'Very Low', value: 5 },
]

export const VALIDATE_TYPE = {
  UPPER_CASE: 'upperCase',
  LOWER_CASE: 'lowerCase',
  NUMBER: 'number',
  SPECIAL_CHARACTER: 'specialCharacter',
  KOREAN: 'korean',
  COORDINATE: 'coordinate',
}

export const EMPTY_ERROR = 'Please fill out this field.'
export const INVALID_ERROR = 'Invalid data.'

export const ERROR_TYPE = {
  REQUIRE: 'required',
  INVALID: 'invalid',
  DUPLICATE: 'duplicate',
}

export const CAMERA_LIST_TAB_TYPE = ['Requested', 'History', 'Archived', 'Edit']
export const CAHNGE_REQUEST_TAB_TYPE = ['Requested', 'In Review', 'Reviewd', 'Archived']
export const HISTORY_ACTION_TYPE = ['merge', 'rollback']
