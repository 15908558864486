import Vue from 'vue'
import { superadmin, workspace } from '@/api'
import Constants from '@/constants'
import Util from '@/util'
import moment from 'moment'
import 'moment-timezone'
import { v4 as uuid } from 'uuid'
import { CAMERA_LIST_TAB_TYPE } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'
import RESPONSE_CODE from '@/responseCode'

const EDIT_MODE_CONSTANTS = {
  REVIEW: 0,
  MERGE: 1,
  ARCHIVE: 2,
}

export default {
  namespaced: true,
  state: () => ({
    timezone: {
      name: moment().tz('America/Los_Angeles').format('z'),
      offset: moment().tz('America/Los_Angeles').utcOffset(),
    },
    timezoneList: [
      { name: moment().tz('America/Los_Angeles').format('z'), offset: moment().tz('America/Los_Angeles').utcOffset() },
      { name: moment().tz('Asia/Seoul').format('z'), offset: moment().tz('Asia/Seoul').utcOffset() },
      { name: moment().tz('Etc/UTC').format('z'), offset: moment().tz('Etc/UTC').utcOffset() },
      { name: moment().tz('Australia/Sydney').format('z'), offset: moment().tz('Australia/Sydney').utcOffset() },
    ],
    contract_types: [
      { id: 0, name: 'POC' },
      { id: 1, name: 'Full-Term' },
    ],
    organizations: [],
    organizationsColorMap: {},
    organizationTableData: [],
    countries: [],
    cameraGroups: [],
    panFixed: [],
    monitored: [],
    users: [],
    usersTableData: [],
    receivers: [],
    receiversTableData: [],
    providers: [],
    apiKeys: [],
    cameras: [],
    fullInfoCameras: [],
    camera: {
      information: {
        name: '',
      },
      condition: {},
    },
    camerasTableData: [],
    allocatedCameras: [],
    regions: [],
    counties: [],
    fullRegions: [],
    fullCounties: [],
    datePeriod: {
      startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    defaultWorkerList: '',
    workerList: [],
    workerIdList: [],
    workerListUpdate: false,
    expandedByLogId: -1,
    monitorTabSound: false,
    //camera list tab
    changeRequestRequestList: [],
    changeRequestInReviewList: [],
    changeRequestReviewedList: [],
    historyList: [],
    discussionList: [],
    archiveList: [],
    resolvedDiscussionList: [],
    // updated camera
    addedCameraList: {},
    editedCameraList: {},
    deletedCameraIdList: [],
    cameraListTabModeInfo: { type: '', status: false, id: -1, userName: '', subject: '', authority: false },
    monitoringLogs: [],
    // duplicate check
    cameraNameSetForDuplicate: {},
    apiUrlSetForDuplicate: {},
  }),
  getters: {
    datePeriodText: function (state) {
      return state.datePeriod.startDate + ' ~ ' + state.datePeriod.endDate
    },
  },
  mutations: {
    setAddedCameraMutation(state, payload) {
      const newCamId = uuid()
      state.addedCameraList = { ...state.addedCameraList }
      state.addedCameraList[newCamId] = { ...payload, id: newCamId }
    },
    editExistingCameraMutation(state, { cameraId, editedCamera }) {
      const organizations = state.camerasTableData.filter((camera) => camera.id === cameraId)[0].organizations
      state.editedCameraList = { ...state.editedCameraList }
      state.editedCameraList[cameraId] = editedCamera
      state.editedCameraList[cameraId].information.organizations = organizations
      state.editedCameraList[cameraId].information.id = cameraId
    },
    editAddedCameraMutation(state, { cameraId, editedCamera }) {
      state.addedCameraList = { ...state.addedCameraList }
      state.addedCameraList[cameraId] = editedCamera
    },
    deleteExistingCameraMutation(state, cameraId) {
      state.deletedCameraIdList = [...state.deletedCameraIdList, cameraId]
    },
    deleteAddedCameraMutation(state, cameraId) {
      delete state.addedCameraList[cameraId]
    },
    setDatePeriodMutation(state, payload) {
      state.datePeriod = payload
    },
    setTimezone(state, timezoneName) {
      state.timezone = state.timezoneList.find((v) => v.name === timezoneName)
    },
    setPanFixed(state, payload) {
      state.panFixed = payload
    },
    setMonitored(state, payload) {
      state.monitored = payload
    },
    setCameraGroups(state, payload) {
      state.cameraGroups = payload
    },
    setCountries(state, countries) {
      state.countries = countries
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setCounties(state, counties) {
      state.counties = counties
    },
    setRegionsAndCounties(state, { regions, counties }) {
      if (regions && regions.length) state.fullRegions = regions
      if (counties && counties.length) state.fullCounties = counties
    },
    setContractTypes(state, payload) {
      state.contract_types = payload
    },
    setCamera(state, payload) {
      state.camera = payload
    },
    setApiKeys(state, payload) {
      state.apiKeys = payload
    },
    setProviders(state, payload) {
      state.providers = payload
    },
    setOrganizations(state, payload) {
      if (payload) {
        state.organizations = payload
        state.organizationsColorMap = {}
      }
      state.organizationTableData = state.organizations.map((v, index) => {
        state.organizationsColorMap[v.name] = Constants.COLORS[index % Constants.COLORS.length]
        const contract_period =
          moment(v.contract_start).utcOffset(state.timezone.offset).format('YYYY-MM-DD') +
          '~' +
          moment(v.contract_end).utcOffset(state.timezone.offset).format('YYYY-MM-DD')
        return {
          id: v.id,
          name: v.name,
          workspace: v.workspace,
          contract_type: v.contract_type,
          contract_period,
          timezone: state.timezone.name,
        }
      })
    },
    setUsers(state, payload) {
      if (payload) {
        state.users = payload
      }
      state.usersTableData = state.users.map((v) => {
        let last_login_time
        if (parseInt(v.last_login_time) < 1000) {
          last_login_time = '-'
        } else {
          last_login_time = moment(v.last_login_time).utcOffset(state.timezone.offset).format('YYYY-MM-DD HH:mm:ss')
        }
        return {
          id: v.id,
          organization_name: v.organization_name,
          user_id: v.user_id,
          user_role: v.user_role,
          last_login_time,
        }
      })
    },
    setReceivers(state, payload) {
      if (payload) {
        state.receivers = payload
      }
      state.receiversTableData = state.receivers.map((v) => {
        return {
          id: v.id,
          name: v.name,
          email: v.email,
          country_code: v.country_code,
          phone_number: v.phone_number,
          full_phone_number: `${v.country_code} ${v.phone_number}`,
          allow_email: v.allow_email,
          allow_sms: v.allow_sms,
          memo: v.memo,
          created_at: moment(v.created_at).utcOffset(state.timezone.offset).format('YYYY-MM-DD'),
        }
      })
    },
    setCameras(state, payload) {
      if (payload) {
        state.cameras = payload
      }
      state.cameraListTabModeInfo = { type: '', status: false, id: -1, userName: '', authority: false }
      state.camerasTableData = state.cameras.map((v) => {
        return {
          id: v.id,
          name: v.name,
          region: v.region,
          county: v.county,
          organizations: v.organizations ? v.organizations.map((v) => v.name) : [].map((v) => v.name),
          coordinate: v.coordinate.replace(' ', ', '),
          monitored: v.monitored ? 1 : 2,
          cameraGroup: v?.camera_group_id ? v?.camera_group_id : '-',
          priority: v.priority,
        }
      })
    },
    setEditedCameraInfo(state, key) {
      const { condition, information, id } = state.editedCameraList[key]
      state.camera = {
        information: {
          altitude: information.altitude,
          api_key_id: information.apiKey,
          api_url: information.apiUrl,
          camera_group_id: information.cameraGroupId,
          coordinate: information.coordinate,
          country_id: information.country,
          county_id: information.county,
          guide_url: information.guide,
          id: id,
          monitored: information.monitored === 1 ? true : false,
          name: information.cameraName,
          private_url_1: information.privateUrl1,
          private_url_2: information.privateUrl2,
          provider_id: information.cameraProvider,
          public_url: information.publicUrl,
          region_id: information.region,
        },
        condition: {
          camera_cleanness: condition.cameraClearnessValue,
          evaluated: condition.evaluated,
          false_positive_areas: condition.falsePositiveAreas,
          image_quality: condition.imageQualityValue,
          internal_usage: condition.internalUsage,
          ir_filter: condition.IRfilter,
          night_detection: condition.nightDetection,
          pan_or_fixed: condition.panFixed,
          ptz_value: condition.PTZValue,
          serviced: condition.serviced,
          sky_area: condition.skyAreaValue,
          within_hftd_area: condition.HFTDAreaValue,
        },
      }
    },
    setNewlyAddedCameraInfo(state, key) {
      const { condition, information, id } = state.addedCameraList[key]
      state.camera = {
        information: {
          altitude: information.altitude,
          api_key_id: information.apiKey,
          api_url: information.apiUrl,
          camera_group_id: information.cameraGroupId,
          coordinate: information.coordinate,
          country_id: information.country,
          county_id: information.county,
          guide_url: information.guide,
          id: id,
          monitored: information.monitored === 1 ? true : false,
          name: information.cameraName,
          private_url_1: information.privateUrl1,
          private_url_2: information.privateUrl2,
          provider_id: information.cameraProvider,
          public_url: information.publicUrl,
          region_id: information.region,
        },
        condition: {
          camera_cleanness: condition.cameraClearnessValue,
          evaluated: condition.evaluated,
          false_positive_areas: condition.falsePositiveAreas,
          image_quality: condition.imageQualityValue,
          internal_usage: condition.internalUsage,
          ir_filter: condition.IRfilter,
          night_detection: condition.nightDetection,
          pan_or_fixed: condition.panFixed,
          ptz_value: condition.PTZValue,
          serviced: condition.serviced,
          sky_area: condition.skyAreaValue,
          within_hftd_area: condition.HFTDAreaValue,
        },
      }
    },
    setFullInfoCameras(state, payload) {
      if (payload) {
        state.fullInfoCameras = payload
      }
    },
    setAllocatedCameras(state, payload) {
      state.allocatedCameras = payload
    },
    setWorkerListMutation(state, workerList) {
      state.workerList = workerList
    },
    setWorkerIdListMutation(state, payload) {
      const currentWorkerList = payload.sort().join('')
      if (currentWorkerList === state.defaultWorkerList) state.workerListUpdate = false
      else state.workerListUpdate = true
      state.workerIdList = payload
    },
    setDefaultWorkerListMutation(state, payload) {
      state.defaultWorkerList = payload
    },
    setExpandedByLogIdMutation(state, logId) {
      state.expandedByLogId = logId
    },
    setMonitorTabSound(state, soundToggle) {
      state.monitorTabSound = soundToggle
    },
    setReviewListMutation(state, list) {
      state.changeRequestRequestList =
        list.requested === null ? [] : list.requested.sort((a, b) => new Date(b.time_point).getTime() - new Date(a.time_point).getTime())
      state.changeRequestReviewedList =
        list.reviewed === null ? [] : list.reviewed.sort((a, b) => new Date(b.time_point).getTime() - new Date(a.time_point).getTime())
      state.changeRequestInReviewList =
        list.in_review === null ? [] : list.in_review.sort((a, b) => new Date(b.time_point).getTime() - new Date(a.time_point).getTime())
    },
    setHistoryListMutation(state, historyList) {
      state.historyList = !historyList ? [] : historyList.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    setDiscussionListMutation(state, discussions) {
      if (!!discussions.discussion)
        state.discussionList = discussions.discussion.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      else state.discussionList = []
      if (!!discussions.resolved)
        state.resolvedDiscussionList = discussions.resolved.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      else state.resolvedDiscussionList = []
    },
    setArchiveListMutation(state, list) {
      if (!!list.archives) {
        state.archiveList = list.archives.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      } else {
        state.archiveList = []
      }
    },
    setSelectedTableMutation(state, { id, userName, list, type, subject, authority }) {
      const addedList = {}
      list.adds.forEach((add) => {
        const newCamId = uuid()
        addedList[newCamId] = {
          information: {
            cameraName: add.camera_information.name,
            coordinate: add.camera_information.coordinate,
            altitude: add.camera_information.altitude,
            apiUrl: add.camera_information.api_url,
            publicUrl: add.camera_information.public_url,
            privateUrl1: add.camera_information.private_url_1,
            privateUrl2: add.camera_information.private_url_2,
            guide: add.camera_information.guide_url,
            monitored: add.camera_information?.monitored ? 1 : 2,
            cameraGroupId: add.camera_information.camera_group_id,
            country: add.camera_information.country_id,
            region: add.camera_information.region_id,
            county: add.camera_information.county_id,
            cameraProvider: add.camera_information?.camera_provider_id,
            apiKey: add.camera_information.api_key_id,
            organizations: [],
          },
          condition: {
            PTZValue: add.camera_condition.ptz_value,
            nightDetection: add.camera_condition.night_detection,
            IRfilter: add.camera_condition.ir_filter,
            serviced: add.camera_condition.serviced,
            evaluated: add.camera_condition.evaluated,
            internalUsage: add.camera_condition.internal_usage,
            panFixed: add.camera_condition.pan_or_fixed,
            falsePositiveAreas: add.camera_condition.false_positive_areas,
            imageQualityValue: add.camera_condition.image_quality,
            skyAreaValue: add.camera_condition.sky_area,
            cameraClearnessValue: add.camera_condition.camera_cleanness,
            HFTDAreaValue: add.camera_condition.within_hftd_area,
          },
        }
      })
      state.addedCameraList = addedList
      const editList = {}
      list.edits.forEach((edit) => {
        editList[edit.camera_information.id] = {
          information: {
            cameraName: edit.camera_information.name,
            coordinate: edit.camera_information.coordinate,
            altitude: edit.camera_information.altitude,
            apiUrl: edit.camera_information.api_url,
            publicUrl: edit.camera_information.public_url,
            privateUrl1: edit.camera_information.private_url_1,
            privateUrl2: edit.camera_information.private_url_2,
            guide: edit.camera_information.guide_url,
            monitored: edit.camera_information.monitored ? 1 : 2,
            cameraGroupId: edit.camera_information?.camera_group_id,
            country: edit.camera_information.country_id,
            region: edit.camera_information.region_id,
            county: edit.camera_information.county_id,
            apiKey: edit.camera_information.api_key_id,
            cameraProvider: edit.camera_information.camera_provider_id,
            organizations: edit.camera_information?.organizations ? edit.camera_information.organizations.map((org) => org.name) : [],
            priority: '-',
          },
          condition: {
            PTZValue: edit.camera_condition.ptz_value,
            nightDetection: edit.camera_condition.night_detection,
            IRfilter: edit.camera_condition.ir_filter,
            serviced: edit.camera_condition.serviced,
            evaluated: edit.camera_condition.evaluated,
            internalUsage: edit.camera_condition.internal_usage,
            panFixed: edit.camera_condition.pan_or_fixed,
            falsePositiveAreas: edit.camera_condition.false_positive_areas,
            imageQualityValue: edit.camera_condition.image_quality,
            skyAreaValue: edit.camera_condition.sky_area,
            cameraClearnessValue: edit.camera_condition.camera_cleanness,
            HFTDAreaValue: edit.camera_condition.within_hftd_area,
          },
        }
      })
      state.editedCameraList = editList
      state.deletedCameraIdList = list.delete_ids ? list.delete_ids : []
      state.camerasTableData = list.cameras.map((camera) => ({
        coordinate: camera.coordinate.replace(' ', ', '),
        id: camera.id,
        monitored: camera?.monitored ? 1 : 2,
        cameraGroup: camera?.camera_group_id ? camera?.camera_group_id : '-',
        name: camera.name,
        organizations: camera.organizations ? camera.organizations.map((org) => org.name) : [],
        priority: camera.priority ? camera.priority : '-',
        county: state.counties.filter((county) => county.id === camera.county_id)[0]?.name,
        region: state.regions.filter((region) => region.id === camera.region_id)[0]?.name,
      }))
      if (type) state.cameraListTabModeInfo = { type, status: true, id, userName, subject, authority: authority ? authority : false }
    },
    setSelectedHistoryTableMutation(state, { type, id, userName, subject, list }) {
      state.addedCameraList = {}
      state.editedCameraList = {}
      state.deletedCameraIdList = []
      state.camerasTableData = list.map((camera) => ({
        coordinate: camera.coordinate.replace(' ', ', '),
        county: state.fullCounties.filter((county) => county.id === camera.county_id)[0]?.name,
        id: camera.id,
        monitored: camera.monitored ? 1 : 2,
        cameraGroup: camera.camera_group_id ? camera.camera_group_id : '-',
        name: camera.name,
        organizations: camera.organizations ? camera.organizations.map((org) => org.name) : [],
        priority: camera.priority ? camera.priority : '-',
        region: state.fullRegions.filter((region) => region.id === camera.region_id)[0]?.name,
      }))
      if (type) state.cameraListTabModeInfo = { type, status: true, id, userName, subject, authority: false }
    },
    resetCameraListTabModeInfo(state) {
      state.cameraListTabModeInfo = { type: '', status: false, id: -1, userName: '', subject: '', authority: false }
    },
    // for camera list upload
    addUploadListToAddedCameraList(state, dataList) {
      for (let i = 0; i < dataList.length; i += 1) {
        Vue.set(state.addedCameraList, uuid(), {
          information: dataList[i].information,
          condition: dataList[i].condition,
          monitoring: dataList[i].monitoring,
        })
      }
    },
    setDeletedCameraIdList(state, idList) {
      state.deletedCameraIdList = idList.sort()
    },
    addDeleteCameraIdsMutation(state, idList) {
      for (let i = 0; i < idList.length; i += 1) {
        // remove camera add/edit info of matched camera id
        if (!!state.addedCameraList[idList[i]]) {
          delete state.addedCameraList[idList[i]]
        } else if (!!state.editedCameraList[idList[i]]) {
          delete state.editedCameraList[idList[i]]
        }
        // add camera id to delete list
        if (!state.deletedCameraIdList.includes(idList[i])) {
          state.deletedCameraIdList.push(idList[i])
        }
      }
      state.deletedCameraIdList.sort()
    },
    editUploadedCameraList(state, dataList) {
      for (let i = 0; i < dataList.length; i += 1) {
        dataList[i].information.id = dataList[i].id
        Vue.set(state.editedCameraList, dataList[i].id, {
          information: dataList[i].information,
          condition: dataList[i].condition,
          monitoring: dataList[i].monitoring,
        })
      }
    },
    resetAddEditDeleteCameraData(state) {
      Vue.set(state, 'addedCameraList', {})
      Vue.set(state, 'editedCameraList', {})
      state.deletedCameraIdList = []
      Vue.$log.debug('resetAddEditDeleteCameraData', state.addedCameraList)
    },
    setMonitoringLogs(state, logs) {
      logs.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
      const MonitoringLogsCahngeFlag = Util.getMonitoringLogsChangeFlag(state.monitoringLogs, logs)
      if (MonitoringLogsCahngeFlag) state.monitoringLogs = logs
    },
    deleteMonitorLogByIndexMutation(state, index) {
      state.monitoringLogs.splice(index, 1)
    },
    // camera name, api url duplicate
    duplicateSettingInDefaultMode(state) {
      state.cameraNameSetForDuplicate = {}
      state.apiUrlSetForDuplicate = {}
    },
    duplicateSettingInReviewEdit(state, payload) {
      const cameraNameSet = {}
      const apiUrlSet = {}

      payload.adds.forEach((camInfo) => {
        cameraNameSet[camInfo.camera_information.name] = true
        apiUrlSet[camInfo.camera_information.api_url] = true
      })
      payload.edits.forEach((camInfo) => {
        cameraNameSet[camInfo.camera_information.name] = true
        apiUrlSet[camInfo.camera_information.api_url] = true
      })
      payload.cameras.forEach((camInfo) => {
        if (payload.edit_ids.includes(camInfo.id)) {
          if (!cameraNameSet[camInfo.name]) cameraNameSet[camInfo.name] = false
          if (!apiUrlSet[camInfo.api_url]) cameraNameSet[camInfo.api_url] = false
        }
      })
      state.cameraNameSetForDuplicate = cameraNameSet
      state.apiUrlSetForDuplicate = apiUrlSet
    },
    duplicateSetUpdateOnAdd(state, { cameraName, apiUrl }) {
      state.cameraNameSetForDuplicate[cameraName] = true
      state.apiUrlSetForDuplicate[apiUrl] = true
    },
    duplicateSetUpdateOnEdit(state, { cameraName, apiUrl, originalCameraName, originalApiUrl }) {
      state.cameraNameSetForDuplicate[originalCameraName] = false
      state.apiUrlSetForDuplicate[originalApiUrl] = false
      state.cameraNameSetForDuplicate[cameraName] = true
      state.apiUrlSetForDuplicate[apiUrl] = true
    },
  },
  actions: {
    async getMonitoringLogs({ commit, dispatch }) {
      try {
        const { data } = await workspace.getAlertsBox()
        commit('setMonitoringLogs', data.logs)
      } catch (error) {
        dispatch('catchNetworkError', error)
        commit('setMonitoringLogs', [])
      }
    },
    async deleteMonitorLogByIndexAction({ commit }, index) {
      commit('deleteMonitorLogByIndexMutation', index)
    },
    async getContractTypes({ commit }) {
      const contract_types = await superadmin.getContractTypes()
      commit('setContractTypes', contract_types)
    },
    async getProviders({ commit }) {
      const providers = await superadmin.getProviders()
      commit('setProviders', providers ?? [])
    },
    async getApiKeys({ commit }, provider) {
      const apiKeys = await superadmin.getApiKeys(provider)
      commit('setApiKeys', apiKeys ?? [])
    },
    async getCountries({ commit }) {
      const countries = await superadmin.getCountries()
      commit('setCountries', countries ?? [])
    },
    async getRegions({ commit }, countries) {
      const regions = await superadmin.getRegions(countries)
      commit('setRegions', regions ?? [])
    },
    async getFullRegions({ commit }) {
      const regions = await superadmin.getRegions()
      commit('setRegionsAndCounties', { regions })
    },
    async getCounties({ commit }, regions) {
      const counties = await superadmin.getCounties(regions)
      commit('setCounties', counties ?? [])
    },
    async getFullCounties({ commit }) {
      const counties = await superadmin.getCounties()
      commit('setRegionsAndCounties', { counties })
    },
    async getCameraGroups({ commit }) {
      const cameraGroups = await superadmin.getCameraGroup()
      commit('setCameraGroups', cameraGroups ?? [])
    },
    async getPanFixed({ commit }) {
      const panFixed = await superadmin.getPanFixed()
      commit('setPanFixed', panFixed ?? [])
    },
    async getMonitored({ commit }) {
      const monitored = await superadmin.getMonitored()
      commit('setMonitored', monitored ?? [])
    },
    async getOrganizations({ commit }, keyword) {
      const organizations = await superadmin.getOrganizations(keyword)
      commit('setOrganizations', organizations ?? [])
    },
    async getUserRoleListAction({}) {
      const roles = await superadmin.getUserRoleList()
      return roles
    },
    async getUsers({ commit }, keyword) {
      const users = await superadmin.getUsers(keyword)
      commit('setUsers', users ?? [])
    },
    async getReceivers({ commit }, { id, keyword }) {
      const receivers = await superadmin.getNotiReceiversInOrganization(id, keyword)
      commit('setReceivers', receivers ?? [])
    },
    async getCameraSearchOptions({ commit }, saveSource) {
      const [regions, counties, countries, cameraGroups, panFixed, monitored] = await Promise.all([
        superadmin.getRegions(),
        superadmin.getCounties(),
        superadmin.getCountries(),
        superadmin.getCameraGroups(),
        superadmin.getPanFixed(),
        superadmin.getMonitored(),
      ])
      if (saveSource) commit('setRegionsAndCounties', { regions, counties })
      commit('setCountries', countries)
      commit('setRegions', regions)
      commit('setCounties', counties)
      commit('setCameraGroups', cameraGroups)
      commit('setPanFixed', panFixed)
      commit('setMonitored', monitored)
    },
    async getCameras({ commit }, { keyword, organizationIds, countryIds, regionIds, countyIds, cameraGroupIds, panFixedIds, monitored }) {
      const cameras = await superadmin.getCameras({
        keyword,
        organizationIds,
        countryIds,
        regionIds,
        countyIds,
        cameraGroupIds,
        panFixedIds,
        monitored,
      })
      commit('setCameras', cameras ?? [])
    },
    async getFullInfoCameras({ commit }, { keyword, organizationIds, countryIds, regionIds, countyIds, cameraGroupIds, panFixedIds, monitored }) {
      const cameras = await superadmin.getFullInfoCameras({
        keyword,
        organizationIds,
        countryIds,
        regionIds,
        countyIds,
        cameraGroupIds,
        panFixedIds,
        monitored,
      })
      commit('setFullInfoCameras', cameras ?? [])
    },
    async getCamera({ commit }, id) {
      const camera = await superadmin.getCamera(id)
      commit('setCamera', camera)
    },
    async getAllocatedCameras({ commit }, { keyword, organization_id, region_ids, county_ids }) {
      const cameras = await superadmin.getAllocatedCameras(organization_id, keyword, region_ids, county_ids)
      commit('setAllocatedCameras', cameras ?? [])
    },
    setDatePeriodAction({ commit }, payload) {
      commit('setDatePeriodMutation', { startDate: payload[0], endDate: payload[1] })
    },
    async getWorkerListAction({ commit, rootState }, { isSuperadmin }) {
      const {
        data: { users },
      } = await workspace.getWorkerList()
      const workerList = [{ id: rootState.user.id, name: rootState.user.user_name + ' (me)' }, ...users]
      commit('setWorkerListMutation', workerList)
      if (isSuperadmin) {
        const defaultWokreList = workerList.map((worker) => worker.id)
        commit('setDefaultWorkerListMutation', defaultWokreList.sort().join(''))
        commit('setWorkerIdListMutation', defaultWokreList)
      } else {
        commit('setDefaultWorkerListMutation', `${rootState.user.id}`)
        commit('setWorkerIdListMutation', [rootState.user.id])
      }
    },
    async setWorkerListAction({ commit }, payload) {
      commit('setWorkerIdListMutation', payload)
    },
    async getRecentLogsAboutCameraAction({ commit }, id) {
      const {
        data: { logs },
      } = await workspace.getRecentLogsAboutCamera(id)
      return logs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    setExpandedByLogIdAction({ commit }, logId) {
      commit('setExpandedByLogIdMutation', logId)
    },
    setMonitorTabSoundAction({ commit }, soundToggle) {
      commit('setMonitorTabSound', soundToggle)
    },
    async getReviewListAction({ commit }) {
      try {
        const { data } = await superadmin.getReviewList()
        commit('setReviewListMutation', data)
      } catch (e) {
        printError(e.response)
      }
    },
    async reviewRequestedReviewAction({ commit, dispatch }, { id, status }) {
      try {
        const { data } = await superadmin.reviewRequestedReview(id, status)
        if (data.result_code === RESPONSE_CODE.SUCCESS_REQUEST) {
          await dispatch('resetCameraListTableData')
          return true
        }
      } catch (e) {
        printError(e.response)
      }
    },
    async getHistoryListAction({ commit }) {
      try {
        const { data } = await superadmin.getHistoryList()
        commit('setHistoryListMutation', data.histories)
      } catch (e) {
        printError(e.response)
      }
    },
    async getDiscussionListAction({ commit }) {
      try {
        const { data } = await superadmin.getDiscussionList()
        commit('setDiscussionListMutation', data)
      } catch (e) {
        printError(e.response)
      }
    },
    async getArchiveListAction({ commit }) {
      try {
        const { data } = await superadmin.getArchiveList()
        commit('setArchiveListMutation', data)
      } catch (e) {
        printError(e.response)
      }
    },
    async createDiscussionAction({}, content) {
      try {
        const { data } = await superadmin.createDiscussion(content)
        if (data.result_code === RESPONSE_CODE.SUCCESS_CREATE) return true
        else return false
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async createDiscussionReplyAction({}, { id, content }) {
      try {
        const { data } = await superadmin.createDiscussionReply(id, content)
        if (data.result_code === RESPONSE_CODE.SUCCESS_CREATE) return true
        else return false
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async updateDiscussionStatusAction({ dispatch }, { id, isResolve }) {
      try {
        await superadmin.updateDiscussionStatus(id, isResolve)
        await dispatch('getDiscussionListAction')
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async getSelectedRequestTableAction({ commit }, { id, userName, subject, authority }) {
      try {
        const { data } = await superadmin.getFilteredRequestCamList({
          id,
          cameraGroupIds: '',
          countryIds: '',
          countyIds: '',
          monitored: '',
          organizationIds: '',
          panFixedIds: '',
          q: '',
          regionIds: '',
        })
        commit('setSelectedTableMutation', {
          id,
          userName,
          list: data,
          type: CAMERA_LIST_TAB_TYPE[0],
          subject,
          authority,
        })
        commit('duplicateSettingInReviewEdit', data)
      } catch (e) {
        printError(e.response)
      }
    },
    async getSelectedHistoryTableAction({ commit }, { id, userName, subject }) {
      try {
        const { data } = await superadmin.getFilteredHistoryCamList({
          id,
          cameraGroupIds: '',
          countryIds: '',
          countyIds: '',
          monitored: '',
          organizationIds: '',
          panFixedIds: '',
          q: '',
          regionIds: '',
        })
        commit('setSelectedHistoryTableMutation', {
          id,
          userName,
          list: data.cameras,
          type: CAMERA_LIST_TAB_TYPE[1],
          subject,
        })
      } catch (e) {
        printError(e.response)
      }
    },
    async getSelectedArchiveTableAction({ commit }, { id, userName, subject }) {
      try {
        const { data } = await superadmin.getFilteredArchiveCamList({
          id,
          cameraGroupIds: '',
          countryIds: '',
          countyIds: '',
          monitored: '',
          organizationIds: '',
          panFixedIds: '',
          q: '',
          regionIds: '',
        })
        commit('setSelectedTableMutation', {
          id,
          userName,
          list: data,
          type: CAMERA_LIST_TAB_TYPE[2],
          subject,
        })
      } catch (e) {
        printError(e.response)
      }
    },
    // Camera List Search Actions
    /**
     *
     * @param {*} filterInfo
     * {
     *  {String} monitored,
     *  {Integer} id, cameraGroups, countryIds, countyIds,  organizationIds, panOrFixed, q, regionIds
     * }
     */
    async getFilterReviewCamListAction({ commit }, { filterSet, id }) {
      filterSet.q = filterSet.keyword
      filterSet.id = id
      const { data } = await superadmin.getFilteredRequestCamList(filterSet)
      commit('setSelectedTableMutation', { list: data })
    },
    async getFilterHistoryCamListAction({ commit }, { filterSet, id }) {
      filterSet.q = filterSet.keyword
      filterSet.id = id
      const { data } = await superadmin.getFilteredHistoryCamList(filterSet)
      commit('setSelectedHistoryTableMutation', { list: data.cameras })
    },
    async getFilterArchiveCamListAction({ commit }, { filterSet, id }) {
      filterSet.q = filterSet.keyword
      filterSet.id = id
      const { data } = await superadmin.getFilteredArchiveCamList(filterSet)
      commit('setSelectedTableMutation', { list: data })
    },
    // v.1.18.0
    /*
     * typeFlag (String)
     * subject (String)
     * rrl_ids (Number[])
     *
     * */
    async submitChanges({ state, dispatch }, popupData) {
      try {
        let add = []
        const addCameraInfo = Object.keys(state.addedCameraList).length ? Object.values(state.addedCameraList) : []
        for (let i = 0; i < addCameraInfo.length; i += 1) {
          Vue.$log.debug('addCameraInfo', addCameraInfo[i])
          const camera_group_id = addCameraInfo[i].information.cameraGroupId !== '-' ? Number(addCameraInfo[i].information.cameraGroupId) : 0

          add.push({
            camera_condition: {
              camera_cleanness: addCameraInfo[i].condition.cameraClearnessValue,
              evaluated: addCameraInfo[i].condition.evaluated,
              false_positive_areas: addCameraInfo[i].condition.falsePositiveAreas,
              image_quality: addCameraInfo[i].condition.imageQualityValue,
              internal_usage: addCameraInfo[i].condition.internalUsage,
              ir_filter: addCameraInfo[i].condition.IRfilter,
              night_detection: addCameraInfo[i].condition.nightDetection,
              pan_or_fixed: addCameraInfo[i].condition.panFixed,
              ptz_value: addCameraInfo[i].condition.PTZValue,
              serviced: addCameraInfo[i].condition.serviced,
              sky_area: addCameraInfo[i].condition.skyAreaValue,
              within_hftd_area: addCameraInfo[i].condition.HFTDAreaValue,
            },
            camera_information: {
              altitude: Number(addCameraInfo[i].information.altitude),
              api_key_id: Number(addCameraInfo[i].information.apiKey),
              api_url: addCameraInfo[i].information.apiUrl,
              camera_group_id,
              coordinate: addCameraInfo[i].information.coordinate,
              county_id: Number(addCameraInfo[i].information.county),
              guide_url: addCameraInfo[i].information.guide,
              monitored: addCameraInfo[i].information.monitored === 1 ? true : false,
              name: addCameraInfo[i].information.cameraName,
              private_url_1: addCameraInfo[i].information.privateUrl1,
              private_url_2: addCameraInfo[i].information.privateUrl2,
              public_url: addCameraInfo[i].information.publicUrl,
            },
          })
        }

        let edit = []
        const editCameraInfo = Object.keys(state.editedCameraList).length ? Object.values(state.editedCameraList) : []
        const editCameraIdList = Object.keys(state.editedCameraList)
        for (let j = 0; j < editCameraInfo.length; j += 1) {
          Vue.$log.debug('editCameraInfo', editCameraInfo[j])
          const camera_group_id = editCameraInfo[j].information.cameraGroupId !== '-' ? Number(editCameraInfo[j].information.cameraGroupId) : null

          edit.push({
            camera_condition: {
              camera_cleanness: editCameraInfo[j].condition.cameraClearnessValue,
              evaluated: editCameraInfo[j].condition.evaluated,
              false_positive_areas: editCameraInfo[j].condition.falsePositiveAreas,
              image_quality: editCameraInfo[j].condition.imageQualityValue,
              internal_usage: editCameraInfo[j].condition.internalUsage,
              ir_filter: editCameraInfo[j].condition.IRfilter,
              night_detection: editCameraInfo[j].condition.nightDetection,
              pan_or_fixed: editCameraInfo[j].condition.panFixed,
              ptz_value: editCameraInfo[j].condition.PTZValue,
              serviced: editCameraInfo[j].condition.serviced,
              sky_area: editCameraInfo[j].condition.skyAreaValue,
              within_hftd_area: editCameraInfo[j].condition.HFTDAreaValue,
            },
            camera_id: Number(editCameraInfo[j].information.id),
            camera_information: {
              altitude: Number(editCameraInfo[j].information.altitude),
              api_key_id: Number(editCameraInfo[j].information.apiKey),
              api_url: editCameraInfo[j].information.apiUrl,
              camera_group_id,
              coordinate: editCameraInfo[j].information.coordinate,
              county_id: editCameraInfo[j].information.county,
              guide_url: editCameraInfo[j].information.guide,
              monitored: editCameraInfo[j].information.monitored === 1 ? true : false,
              name: editCameraInfo[j].information.cameraName,
              private_url_1: editCameraInfo[j].information.privateUrl1,
              private_url_2: editCameraInfo[j].information.privateUrl2,
              public_url: editCameraInfo[j].information.publicUrl,
            },
          })
        }

        let deleteList = []
        for (let k = 0; k < state.deletedCameraIdList.length; k += 1) {
          deleteList.push({
            camera_id: Number(state.deletedCameraIdList[k]),
          })
        }
        let payload = {
          add,
          edit,
          delete: deleteList,
          subject: popupData.subject,
        }
        if (popupData.typeFlag === EDIT_MODE_CONSTANTS.REVIEW) {
          payload.recipient_ids = popupData.rrl_ids
        }
        let successToastText = 'Request Successfully Submitted.'
        const { id, type } = state.cameraListTabModeInfo

        switch (popupData.typeFlag) {
          case EDIT_MODE_CONSTANTS.REVIEW:
            payload.recipient_ids = popupData.rrl_ids
            Vue.$log.debug('review', payload)
            if (id !== -1 && type === CAMERA_LIST_TAB_TYPE[3]) await superadmin.submitCameraChangesInEditModeOfExistingRequest(payload, id)
            else await superadmin.submitCameraChanges(payload)
            break
          case EDIT_MODE_CONSTANTS.MERGE:
            successToastText = 'Successfully Merged.'
            Vue.$log.debug('merge', payload)
            await superadmin.mergeCameraChanges(payload)
            break
          case EDIT_MODE_CONSTANTS.ARCHIVE:
            successToastText = 'Successfully Archived.'
            Vue.$log.debug('archive', payload)
            await superadmin.archiveCameraChanges(payload)
            break
        }
        await dispatch('resetCameraListTableData')
        Vue.$toast(successToastText, {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      } catch (e) {
        const errorToastText = 'Failed. Please Retry.'
        // let errorToastText = 'Submit changes error.'
        // switch (popupData.typeFlag) {
        //   case EDIT_MODE_CONSTANTS.MERGE:
        //     errorToastText = 'Merge changes error.'
        //     break
        //   case EDIT_MODE_CONSTANTS.ARCHIVE:
        //     errorToastText = 'Archive changes error.'
        //     break
        // }
        Vue.$log.debug(errorToastText, e)
        Vue.$toast(errorToastText, {
          position: 'bottom-center',
          toastClassName: 'requestErrorToastClass',
          timeout: 7000,
        })
      }
    },
    addDeleteCameraIdsAction({ commit }, cameraIdList) {
      commit('addDeleteCameraIdsMutation', cameraIdList)
    },
    async cameraValidateAction({}, { column, value }) {
      try {
        return await superadmin.getCameraValidation(column, value)
      } catch (e) {
        printError(e.response)
      }
    },
    cameraAddAction({ commit }, addedCamera) {
      commit('setAddedCameraMutation', addedCamera)
      commit('duplicateSetUpdateOnAdd', { cameraName: addedCamera.information.cameraName, apiUrl: addedCamera.information.apiUrl })
    },
    cameraEditAction({ commit }, { cameraId, editedCamera, originalApiUrl, originalCameraName }) {
      const isExistCamId = Number.isInteger(cameraId)
      if (isExistCamId) commit('editExistingCameraMutation', { cameraId, editedCamera })
      else commit('editAddedCameraMutation', { cameraId, editedCamera })
      commit('duplicateSetUpdateOnEdit', {
        originalApiUrl,
        originalCameraName,
        cameraName: editedCamera.information.cameraName,
        apiUrl: editedCamera.information.apiUrl,
      })
    },
    cameraDeleteAction({ commit }, cameraId) {
      const isExistCamId = Number.isInteger(cameraId)
      if (isExistCamId) commit('deleteExistingCameraMutation', cameraId)
      else commit('deleteAddedCameraMutation', cameraId)
    },
    async getCameraScoringCriteria() {
      try {
        const { data } = await superadmin.getCameraScoringCriteria()
        return data.camera_scoring_criteria
      } catch (e) {
        printError(e.response)
        return null
      }
    },
    async getCameraDetailInfoAction({}, id) {
      try {
        const { data } = await superadmin.getCameraDetailInfo(id)
        return { cameraCondition: data.camera_condition, cameraInformation: data.camera_information }
      } catch (e) {
        printError(e.response)
        return null
      }
    },
    async mergeReviewedRequestAction({ dispatch }, id) {
      try {
        const { data } = await superadmin.mergeReviewedRequest(id)
        if (data.result_code === RESPONSE_CODE.SUCCESS_REQUEST) {
          await Promise.all([dispatch('getReviewListAction'), dispatch('resetCameraListTableData')])
          return true
        } else return false
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async editReviewedRequestAction({ commit }, { id, userName, subject }) {
      try {
        const { data } = await superadmin.getFilteredRequestCamList({
          id,
          cameraGroups: '',
          countryIds: '',
          countyIds: '',
          monitored: '',
          organizationIds: '',
          panOrFixed: '',
          q: '',
          regionIds: '',
        })
        commit('setSelectedTableMutation', {
          id,
          userName,
          list: data,
          type: CAMERA_LIST_TAB_TYPE[3],
          subject,
        })
      } catch (e) {
        printError(e.response)
      }
    },
    async archiveReviewedRequestAction({ dispatch }, id) {
      try {
        const { data } = await superadmin.archiveReviewedRequest(id)
        if (data.result_code === RESPONSE_CODE.SUCCESS_REQUEST) {
          await Promise.all([dispatch('getReviewListAction'), dispatch('resetCameraListTableData')])
          return true
        } else return false
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async deleteReviewedRequestAction({ dispatch }, id) {
      try {
        const { data } = await superadmin.deleteReviewedRequest(id)
        if (data.result_code === RESPONSE_CODE.SUCCESS_REQUEST) {
          await Promise.all([dispatch('getReviewListAction'), dispatch('resetCameraListTableData')])
          return true
        }
      } catch (e) {
        printError(e.response)
        return false
      }
    },
    async resetCameraListTableData({ commit, dispatch }) {
      try {
        await dispatch('getCameras', {
          keyword: [],
          organizationIds: [],
          countryIds: [],
          regionIds: [],
          countyIds: [],
          cameraGroupIds: [],
          panFixedIds: [],
          monitored: [],
        })
        commit('resetAddEditDeleteCameraData')
        commit('resetCameraListTabModeInfo')
      } catch (e) {
        printError(e)
      }
    },
    async downloadCameraTemplateExcelFile({}) {
      try {
        const { data } = await superadmin.getCameraTemplateFile()
        const blob = new Blob([data], { type: 'application/octet-stream' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('download', 'Camera_List_Template.xlsx')
        link.href = url
        link.click()
        return data
      } catch (e) {
        printError(e)
        return null
      }
    },
    async addCameraListWithUploadData({ commit }, uploadData) {
      try {
        commit('addUploadListToAddedCameraList', uploadData)
        Vue.$toast('Successfully Uploaded.', {
          position: 'bottom-center',
          closeOnClick: false,
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      } catch (e) {
        Vue.$toast('File Upload Failure.', {
          position: 'bottom-center',
          closeOnClick: false,
          toastClassName: 'requestErrorToastClass',
          timeout: 7000,
        })
      }
    },
    async replaceCameraListWithUploadData({ commit }, uploadData) {
      try {
        // const idList = state.camerasTableData.map((camera) => camera?.id)
        // commit('setDeletedCameraIdList', idList)
        commit('editUploadedCameraList', uploadData)
        Vue.$toast('Successfully Uploaded.', {
          position: 'bottom-center',
          closeOnClick: false,
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      } catch (e) {
        printError(e)
        Vue.$toast('File Upload Failure.', {
          position: 'bottom-center',
          closeOnClick: false,
          toastClassName: 'requestErrorToastClass',
          timeout: 7000,
        })
      }
    },
    async resetCameraChanges({ commit }) {
      commit('resetAddEditDeleteCameraData')
    },
    async rollbackHistoryAction({ commit, state }) {
      try {
        const historyId = state.cameraListTabModeInfo.id
        await superadmin.rollbackHistory(historyId)
        const cameras = await superadmin.getCameras({
          keyword: [],
          organizationIds: [],
          countryIds: [],
          regionIds: [],
          countyIds: [],
          cameraGroupIds: [],
          panFixedIds: [],
          monitored: [],
        })
        commit('setCameras', cameras ?? [])
        commit('resetCameraListTabModeInfo')
        return true
      } catch (e) {
        printError(e)
        return false
      }
    },
    catchNetworkError({ commit }, error) {
      commit('setNetworkError', { flag: true, errorCode: error.response?.data?.result_code }, { root: true })
    },
  },
}

function printError(response) {
  if (response) {
    const { status, data, statusText } = response
    const message = typeof data === 'string' ? data : statusText
    Vue.$log.error(status, message)
  } else {
    Vue.$log.error(response)
  }
}
