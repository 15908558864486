<template>
  <header :class="{ dark: $vuetify.theme.dark }">
    <div class="left">
      <div :class="{ title: true, dark: $vuetify.theme.dark }">{{ title }}</div>
      <AnalyticsDropdown v-if="title === 'Overview'" />
      <AnalyticsCalendarDropdown v-else :calendarType="calendarType" :pageType="title" />
    </div>
    <div class="right">
      <SmallButton @onClick="onClickExport">Export</SmallButton>
      <AnalyticsExportDropdown
        v-if="showExportDropdown"
        v-click-outside="handleClickOutsideExportDropdown"
        :currentCameraOption="currentCameraOption"
        :currentFrequencyOption="currentFrequencyOption"
      />
      <TextButton content="Logout" @clickMethod="onClickLogout" />
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import Constants from '@/constants'
import SmallButton from '@item/button/SmallButton'
import TextButton from '@item/button/TextButton'
import AnalyticsDropdown from '@common/AnalyticsToolbar/AnalyticsDropdown'
import AnalyticsCalendarDropdown from './AnalyticsCalendarDropdown.vue'
import AnalyticsExportDropdown from './AnalyticsExportDropdown'

export default {
  components: { SmallButton, AnalyticsDropdown, TextButton, AnalyticsCalendarDropdown, AnalyticsExportDropdown },
  props: {
    title: {
      type: String,
      default: 'Analytics',
    },
  },
  data() {
    return {
      showExportDropdown: false,
    }
  },
  computed: {
    ...mapState({
      frequencyTabType: (state) => state.analytics.frequency.selectedTab.tab,
      cameraSelectedRangeMode: (state) => state.analytics.camera.selectedRangeMode,
      frequencyDateTabSelectedRangeMode: (state) => state.analytics.frequency.selectedRangeMode.dateTabRangeMode,
      frequencyTimeTabSelectedRangeMode: (state) => state.analytics.frequency.selectedRangeMode.timeTabRangeMode,
      cameraComparisonMode: (state) => state.analytics.camera.comparisonModeToggle,
      frequencyComparisonMode: (state) => state.analytics.frequency.comparisonModeToggle,
    }),

    currentCameraOption() {
      if (this.title === Constants.ANALYTICS_CAMERA_PAGE) {
        return { comparisonMode: this.cameraComparisonMode, rangeMode: this.cameraSelectedRangeMode }
      } else return null
    },
    currentFrequencyOption() {
      if (this.title === Constants.ANALYTICS_FREQUENCY_PAGE) {
        const tabType = this.frequencyTabType
        const comparisonMode = this.frequencyComparisonMode
        const rangeMode = tabType === Constants.FREQUENCY_TAB_DATE ? this.frequencyDateTabSelectedRangeMode : this.frequencyTimeTabSelectedRangeMode
        return {
          tabType,
          comparisonMode,
          rangeMode,
        }
      } else return null
    },

    frequencySelectedRangeMode() {
      return this.frequencyTabType === Constants.FREQUENCY_TAB_DATE ? this.frequencyDateTabSelectedRangeMode : this.frequencyTimeTabSelectedRangeMode
    },
    calendarType() {
      if (this.title === Constants.ANALYTICS_CAMERA_PAGE) {
        if (!this.cameraComparisonMode || (this.cameraComparisonMode && this.cameraSelectedRangeMode === Constants.RANGE_MODE_WEEK)) return 'DEFAULT'
        else return this.cameraSelectedRangeMode
      } else {
        if (this.frequencyTabType === Constants.FREQUENCY_TAB_DATE) {
          return 'DEFAULT'
        } else {
          if (!this.frequencyComparisonMode) return 'DEFAULT'
          switch (this.frequencySelectedRangeMode) {
            case Constants.RANGE_MODE_DAY:
              return Constants.RANGE_MODE_DAY
            case Constants.RANGE_MODE_MONTH:
              return Constants.RANGE_MODE_MONTH
            default:
              return 'DEFAULT'
          }
        }
      }
    },
  },
  methods: {
    onClickExport(e) {
      this.showExportDropdown = !this.showExportDropdown
    },
    handleClickOutsideExportDropdown() {
      this.showExportDropdown = false
    },
    clickExportOption(option) {
      this.showExportDropdown = false
      setTimeout(() => {
        if (option === 'excel') {
          // await this.$store.dispatch('analytics/exportExcelFile')
        } else if (option === 'pdf') {
          window.print()
          // const element = document.getElementById('app')
          // const options = {
          //   // margin: 10,
          //   filename: 'analytics.pdf',
          //   // pagebreak: {
          //   //   mode: 'avoid-all',
          //   // },
          //   html2canvas: {
          //     dpi: 300,
          //   },
          //   jsPDF: {
          //     orientation: 'landscape',
          //     format: 'a4',
          //     unit: 'mm',
          //     // hotfixes: ['px_scaling'],
          //   },
          // }
          // this.showExportDropdown = false
          // html2pdf().set(options).from(element).save()
        }
      }, 500)
    },
    async onClickLogout() {
      await this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  // margin-left: 11px;
  justify-content: space-between;
  padding: 15px 30px 15px 42px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-low);
    border-bottom: 1px solid var(--f-bg-high);
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 20px 0 0;
}

.right {
  display: flex;
}

.right > .normal {
  margin-right: 20px;
}

.left {
  display: flex;
}

.export-dropdown {
  position: absolute;
  right: 32px;
  top: 62px;
  width: 180px;
  border-radius: 8px;
  background-color: var(--f-text-white);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .export-dropdown-option {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--v-textDefault);
    padding: 4px 20px;
    cursor: pointer;
    &.dark {
      color: var(--v-textDefault);
    }
  }

  .export-dropdown-option:hover {
    background-color: var(--f-primary-50);
    color: white;
  }
}
</style>
