<template>
  <div
    :class="{ 'search-bar-root': true, focus, dark: $vuetify.theme.dark }"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHaspopup"
    :role="role"
    @click="$emit('click', $event)"
  >
    <img v-if="focus" src="@/assets/Icon ionic-ios-search-blue.svg" class="icon-search" />
    <img v-else-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-search-white.svg" class="icon-search" />
    <img v-else src="@/assets/Icon ionic-ios-search.svg" class="icon-search" />
    <input ref="input" type="text" :placeholder="placeholder" @keydown="onKeydown" @input="onInput" @focus="onFocus" @blur="onBlur" maxlength="50" />
    <img v-if="!empty" @click="onClear" src="@/assets/Exit_Icon_Blue.svg" class="icon-clear" />
  </div>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'

export default {
  extends: InputSearchBar,
}
</script>

<style lang="scss" scoped>
.search-bar-root {
  display: flex;
  align-items: center;
  background-color: var(--v-backgroundDate-base);
  border: 1px solid #d3d5dd;
  border-radius: 30px;
  width: 100%;
  height: 36px;
  min-width: 300px;

  &.dark {
    border-width: 0;
  }

  &.focus {
    border: 1px solid #3d51fb;
  }

  .icon-search {
    width: 12px;
    margin: 0 7px 0 20px;
  }

  input {
    flex-grow: 1;
    outline: none;
    font-size: 12px;
    caret-color: #3d51fb;
    color: var(--v-textDefault);

    &::placeholder {
      color: var(--v-textDefault);
    }
  }

  .icon-clear {
    width: 10px;
    cursor: pointer;
    margin-right: 20px;
  }
}
</style>
