<template>
  <div
    :class="{ 'search-bar-root': true, focus, dark: $vuetify.theme.dark }"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHaspopup"
    :role="role"
    @click="$emit('click', $event)"
  >
    <img v-if="focus" src="@/assets/Icon ionic-ios-search-blue.svg" class="icon-search" />
    <img v-else-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-search-white.svg" class="icon-search" />
    <img v-else src="@/assets/Icon ionic-ios-search.svg" class="icon-search" />
    <input ref="input" type="text" :placeholder="placeholder" @keydown="onKeydown" @input="onInput" @focus="onFocus" @blur="onBlur" maxlength="50" />
    <img v-if="!empty" @click="onClear" src="@/assets/Exit_Icon_Blue.svg" class="icon-clear" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    ariaExpanded: String,
    ariaHaspopup: Boolean,
    role: String,
  },
  data() {
    return {
      focus: false,
      text: this.value,
    }
  },
  computed: {
    empty() {
      return !this.text.length
    },
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
  },
  methods: {
    onInput(e) {
      this.text = e.target.value
      this.$emit('input', this.text)
    },
    onBlur(e) {
      this.focus = false
      this.$emit('blur', e)
    },
    onFocus(e) {
      this.focus = true
      this.$emit('focus', e)
    },
    onClear() {
      this.$refs.input.value = ''
      this.text = ''
      this.$emit('input', this.text)
      this.$emit('clear')
    },
    onKeydown(e) {
      this.$emit('keydown', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-bar-root {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #d3d5dd;
  border-radius: 30px;
  width: 100%;
  height: 40px;
  min-width: 300px;

  &.dark {
    background-color: #25262c;
    border-width: 0;
  }

  &.focus {
    border: 1px solid #3d51fb;
  }

  .icon-search {
    width: 12px;
    margin: 0 10px 0 30px;
  }

  input {
    flex-grow: 1;
    outline: none;
    font-size: 14px;
    caret-color: #3d51fb;
    color: var(--v-textDefault);

    &::placeholder {
      color: var(--v-textDefault);
    }
  }

  .icon-clear {
    width: 12px;
    cursor: pointer;
    margin-right: 20px;
  }
}
</style>
